<template>
    <div id="nav" class="navbar navbar-light">
      <a class="navbar-brand" href="#">
        <img src="@/assets/images/Bauhaus_Logo_Long.png" height="45" alt="">
      </a>
        <ul class="nav nav-pills">
          <template v-for="(item,index) in this.items">
            <li>
              <a class="nav-item nav-link" v-bind:class="{active: item.active}" :href="''+item.link" @click="toggle_active(item, index)">{{item.title}}</a>
            </li>
          </template>
        </ul>
    </div>
</template>

<script>
    export default {
    name: 'NavBar',

    data() {
        return{
          items: [
                  {title: "Start", active: true, link: "#"},
                  {title: "How to participate", active: false, link: "#participation"},
                  {title: "Participation Models", active: false, link: "#custom_model"},
                  {title: "Scoreboard", active: false, link: "#scoreboard"},
                  {title: "Criteria", active: false, link: "#criteria"},
                  {title: "Team", active: false, link: "#team"},
                  {title: "Contact", active: false, link: "#contact"},
                  ],
          curr_active: 0
        }
    },

    methods: {
      toggle_active: function(item, index){
        this.items[this.curr_active].active = false;
        item.active = !item.active;
        this.curr_active = index;
      }
    }
    }
</script>

<style>

    @media (min-width: 50em) {
      #nav {
        position: sticky;
        top: 0;
        z-index: 20;
      }
    }

    #nav {
        background-color: #e3f2fd;
        padding-left: 20px;
        padding-right: 20px;
        grid-column: 1/-1;
        grid-row: 2;
    }

</style>
