<template>
    <h3> Challenge Ranking
        <button class="btn btn-labeled btn-outline-dark btn-sm" style="margin-left: 5px" @click="updateScores()">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-counterclockwise" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"/>
                <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z"/>
            </svg>
        </button>
    </h3>
    <table class="table table-sm table-hover" style="text-align:center">
        <thead>
            <tr>
            <th scope="col">Rank</th>
            <th scope="col">Name</th>
            <th scope="col">Mean Score</th>
            <th scope="col">Mean Accuracy &Phi;</th>
            <th scope="col">Mean Resolution &delta;</th>
            <th scope="col">Mean Length</th>
            <th scope="col">Mean Quality &Psi;</th>
            <th scope="col">Date</th>
            </tr>
        </thead>
        <tbody>
            <template v-for="(score,index) in this.scoresSplitted[this.currentPage]">
                <tr class='clickable' @click="selectItem(index)">
                    <th scope="row">{{getRank(index)}}</th>
                    <td>{{score.author}}</td>
                    <td>{{parseFloat(score.final_score).toFixed(3)}}</td>
                    <td>{{parseFloat(score.accuracy_score).toFixed(3)}}</td>
                    <td>{{parseFloat(score.resolution_score).toFixed(3)}}</td>
                    <td>{{parseFloat(score.length_cost).toFixed(2)}}</td>
                    <td>{{parseFloat(score.quality_score).toFixed(3)}}</td>
                    <td>{{convertDate(score.publishing_date)}}</td>
                </tr>
                <tr v-if="index === clickedItem" style="background-color: #e3f2fd">
                    <td colspan="8">
                      <div style="width: 20%; float: left; text-align: left">
                        Bridge: <br>
                        Artifical: <br>
                        Hart: <br>
                      </div>
                      <div style="float: left; text-align: left; width: 80%">
                        <div>
                          Score {{parseFloat(score.scherkonde_final_score).toFixed(3)}},
                          Accuracy &Phi; {{parseFloat(score.scherkonde_accuracy_score).toFixed(3)}},
                          Resolution &delta; {{parseFloat(score.scherkonde_resolution_score).toFixed(3)}},
                          Length {{parseFloat(score.scherkonde_length_cost).toFixed(3)}},
                          Quality &Psi; {{parseFloat(score.scherkonde_quality_score).toFixed(3)}}
                          <br></div>
                        <div>
                          Score {{parseFloat(score.artifical_final_score).toFixed(3)}},
                          Accuracy &Phi; {{parseFloat(score.artifical_accuracy_score).toFixed(3)}},
                          Resolution &delta; {{parseFloat(score.artifical_resolution_score).toFixed(3)}},
                          Length {{parseFloat(score.artifical_length_cost).toFixed(3)}},
                          Quality &Psi; {{parseFloat(score.artifical_quality_score).toFixed(3)}}
                          <br></div>
                        <div>
                          Score {{parseFloat(score.hart_final_score).toFixed(3)}},
                          Accuracy &Phi; {{parseFloat(score.hart_accuracy_score).toFixed(3)}},
                          Resolution &delta; {{parseFloat(score.hart_resolution_score).toFixed(3)}},
                          Length {{parseFloat(score.hart_length_cost).toFixed(3)}},
                          Quality &Psi; {{parseFloat(score.hart_quality_score).toFixed(3)}}
                          <br></div>
                      </div>
                        <div> Contact the Author: {{score.email}} </div>

                        <div v-if="score.paper === 'No Paper'">
                            Publication: No publication published.
                        </div>
                        <div v-else>
                            <a :href=score.paper class="link-primary">Go to publication</a>
                        </div>
                        <a v-if="currentPage==0" class="btn btn-outline-primary" href="#" style="margin-top: 10px">Select in Sidebar to visualize</a>
                    </td>
                </tr>
            </template>
        </tbody>
    </table>
    <div class="btn-toolbar" role="toolbar" aria-label="Page Toolbar with button groups">
        <div class="btn-group btn-group-sm" style="left: 50%;" role="group" aria-label="First group">
            Page:
            <template v-for="n in this.pagenumber">
                <button type="button" :class="['btn', 'btn-outline-primary', {'active': n===currentPage+1}]" style="left: 10px" @click="changePage(n)">{{n}}</button>
            </template>
        </div>
    </div>
</template>

<script>

    export default {
        name: "Scoreboard",

        data(){
            return {
                scores: [],
                scoresSplitted: [[]],
                scoresPerPage: 10,
                pagenumber: 0,
                currentPage: 0,
                clickedItem: -1
            }
        },

        methods: {
            updateScores(){
                this.$scoreboardController.updateScores();
            },

            changePage(page){
                this.currentPage = page-1;
            },

            selectItem(index){
                if(index === this.clickedItem){
                    this.clickedItem = -1;
                }
                else{
                    this.clickedItem = index;
                }
            },

            convertDate(date){
                var date = new Date(date-62135683200000);
                date.setTime( date.getTime() + date.getTimezoneOffset()*60*1000 )
                return date.toLocaleDateString() + " " +date.toLocaleTimeString();
            },

            getRank(index){
                return ((this.currentPage)*this.scoresPerPage)+index+1;
            }
        },

        mounted() {
            this.$scoreboardController.subscribeForUpdates("update", scores =>{
                console.log(scores);
                this.scores = scores;
                this.pagenumber = Math.ceil(this.scores.length/this.scoresPerPage);
                for(var i = 0; i<this.pagenumber; i++){
                    this.scoresSplitted[i] = scores.slice(this.scoresPerPage*i,this.scoresPerPage*(i+1));
                }
            });
        }
    }

</script>

<style>

</style>