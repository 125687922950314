import { contentHandler } from "./content_handler";
import { initializeThree } from "./initialize_three";
import { samplePoints } from "./sampling_points";
import { animateFlightpath } from "./animations";
import { visualizeEvaluatedPoints } from "./visualize_result";
import { computeFlightpath } from "./compute_flightpath";
import * as Three from 'three';
import { SegmentationTool } from "./segmentation_tool";
import Worker from 'worker-loader!../utils/flightpathWorker.js';

//singleton pattern
class ThreeInterface {

    constructor(){
        this.contentHandler = contentHandler;
        this.threeContext = null;
        this.models = [];
        this.staticModels = [
            {modelName: 'Bridge', filepath: "/meshes/bridge.glb", thumbnail: "images/bridge.png"},
            {modelName: 'Artificial', filepath: "/meshes/artificial_house.glb", thumbnail: "images/Artificial.png"},
            {modelName: 'Hart', filepath: "/meshes/hart.glb", thumbnail: "images/Hart.png"}
        ],
        this.preloading = true;
        this.renderedItem = {};
        this.defaultFlightpath = {};
        this.emitter;
    }

    //creates three instance if none exists
    static getInstance(){
        if(!ThreeInterface.instance){
            ThreeInterface.instance = new ThreeInterface();
        }
        else{
            console.log("Three instance already created. Returned existing.");
        }
        return ThreeInterface.instance;
    }

    //triggers when window is resized
    onWindowResize(){
        if(!this.threeContext){
            return;
        }
        this.threeContext.renderer.setSize(this.threeContext.container.clientWidth, this.threeContext.container.clientHeight);
        this.threeContext.camera.aspect = this.threeContext.container.clientWidth / this.threeContext.container.clientHeight;
        this.threeContext.camera.updateProjectionMatrix();
    }

    //set container for Three instance
    bindContainer(view_container){
        this.threeContext = initializeThree(view_container);
        this.contentHandler.setThreeContext(this.threeContext);
    }

    //binds event handler
    bindEventHandler(handler){
        this.emitter = handler;
    }

    //triggers on fullscreen
    toggleFullscreen(isFullscreen){
        //this.onWindowResize();
    }

    //get static models
    getStaticModels(){
        return this.staticModels;
    }

    //preloads static models {modelName, filepath}
    preloadStaticModels(){
        var that = this;
        return new Promise(function (resolve, reject) {
            var promises = [];

            for(var m = 0; m < that.staticModels.length; m++){
                var staticModel = that.staticModels[m];

                promises.push(that.contentHandler.loadModel(staticModel.modelName, staticModel.filepath).then(result => {
                    if(result.status = "fulfilled"){

                        result.visible = false;
                        that.threeContext.scene.add(result);

                        //presample
                        var sampledModel;

                        sampledModel = samplePoints(result, result.normalization);
                        that.threeContext.renderer.render(that.threeContext.scene, that.threeContext.camera);
                        sampledModel.sceneInstance.name = "sampled_points_"+result.name;
                        that.threeContext.scene.add(sampledModel.sceneInstance);
                        sampledModel.sceneInstance.visible = false;

                        var m = {modelName: result.name, model: result, sampledPoints: sampledModel};
                        that.models.push(m);
                    }
                }));
            }

            Promise.allSettled(promises).then((results) => {
                results.forEach((result) =>{
                    if(result.status =="rejected"){
                        console.log(result);
                        reject();
                    }
                });
                that.showDefault();
                that.preloading = false;
                resolve();
            });

        }).then((result) => {

        });
    }

    //enables setting a default flightpath from scoreboard for example
    setDefaultFlightpath(model, flightpath_file, filename){
        this.defaultFlightpath.model = model;
        this.defaultFlightpath.file = flightpath_file;
        this.defaultFlightpath.filename = filename;
    }

    //shows default bridge and flightpath
    showDefault(){
        if(!this.defaultFlightpath.model){
            console.warn("No default flightpath set.");
            return;
        }

        var idx = this.models.findIndex(el=>el.modelName == this.defaultFlightpath.model);
        if(idx < 0){
            console.error("Default Flightpath has not a static model. Custom model is not supported as default.");
            return;
        }
        else
        {
            var static_model = this.models[idx];
            this.defaultFlightpath.bridgeInstance = static_model.model;
            this.defaultFlightpath.index = "_default";
            this.defaultFlightpath.bridgeInstance.visible = true;
            static_model.sampledPoints.sceneInstance.visible = true;
            this.renderedItem = {model: static_model, flightpathInstance: undefined, evaluatedPoints: undefined};

            this.contentHandler.loadFlightpath(this.defaultFlightpath,static_model.model).then((result) => {
                this.defaultFlightpath.flightpathInstance = result;
                this.threeContext.scene.add(result);
                this.renderedItem.flightpathInstance = result;
            });

            this.threeContext.alignCameraToFitBox(this.renderedItem.model.model.geometry.boundingBox);
        }
        this.emitter.emit("content-changed", this.renderedItem);
    }

    //load bridge and flightpath
    addEvaluationItem(item){
        var model_index = this.models.findIndex(el=>el.modelName == item.model);
        var promise;

        if(item.customFile && model_index==-1){
            this.addCustomModel(item);
        }
        else {
            this.emitter.emit("loading", true);
            item.modelInstance = this.models[model_index].model;
            promise = this.contentHandler.loadFlightpath(item, this.models[model_index].model).then(result => {
                result.visible = false;
                this.threeContext.scene.add(result);
                item.flightpathInstance =  result;
                this.emitter.emit("loading", false);
            });
            item.sampledPoints = this.models[model_index].sampledPoints.rawPoints;
        }
        return promise;
    }

    //adds custom model without flightpath for flightpath generation
    addCustomModel(item){
        this.emitter.emit("loading", true);
        var model_index = this.models.findIndex(el=>el.modelName == item.model+"_"+item.filename);
        var promise;

        if(item.customFile && model_index==-1){
            var modelPath;
            if(item.customFile instanceof File){
                modelPath = URL.createObjectURL(item.customFile);
            } else {
                modelPath = item.customFile;
            }
            var that = this;
            promise = this.contentHandler.loadModel(item.model, modelPath, item.filename)
            .then(async function(result) {
                result.visible = false;
                that.threeContext.scene.add(result);
                item.flightpathInstance = null;
                var model = {modelName: result.name, model: result, sampledPoints: undefined};
                that.models.push(model);
                item.modelInstance = model.model;
                if(item.file){
                    await that.contentHandler.loadFlightpath(item, result).then(result => {
                        result.visible = false;
                        that.threeContext.scene.add(result);
                        item.flightpathInstance =  result;
                        that.emitter.emit("loading", false);
                    });
                }else{
                    that.emitter.emit("loading", false);
                }
            })
            .catch((error)=>{
                return promise.reject(error);
            });
            return promise;
        }
    }

    //toggle visibility
    showEvaluationItem(item){

        //deactivate rendered item
        this.renderedItem.model.model.visible = false;
        if(this.renderedItem.flightpathInstance){
            this.renderedItem.flightpathInstance.visible = false;
        }

        var idx = this.models.findIndex(el=>el.modelName == this.renderedItem.model.modelName);
        if(this.renderedItem.model.sampledPoints){
            this.renderedItem.model.sampledPoints.sceneInstance.visible = false;
        }

        if(this.renderedItem.evaluatedPoints){
            this.renderedItem.evaluatedPoints.sceneInstance.visible = false;
        }

        //activate selected item
        idx = this.models.findIndex(el => el.modelName == item.model);
        if(item.model =="Custom"){
            idx = this.models.findIndex(el => el.modelName == item.model+"_"+item.filename);
        }
        this.models[idx].model.visible = true;
        item.flightpathInstance.visible = true;
        if(!item.evaluatedPoints && this.models[idx].sampledPoints){
            this.models[idx].sampledPoints.sceneInstance.visible = true;
        }

        if(item.evaluatedPoints){
            item.evaluatedPoints.sceneInstance.visible = true;
            this.renderedItem.evaluatedPoints = item.evaluatedPoints;
        }
        else {
            this.renderedItem.evaluatedPoints = undefined;
        }

        this.renderedItem.model = this.models[idx]
        this.renderedItem.flightpathInstance = item.flightpathInstance;

        this.threeContext.alignCameraToFitBox(this.renderedItem.model.model.geometry.boundingBox);

        this.emitter.emit("content-changed", this.renderedItem);
    }

    //show custom model to calculate flightpath
    showCustomModelForGeneration(item){
        this.renderedItem.model.model.visible = false;
        if(this.renderedItem.flightpathInstance){
            this.renderedItem.flightpathInstance.visible = false;
        }

        var idx = this.models.findIndex(el=>el.modelName == this.renderedItem.model.modelName);
        if(this.renderedItem.model.sampledPoints){
            this.renderedItem.model.sampledPoints.sceneInstance.visible = false;
        }

        if(this.renderedItem.evaluatedPoints){
            this.renderedItem.evaluatedPoints.sceneInstance.visible = false;
        }

        idx = this.models.findIndex(el => el.modelName == item.model+"_"+item.filename);
        this.models[idx].model.visible = true;

        this.renderedItem.model = this.models[idx]
        this.renderedItem.flightpathInstance = null;

        this.threeContext.alignCameraToFitBox(this.renderedItem.model.model.geometry.boundingBox);

        this.emitter.emit("content-changed", this.renderedItem);
    }

    //remove bridge and flightpath
    removeEvaluationItem(item){
        if(item.rendered){
            this.showEvaluationItem(this.defaultFlightpath);
            this.emitter.emit("evaluated-points-visible", false);
        }

        if(item.flightpathInstance){
            var fltoremove = this.threeContext.scene.getObjectByName(item.flightpathInstance.name);
            this.threeContext.scene.remove(fltoremove);
        }

        if(item.evaluatedPoints){
            this.threeContext.scene.remove(item.evaluatedPoints.sceneInstance);
        }

        if(item.model == "Custom"){
            var idx = this.models.findIndex(el=>el.modelName == item.model+"_"+item.filename);
            if(idx > -1){
                item.modelInstance = null;
                this.threeContext.scene.remove(this.models[idx].model);

                if(this.models[idx].sampledPoints){
                    this.threeContext.scene.remove(this.models[idx].sampledPoints.sceneInstance.name);
                }
                this.models.splice(idx, 1);
            };
        }
    }

    //sample points if not sampled yet
    sample(item){

        var model_index = this.models.findIndex(el=>el.modelName == item.model);
        if(item.model == "Custom"){
            model_index = this.models.findIndex(el=>el.modelName == item.model+"_"+item.filename);
        }
        if(model_index != -1){

            if(this.models[model_index].sampledPoints){
                item.sampledPoints = this.models[model_index].sampledPoints.rawPoints;
            }
            else {
                var that = this;
                var sampledModel;
                this.models[model_index].model.traverse( function (node){
                    if(node.isMesh){
                        sampledModel = samplePoints(node, that.models[model_index].model.normalization);
                        that.threeContext.renderer.render(that.threeContext.scene, that.threeContext.camera);
                        sampledModel.sceneInstance.name = "sampled_points_"+that.models[model_index].model.name;
                        that.models[model_index].sampledPoints = sampledModel;
                    }
                });
                item.sampledPoints = sampledModel.rawPoints;
                sampledModel.sceneInstance.visible = false;
                this.threeContext.scene.add(sampledModel.sceneInstance);
            }
        }
        else {
            console.error("No instance for: "+item.model+" loaded in Three.");
        }
    }

    //stop animation temp

    animate(){
        animateFlightpath(this.threeContext)
        // this.threeContext.segmentationTool = new SegmentationTool(this.threeContext.scene, this.threeContext.container, this.threeContext.camera_controls, this.threeContext.camera);
        //this.threeContext.segmentationTool.segment(this.renderedItem.model.model);
    }

    visualizeResult(item, evaluatedPoints){
        var model_index = this.models.findIndex(el=>el.modelName == item.model);
        if(item.model == "Custom"){
            model_index = this.models.findIndex(el=>el.modelName == item.model+"_"+item.filename);
        }
        if(model_index != -1 && !item.evaluatedPoints){
            var evaluatedPoints = visualizeEvaluatedPoints(this.threeContext, evaluatedPoints, this.models[model_index].model.normalization);
            this.threeContext.renderer.render(this.threeContext.scene, this.threeContext.camera);
            evaluatedPoints.sceneInstance.name = "evaluated_points_"+item.filename;

            this.models[model_index].sampledPoints.sceneInstance.visible = false;
            item.evaluatedPoints = evaluatedPoints;

            if(item.rendered){
                evaluatedPoints.sceneInstance.visible = true;
                this.renderedItem.evaluatedPoints = evaluatedPoints;
            }
            else{
                evaluatedPoints.sceneInstance.visible = false;
            }

            this.threeContext.scene.add(evaluatedPoints.sceneInstance);
            this.emitter.emit("evaluated-points-visible", true);

        }
        else {
            console.error("No instance for: "+item.model+" loaded in Three.");
        }
    }

    computeFlightpathForModel(item){
        var that = this;
        let flightpath_worker = null;
        if(window.Worker){
            flightpath_worker = new Worker();
        }

        return {Promise: new Promise(function(resolve, reject){
            var model_index = that.models.findIndex(el=>el.modelName == item.model);
            if(item.model == "Custom"){
                model_index = that.models.findIndex(el=>el.modelName == item.model+"_"+item.filename);
            }
            if(model_index != -1){
                var vertex_buffer = [];
                var normal_buffer = [];
                that.models[model_index].model.traverse( function (node){
                    if(node.isMesh){
                        var cur_vertex_buffer = node.geometry.attributes.position.array;
                        var cur_normal_buffer = node.geometry.attributes.normal.array;
                        vertex_buffer.push(cur_vertex_buffer);
                        normal_buffer.push(cur_normal_buffer);
                    }
                });
                var flightpath;

                // use webworker for paralellism
                if(window.Worker){

                    //error handling
                    let onError = (e) => {
                        var error = ['ERROR: Line ', e.lineno, ' in ', e.filename, ': ', e.message].join('');
                        reject(error);
                    }

                    flightpath_worker.addEventListener('error', onError, false);

                    console.log(vertex_buffer);
                    console.log(normal_buffer);
                    console.log(that.models[model_index].model.normalization);

                    var normalization = that.models[model_index].model.normalization;

                    //serialize data
                    var serialized_normalization = {rotation: JSON.stringify(normalization.rotation),
                        scale: JSON.stringify(normalization.scale),
                        translation: JSON.stringify(normalization.translation)};
                        var serialized_data = {
                            vertexBuffer: vertex_buffer,
                            normalBuffer: normal_buffer,
                            normalization: serialized_normalization
                        };

                    //start worker
                    flightpath_worker.postMessage(serialized_data);
                    flightpath_worker.onmessage = (result) => {
                        flightpath = result.data;

                        //instantiate result
                        var geometry = new Three.BufferGeometry();
                        geometry.setAttribute('position', new Three.Float32BufferAttribute(flightpath.sceneInstanceProperties.positions, 3));
                        geometry.setAttribute('color', new Three.Float32BufferAttribute(flightpath.sceneInstanceProperties.colors, 3));
                        geometry.computeBoundingSphere();

                        var material = new Three.PointsMaterial({size: 0.005, vertexColors: Three.VertexColors});
                        material.polygonOffset = true;
                        material.depthTest = true;
                        material.polygonOffsetFactor = 1;
                        material.polygonOffsetUnits = 0.1;

                        //normalize
                        flightpath.sceneInstance = new Three.Points(geometry, material);
                        flightpath.sceneInstance.scale.set(normalization.scale, normalization.scale, normalization.scale);
                        flightpath.sceneInstance.rotation.set(normalization.rotation.x, normalization.rotation.y, normalization.rotation.z);
                        flightpath.sceneInstance.translateX(normalization.translation.x);
                        flightpath.sceneInstance.translateY(normalization.translation.y);
                        flightpath.sceneInstance.translateZ(normalization.translation.z);

                        flightpath.sceneInstance.name = item.model+"_"+item.filename+"_Flightpath";
                        that.threeContext.scene.add(flightpath.sceneInstance);
                        that.renderedItem.flightpathInstance = flightpath.sceneInstance;
                        resolve(flightpath);
                    }
                }
                else{
                    flightpath = computeFlightpath(vertex_buffer, normal_buffer, that.models[model_index].model.normalization);
                    flightpath.sceneInstance.name = item.model+"_"+item.filename+"_Flightpath";
                    that.threeContext.scene.add(flightpath.sceneInstance);
                    resolve(flightpath);
                }
            }
            else {
                var error = "No instance for: "+item.model+" loaded in Three.";
                reject(error);
            }
        }),
        Worker: flightpath_worker};
    }
}

export {ThreeInterface}
