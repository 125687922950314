import * as Three from "three";
import {Vector3} from "three";

// resolution in meter per pixel
const resolution = 0.0002
// sensor size in m
const sensor_size = [0.0358, 0.0239];
// image dimension in pixel
const img_dims = [7360, 4912];
// focal length in m
const f = 0.055;
const k1 = 0.0;
const k2 = 0.0;

const showNormalSegments = false;
const skipUnnecessaryProject = false

class Node {
    constructor(point, index) {
        this.point = point;
        this.index = index;
        this.children = [];
        this.parent = undefined;
        this.visited = false;
        this.preOrderVisited = false;
    }

    setParent(parent) {
        if (this.parent === undefined) {
            this.parent = parent;
        } else {
            console.log("Error. Trying to set an already set parent!");
        }
    }

    addChild(child) {
        this.children.push(child);
    }

    preOrderTraversal(curTraversal){
        curTraversal.push(this.index);
        for(let x = 0; x < this.children.length; x++){
            var curChild = this.children[x];
            curChild.preOrderTraversal(curTraversal);
            // uncomment this line to see the complete MST
            //curTraversal.push(this.index);
        }
        this.preOrderVisited = true;
    }
}

class Point {
    constructor(x, y, z) {
        this.x = x;
        this.y = y;
        this.z = z;
        this.index = -1;
    }

    setIndex(index) {
        this.index = index;
    }

    getIndex() {
        return this.index;
    }

    add(point) {
        return new Point(this.x + point.x, this.y + point.y, this.z + point.z);
    }

    sub(point) {
        return new Point(this.x - point.x, this.y - point.y, this.z - point.z);
    }

    dot(point) {
        return this.x * point.x + this.y * point.y + this.z * point.z;
    }

    length() {
        return Math.sqrt(this.x * this.x + this.y * this.y + this.z * this.z);
    }

    angle(point) {
        return Math.acos( this.dot(point) / (this.length() + point.length()));
    }

    scale(a) {
        return new Point(this.x * a, this.y * a, this.z * a);
    }

    cross(point) {
        // correct
        var x = this.y * point.z - this.z * point.y;
        var y = this.z * point.x - this.x * point.z;
        var z = this.x * point.y - this.y * point.x;
        var p = new Point(x, y, z);
        //var dotP1 = this.dot(p);
        //var dotP2 = point.dot(p);
        //if(Math.abs(dotP1) > 1e-10 || Math.abs(dotP2) > 1e-10){
        //    console.log("Error! The perpendicular vector was not computed correctly!");
        //}
        return p;
    }

    normalize() {
        var length = this.length();
        this.x /= length;
        this.y /= length;
        this.z /= length;
    }

    perpendicularVector() {
        // correct
        var x = -1;
        var y = -1;
        var z = -1;

        if (this.x != 0) {
            z = 0;
            y = 1;
            x = -(this.z * z + this.y * y) / this.x;
        } else if (this.y != 0) {
            x = 1;
            z = 0;
            y = -(this.x * x + this.z * z) / this.y;
        } else if (this.z != 0) {
            x = 0;
            y = 1;
            z = -(this.x * x + this.y * y) / this.z;
        }

        var perpendicular = new Point(x, y, z);
        //var dotProd = this.dot(perpendicular);
        //if(Math.abs(dotProd) > 1e-10){
        //    console.log("Error! The perpendicular vector was not computed correctly!");
        //}

        return perpendicular;
    }
}

class Triangle {
    /*
    constructor(pointA, pointB, pointC) {
        this.a = pointA;
        this.b = pointB;
        this.c = pointC;

        this.center = new Point((this.a.x + this.b.x + this.c.x) / 3.0, (this.a.y + this.b.y + this.c.y) / 3.0, (this.a.z + this.b.z + this.c.z) / 3.0)

        var u = new Point(this.b.x - this.a.x, this.b.y - this.a.y, this.b.z - this.a.z);
        var v = new Point(this.c.x - this.a.x, this.c.y - this.a.y, this.c.z - this.a.z);

        var normal = new Point(u.y * v.z - u.z * v.y, u.z * v.x - u.x * v.z, u.x * v.y - u.y * v.x);
        var length = Math.sqrt(normal.x * normal.x + normal.y * normal.y + normal.z * normal.z);

        // TODO: check how we can correctly determine the normal (-normal or normal?)
        this.normal = new Point(normal.x / length, normal.y / length, normal.z / length);
    }
    */

    constructor(pointA, pointB, pointC, normal, bestFittingNormalIndex) {
        this.a = pointA;
        this.b = pointB;
        this.c = pointC;
        this.center = new Point((this.a.x + this.b.x + this.c.x) / 3.0, (this.a.y + this.b.y + this.c.y) / 3.0, (this.a.z + this.b.z + this.c.z) / 3.0)
        this.normal = normal;
        this.bestFittingNormalIndex = bestFittingNormalIndex;
        this.cameraCount = 0;
        this.cameraCenter = false;
    }

    setIndex(index){
        this.index = index
    }

    maxEdgeLength(){
        var lenghtAB = this.a.sub(this.b).length();
        var lenghtAC = this.a.sub(this.c).length();
        var lenghtBC = this.b.sub(this.c).length();
        return Math.max(lenghtAB, lenghtAC, lenghtBC);
    }
}

function sortVertices() {
    return function (point1, point2) {

        const eps = 0.001;

        if (point1.x > point2.x) {
            return 1;
        } else if (point1.x < point2.x) {
            return -1;
        } else {
            if (point1.y > point2.y) {
                return 1;
            } else if (point1.y < point2.y) {
                return -1;
            } else {
                if (point1.z > point2.z) {
                    return 1;
                } else if (point1.z < point2.z) {
                    return -1;
                } else {
                    // both points are most likely identical
                    return 0;
                }
            }
        }
    }
}

function computeAllowedCameraDistances() {
    const pix_size_x = sensor_size[0] / img_dims[0];
    const pix_size_y = sensor_size[1] / img_dims[1];

    const optimal_distance = f * resolution / pix_size_x;

    // opening angle of the camera in horizontal direction
    const angle = 2 * Math.atan(sensor_size[0] / (2 * f));
    // maximal horizontal width at the maximum distance from the camera that is allowed
    // computed using formulas from https://www.redcrab-software.com/de/Rechner/Gleichschenkliges-Dreieck
    const max_width = (1.1 * optimal_distance) * 2 / Math.tan(Math.PI / 2.0 - angle / 2);

    // minimal distance to the camera, maximum distance to the camera, max horizontal width
    const allowed_dist = [0.9 * optimal_distance, 1.1 * optimal_distance, max_width];

    return allowed_dist;
}

function computeCameraProjectionMatrix(viewpoint, surfaceNormal) {
    // R - rotation matrix from view direction and up vector
    // see: https://stackoverflow.com/questions/18558910/direction-vector-to-rotation-matrix
    var R = [[1.0, 0.0, 0.0], [0.0, 1.0, 0.0], [0.0, 0.0, 1.0]];

    var viewDirection = surfaceNormal.scale(-1);
    viewDirection.normalize();

    //var up = new Point(0,1,0);
    var up = viewDirection.perpendicularVector();
    up.normalize();

    var xAxis = viewDirection.cross(up);
    xAxis.normalize();

    var yAxis = viewDirection.cross(xAxis);
    yAxis.normalize();

    R[0][0] = xAxis.x;
    R[0][1] = xAxis.y;
    R[0][2] = xAxis.z;
    R[1][0] = yAxis.x;
    R[1][1] = yAxis.y;
    R[1][2] = yAxis.z;
    R[2][0] = viewDirection.x;
    R[2][1] = viewDirection.y;
    R[2][2] = viewDirection.z;

    // K - Camera calibration matrix
    var K = [[0.0, 0.0, 0.0], [0.0, 0.0, 0.0], [0.0, 0.0, 0.0]];
    var KR = [[0.0, 0.0, 0.0], [0.0, 0.0, 0.0], [0.0, 0.0, 0.0]];
    var IC = [[0.0, 0.0, 0.0, 0.0], [0.0, 0.0, 0.0, 0.0], [0.0, 0.0, 0.0, 0.0]];
    var P = [[0.0, 0.0, 0.0, 0.0], [0.0, 0.0, 0.0, 0.0], [0.0, 0.0, 0.0, 0.0]];

    K[0][0] = f * img_dims[0] / sensor_size[0];
    K[1][1] = f * img_dims[1] / sensor_size[1];
    // we assume the principal point to be in the center of the image
    K[0][2] = img_dims[0] / 2.0;
    K[1][2] = img_dims[1] / 2.0;
    K[2][2] = 1.0;

    var C = [viewpoint.x, viewpoint.y, viewpoint.z];

    //x = K * R * [I | -C]

    // this is correct
    KR[0][0] = K[0][0] * R[0][0] + K[0][1] * R[1][0] + K[0][2] * R[2][0];
    KR[0][1] = K[0][0] * R[0][1] + K[0][1] * R[1][1] + K[0][2] * R[2][1];
    KR[0][2] = K[0][0] * R[0][2] + K[0][1] * R[1][2] + K[0][2] * R[2][2];
    KR[1][0] = K[1][0] * R[0][0] + K[1][1] * R[1][0] + K[1][2] * R[2][0];
    KR[1][1] = K[1][0] * R[0][1] + K[1][1] * R[1][1] + K[1][2] * R[2][1];
    KR[1][2] = K[1][0] * R[0][2] + K[1][1] * R[1][2] + K[1][2] * R[2][2];
    KR[2][0] = K[2][0] * R[0][0] + K[2][1] * R[1][0] + K[2][2] * R[2][0];
    KR[2][1] = K[2][0] * R[0][1] + K[2][1] * R[1][1] + K[2][2] * R[2][1];
    KR[2][2] = K[2][0] * R[0][2] + K[2][1] * R[1][2] + K[2][2] * R[2][2];

    // this is correct
    IC[0][0] = 1.0;
    IC[0][1] = 0.0;
    IC[0][2] = 0.0;
    IC[0][3] = -C[0];
    IC[1][0] = 0.0;
    IC[1][1] = 1.0;
    IC[1][2] = 0.0;
    IC[1][3] = -C[1];
    IC[2][0] = 0.0;
    IC[2][1] = 0.0;
    IC[2][2] = 1.0;
    IC[2][3] = -C[2];

    // this is correct
    P[0][0] = KR[0][0] * IC[0][0] + KR[0][1] * IC[1][0] + KR[0][2] * IC[2][0];
    P[0][1] = KR[0][0] * IC[0][1] + KR[0][1] * IC[1][1] + KR[0][2] * IC[2][1];
    P[0][2] = KR[0][0] * IC[0][2] + KR[0][1] * IC[1][2] + KR[0][2] * IC[2][2];
    P[0][3] = KR[0][0] * IC[0][3] + KR[0][1] * IC[1][3] + KR[0][2] * IC[2][3];
    P[1][0] = KR[1][0] * IC[0][0] + KR[1][1] * IC[1][0] + KR[1][2] * IC[2][0];
    P[1][1] = KR[1][0] * IC[0][1] + KR[1][1] * IC[1][1] + KR[1][2] * IC[2][1];
    P[1][2] = KR[1][0] * IC[0][2] + KR[1][1] * IC[1][2] + KR[1][2] * IC[2][2];
    P[1][3] = KR[1][0] * IC[0][3] + KR[1][1] * IC[1][3] + KR[1][2] * IC[2][3];
    P[2][0] = KR[2][0] * IC[0][0] + KR[2][1] * IC[1][0] + KR[2][2] * IC[2][0];
    P[2][1] = KR[2][0] * IC[0][1] + KR[2][1] * IC[1][1] + KR[2][2] * IC[2][1];
    P[2][2] = KR[2][0] * IC[0][2] + KR[2][1] * IC[1][2] + KR[2][2] * IC[2][2];
    P[2][3] = KR[2][0] * IC[0][3] + KR[2][1] * IC[1][3] + KR[2][2] * IC[2][3];

    if (Number.isNaN(P[0][0])) {
        console.log("Something went horribly wrong. Abort mission.")
    }
    return [P, R];
}

function subdivideTriangle(currentTriangle, maxEdgeLength, currentArray){
    if(currentTriangle.maxEdgeLength() > maxEdgeLength){
        var lenghtAB = currentTriangle.a.sub(currentTriangle.b).length();
        var lenghtAC = currentTriangle.a.sub(currentTriangle.c).length();
        var lenghtBC = currentTriangle.b.sub(currentTriangle.c).length();

        var triangle1 = null
        var triangle2 = null

        if(lenghtAB >= lenghtAC && lenghtAB >= lenghtBC){
            var midAB = currentTriangle.a.add(currentTriangle.b).scale(0.5);
            triangle1 = new Triangle(midAB, currentTriangle.a, currentTriangle.c, currentTriangle.normal, currentTriangle.bestFittingNormalIndex);
            triangle2 = new Triangle(midAB, currentTriangle.b, currentTriangle.c, currentTriangle.normal, currentTriangle.bestFittingNormalIndex);
        } else if(lenghtAC >= lenghtAB && lenghtAC >= lenghtBC){
            var midAC = currentTriangle.a.add(currentTriangle.c).scale(0.5);
            triangle1 = new Triangle(midAC, currentTriangle.a, currentTriangle.b, currentTriangle.normal, currentTriangle.bestFittingNormalIndex);
            triangle2 = new Triangle(midAC, currentTriangle.c, currentTriangle.b, currentTriangle.normal, currentTriangle.bestFittingNormalIndex);
        } else if(lenghtBC >= lenghtAB && lenghtBC >= lenghtAC){
            var midBC = currentTriangle.b.add(currentTriangle.c).scale(0.5);
            triangle1 = new Triangle(midBC, currentTriangle.b, currentTriangle.a, currentTriangle.normal, currentTriangle.bestFittingNormalIndex);
            triangle2 = new Triangle(midBC, currentTriangle.c, currentTriangle.a, currentTriangle.normal, currentTriangle.bestFittingNormalIndex);
        }

        currentArray.concat(subdivideTriangle(triangle1,maxEdgeLength, currentArray));
        currentArray.concat(subdivideTriangle(triangle2,maxEdgeLength, currentArray));
        return currentArray;

    } else {
        return [currentTriangle];
    }
}

export function computeFlightpath(vertex_buffer, normal_buffer, transform = {
    translation: new Vector3(0.0, 0.0, 0.0),
    rotation: new Vector3(0.0, 0.0, 0.0),
    scale: 1.0
}) {
    console.log(vertex_buffer);
    var test_viewpoint = new Point(0, 0, 0);
    var test_surface_normal = new Point(0, 0, 1);

    var matrices = computeCameraProjectionMatrix(test_viewpoint, test_surface_normal);
    var test_projection_matrix = matrices[0];
    var test_point = [0, 0, -1];

    var pr_x_test = test_point[0] * test_projection_matrix[0][0] + test_point[1] * test_projection_matrix[0][1] + test_point[2] * test_projection_matrix[0][2] + test_projection_matrix[0][3];
    var pr_y_test = test_point[0] * test_projection_matrix[1][0] + test_point[1] * test_projection_matrix[1][1] + test_point[2] * test_projection_matrix[1][2] + test_projection_matrix[1][3];
    var pr_w_test = test_point[0] * test_projection_matrix[2][0] + test_point[1] * test_projection_matrix[2][1] + test_point[2] * test_projection_matrix[2][2] + test_projection_matrix[2][3];

    var test_projected = [pr_x_test / pr_w_test, pr_y_test / pr_w_test];

    if (test_projected[0] != img_dims[0] / 2 && test_projected[1] != img_dims[1] / 2) {
        console.log("Projection Matrix computation seems to be broken!");
    }


    // compute the minimal and maximal distance a point should have to the camera
    const allowed_dist = computeAllowedCameraDistances();
    // find the maximum distance that will be used to separate the 3d space into bounding boxes
    const maxD = Math.max(...allowed_dist);

    var triangles = [];
    var raw_positions = [];
    var positions = [];
    var vertices = [];
    var colors = [];
    var color = new Three.Color();

    const segments = 25;
    var segment_normals = [];

    // -----------------------------------------------------------------------------------------------------------------
    // define different normals that have roughly the same distance from each other for the normal segmentation
    // this is done using the fibonacci sphere algorithm see https://stackoverflow.com/a/26127012
    var phi = Math.PI * (3. - Math.sqrt(5.))
    for (let i = 0; i < segments; i++) {

        var y = 1 - (i / (segments - 1)) * 2
        var radius = Math.sqrt(1 - y * y)
        var theta = phi * i
        var x = Math.cos(theta) * radius
        var z = Math.sin(theta) * radius

        var normal = new Point(x, y, z);

        segment_normals.push(normal);
    }
    // -----------------------------------------------------------------------------------------------------------------

    // these values will define the mesh bounding box from which we will then derive a separation into cubes
    var minX = Number.MAX_VALUE;
    var minY = Number.MAX_VALUE;
    var minZ = Number.MAX_VALUE;
    var maxX = Number.MIN_VALUE;
    var maxY = Number.MIN_VALUE;
    var maxZ = Number.MIN_VALUE;

    // -----------------------------------------------------------------------------------------------------------------
    // iterate over the vertex buffers and create all triangles with their corresponding normals
    // find the best fitting segmentation normal and add the triangle to this normal group
    for (let j = 0; j < vertex_buffer.length; j++) {
        var triangleBuffer = vertex_buffer[j];
        var normalBuffer = normal_buffer[j];
        var triangleIndex = 0;
        for (let i = 0; i < triangleBuffer.length; i += 9) {

            // TODO: if the triangle is to large subdivide it into more triangles in order to increase the resolution!
            var a = new Point(triangleBuffer[i], triangleBuffer[i + 1], triangleBuffer[i + 2]);
            vertices.push(a);
            var b = new Point(triangleBuffer[i + 3], triangleBuffer[i + 4], triangleBuffer[i + 5]);
            vertices.push(b);
            var c = new Point(triangleBuffer[i + 6], triangleBuffer[i + 7], triangleBuffer[i + 8]);
            vertices.push(c);

            if (Math.min(triangleBuffer[i], triangleBuffer[i + 3], triangleBuffer[i + 6]) < minX)
                minX = Math.min(triangleBuffer[i], triangleBuffer[i + 3], triangleBuffer[i + 6]);

            if (Math.max(triangleBuffer[i], triangleBuffer[i + 3], triangleBuffer[i + 6]) > maxX)
                maxX = Math.max(triangleBuffer[i], triangleBuffer[i + 3], triangleBuffer[i + 6]);

            if (Math.min(triangleBuffer[i + 1], triangleBuffer[i + 4], triangleBuffer[i + 7]) < minY)
                minY = Math.min(triangleBuffer[i + 1], triangleBuffer[i + 4], triangleBuffer[i + 7]);

            if (Math.max(triangleBuffer[i + 1], triangleBuffer[i + 4], triangleBuffer[i + 7]) > maxY)
                maxY = Math.max(triangleBuffer[i + 1], triangleBuffer[i + 4], triangleBuffer[i + 7]);

            if (Math.min(triangleBuffer[i + 2], triangleBuffer[i + 5], triangleBuffer[i + 8]) < minZ)
                minZ = Math.min(triangleBuffer[i + 2], triangleBuffer[i + 5], triangleBuffer[i + 8]);

            if (Math.max(triangleBuffer[i + 2], triangleBuffer[i + 5], triangleBuffer[i + 8]) > maxZ)
                maxZ = Math.max(triangleBuffer[i + 2], triangleBuffer[i + 5], triangleBuffer[i + 8]);

            // compute the triangle normal as the average of all vertex normals
            var nx1 = normalBuffer[i];
            var ny1 = normalBuffer[i + 1];
            var nz1 = normalBuffer[i + 2];

            var nx2 = normalBuffer[i + 3];
            var ny2 = normalBuffer[i + 4];
            var nz2 = normalBuffer[i + 5];

            var nx3 = normalBuffer[i + 6];
            var ny3 = normalBuffer[i + 7];
            var nz3 = normalBuffer[i + 8];

            var n = new Point((nx1 + nx2 + nx3) / 3, (ny1 + ny2 + ny3) / 3, (nz1 + nz2 + nz3) / 3);

            // find the best fitting normal from the segmentation normals in order to assign it to the triangle
            var min_angle = 100.0;
            var best_normal = undefined;
            var norm_n = Math.sqrt(n.x * n.x + n.y * n.y + n.z * n.z);
            var best_index = 0;

            for (let k = 0; k < segment_normals.length; k++) {
                var dot = n.x * segment_normals[k].x + n.y * segment_normals[k].y + n.z * segment_normals[k].z;
                var norm_s = Math.sqrt(segment_normals[k].x * segment_normals[k].x + segment_normals[k].y * segment_normals[k].y + segment_normals[k].z * segment_normals[k].z);
                var angle = Math.acos(dot / (norm_n * norm_s));

                if (angle < min_angle) {
                    min_angle = angle;
                    best_normal = segment_normals[k];
                    best_index = k;
                }
            }

            var triangle = new Triangle(a, b, c, n, best_index);
            // if triangle edge with is larger than max camera image width then split the triangle
            if(triangle.maxEdgeLength() > allowed_dist[2]){
                var subdividedTriangles = subdivideTriangle(triangle, allowed_dist[2], []);
                for(let h = 0; h < subdividedTriangles.length; h++){
                    triangleIndex += 1;
                    triangle.setIndex(triangleIndex);
                    triangles.push(subdividedTriangles[h]);
                }
            } else {
                triangleIndex += 1;
                triangle.setIndex(triangleIndex);
                triangles.push(triangle);
            }
        }
    }
    // -----------------------------------------------------------------------------------------------------------------

    // -----------------------------------------------------------------------------------------------------------------
    // sort the vertices in order to derive a unique index for each vertex and update it for later usage
    vertices.sort(sortVertices());

    var currentIndex = 0;
    var lastPoint = undefined;
    const eps = 0.00001;
    for (let j = 0; j < vertices.length; j++) {
        if (lastPoint === undefined) {
            lastPoint = vertices[j];
        } else {
            if (Math.abs(vertices[j].x - lastPoint.x) < eps &&
                Math.abs(vertices[j].y - lastPoint.y) < eps &&
                Math.abs(vertices[j].z - lastPoint.z) < eps) {
                vertices[j].setIndex(currentIndex);
            } else {
                currentIndex += 1;
                vertices[j].setIndex(currentIndex);
                lastPoint = vertices[j];
            }
        }
    }
    // -----------------------------------------------------------------------------------------------------------------


    // -----------------------------------------------------------------------------------------------------------------
    // detect which triangles belong to each vertex and test which vertices are on edges
    var trianglesPerIndex = [];
    var vertexIsEdge = [];
    var vertexIsEdgeNominated = [];
    var maxEdgeAngle = 80.0 * Math.PI / 180.0;

    for(let j = 0; j < currentIndex; j++){
        trianglesPerIndex.push([]);
        vertexIsEdgeNominated.push(-1)
        vertexIsEdge.push(false);
    }

    for(let j = 0; j < triangles.length; j++){
        var aIndex = triangles[j].a.getIndex();
        var bIndex = triangles[j].b.getIndex();
        var cIndex = triangles[j].c.getIndex();

        if(0 <= aIndex && aIndex < currentIndex)
            trianglesPerIndex[aIndex].push(j);
        if(0 <= bIndex && bIndex < currentIndex)
            trianglesPerIndex[bIndex].push(j);
        if(0 <= cIndex && cIndex < currentIndex)
            trianglesPerIndex[cIndex].push(j);
    }

    var averageAngle = 0;
    for(let j = 0; j < trianglesPerIndex.length; j++){
        var currentBaseNormal = undefined;
        for(let k = 0; k < trianglesPerIndex[j].length; k++){
            var currentTriangle = triangles[trianglesPerIndex[j][k]]
            if(currentBaseNormal === undefined){
                currentBaseNormal = currentTriangle.normal;
            } else {
                var currentAngle = currentBaseNormal.angle(currentTriangle.normal)
                averageAngle += (currentAngle/(trianglesPerIndex[j].length-1))/trianglesPerIndex.length
            }
        }
    }

    maxEdgeAngle = averageAngle * 1.05
    if(maxEdgeAngle >= Math.PI/2.0){
        maxEdgeAngle = Math.PI/2.0 * 0.9
    }

    for(let j = 0; j < trianglesPerIndex.length; j++){
        var currentBaseNormal = undefined;
        for(let k = 0; k < trianglesPerIndex[j].length; k++){
            var currentTriangle = triangles[trianglesPerIndex[j][k]]
            if(currentBaseNormal === undefined){
                currentBaseNormal = currentTriangle.normal;
            } else {
                var currentAngle = currentBaseNormal.angle(currentTriangle.normal)
                if(currentAngle > maxEdgeAngle){
                    vertexIsEdgeNominated[j] += currentAngle;
                }
            }
        }
    }

    var minEdgeProof = 15 * Math.PI / 180

    for(let j = 0; j < triangles.length; j++){
        var currentTriangle = triangles[j]
        if(vertexIsEdgeNominated[currentTriangle.a.getIndex()] >= minEdgeProof && vertexIsEdgeNominated[currentTriangle.b.getIndex()] >= minEdgeProof){
            vertexIsEdge[currentTriangle.a.getIndex()] = true;
            vertexIsEdge[currentTriangle.b.getIndex()] = true;
        }
        if(vertexIsEdgeNominated[currentTriangle.a.getIndex()] >= minEdgeProof && vertexIsEdgeNominated[currentTriangle.c.getIndex()] >= minEdgeProof){
            vertexIsEdge[currentTriangle.a.getIndex()] = true;
            vertexIsEdge[currentTriangle.c.getIndex()] = true;
        }
        if(vertexIsEdgeNominated[currentTriangle.c.getIndex()] >= minEdgeProof && vertexIsEdgeNominated[currentTriangle.b.getIndex()] >= minEdgeProof){
            vertexIsEdge[currentTriangle.c.getIndex()] = true;
            vertexIsEdge[currentTriangle.b.getIndex()] = true;
        }
    }

    // -----------------------------------------------------------------------------------------------------------------

    // -----------------------------------------------------------------------------------------------------------------
    // add the triangles to the corresponding cubes
    var xCubeNumber = Math.ceil((maxX - minX) / maxD);
    var yCubeNumber = Math.ceil((maxY - minY) / maxD);
    var zCubeNumber = Math.ceil((maxZ - minZ) / maxD);

    if(xCubeNumber === 0)
        xCubeNumber = 1;
    if(yCubeNumber === 0)
        yCubeNumber = 1;
    if(zCubeNumber === 0)
        zCubeNumber = 1

    console.log(xCubeNumber);
    console.log(yCubeNumber);
    console.log(zCubeNumber);

    // TODO: visualize the computed cubes in three js
    var cubes = [];

    for (let j = 0; j < xCubeNumber; j++) {
        var xArray = [];
        for (let k = 0; k < yCubeNumber; k++) {
            var yArray = [];
            for (let l = 0; l < zCubeNumber; l++) {
                var zArray = [];
                yArray.push(zArray);
            }
            xArray.push(yArray);
        }
        cubes.push(xArray);
    }

    for (let j = 0; j < triangles.length; j++) {
        var triangle = triangles[j];

        var xCubeIndex = Math.floor((triangle.center.x - minX) / maxD);
        var yCubeIndex = Math.floor((triangle.center.y - minY) / maxD);
        var zCubeIndex = Math.floor((triangle.center.z - minZ) / maxD);

        cubes[xCubeIndex][yCubeIndex][zCubeIndex].push(triangle);

        if (showNormalSegments) {
            positions.push(triangle.center.x + triangle.normal.x, triangle.center.y + triangle.normal.y, triangle.center.z + triangle.normal.z);

            color.setRGB((segment_normals[triangle.bestFittingNormalIndex].x + 1.0) / 2.0,
                (segment_normals[triangle.bestFittingNormalIndex].y + 1.0) / 2.0,
                (segment_normals[triangle.bestFittingNormalIndex].z + 1.0) / 2.0);
            colors.push(color.r, color.g, color.b);
        }
    }

    console.log(cubes);
    // -----------------------------------------------------------------------------------------------------------------

    // -----------------------------------------------------------------------------------------------------------------
    // go through each cube and try to find the best viewpoints (note that neighbouring cubes have to taken
    // into consideration as well! Note that the triangle vertex indices can be used to check whether triangles are adjacent
    var rotationMatrices = [];
    var viewpoints = [];

    var total_iterations = 0;
    var total_wrong_projections = 0;
    var total_correct_projection = 0;

    var minXViewP = Number.MAX_VALUE;
    var minYViewP = Number.MAX_VALUE;
    var minZViewP = Number.MAX_VALUE;
    var maxXViewP = Number.MIN_VALUE;
    var maxYViewP = Number.MIN_VALUE;
    var maxZViewP = Number.MIN_VALUE;

    //TEMP
    var edgepoints = 0

    for (let x = 0; x < xCubeNumber; x++) {
        for (let y = 0; y < yCubeNumber; y++) {
            for (let z = 0; z < zCubeNumber; z++) {


                var cube = cubes[x][y][z];
                var relevant_cubes = [cube];

                // add the 6 cubes that are one the direct sides of the cube
                // these commands could be combined in a few for loops but it would make everything less understandable i guess
                if (x > 0)
                    relevant_cubes.push(cubes[x - 1][y][z]);
                if (y > 0)
                    relevant_cubes.push(cubes[x][y - 1][z]);
                if (z > 0)
                    relevant_cubes.push(cubes[x][y][z - 1]);
                if (x < xCubeNumber - 1)
                    relevant_cubes.push(cubes[x + 1][y][z]);
                if (y < yCubeNumber - 1)
                    relevant_cubes.push(cubes[x][y + 1][z]);
                if (z < zCubeNumber - 1)
                    relevant_cubes.push(cubes[x][y][z + 1]);

                // add the 8 cubes that are completely diagonally of the cube
                // these commands could be combined in a few for loops but it would make everything less understandable i guess
                if (x > 0 && y > 0 && z > 0)
                    relevant_cubes.push(cubes[x - 1][y - 1][z - 1]);
                if (x > 0 && y > 0 && z < zCubeNumber - 1)
                    relevant_cubes.push(cubes[x - 1][y - 1][z + 1]);
                if (x > 0 && y < yCubeNumber - 1 && z > 0)
                    relevant_cubes.push(cubes[x - 1][y + 1][z - 1]);
                if (x > 0 && y < yCubeNumber - 1 && z < zCubeNumber - 1)
                    relevant_cubes.push(cubes[x - 1][y + 1][z + 1]);
                if (x < xCubeNumber - 1 && y > 0 && z > 0)
                    relevant_cubes.push(cubes[x + 1][y - 1][z - 1]);
                if (x < xCubeNumber - 1 && y > 0 && z < zCubeNumber - 1)
                    relevant_cubes.push(cubes[x + 1][y - 1][z + 1]);
                if (x < xCubeNumber - 1 && y < yCubeNumber - 1 && z > 0)
                    relevant_cubes.push(cubes[x + 1][y + 1][z - 1]);
                if (x < xCubeNumber - 1 && y < yCubeNumber - 1 && z < zCubeNumber - 1)
                    relevant_cubes.push(cubes[x + 1][y + 1][z + 1]);

                // add the 12 cubes that are sideways diagonally of the cube
                // these commands could be combined in a few for loops but it would make everything less understandable i guess
                if (x > 0 && y > 0)
                    relevant_cubes.push(cubes[x - 1][y - 1][z]);
                if (x > 0 && y < yCubeNumber - 1)
                    relevant_cubes.push(cubes[x - 1][y + 1][z]);
                if (x < xCubeNumber - 1 && y > 0)
                    relevant_cubes.push(cubes[x + 1][y - 1][z]);
                if (x < xCubeNumber - 1 && y < yCubeNumber - 1)
                    relevant_cubes.push(cubes[x + 1][y + 1][z]);
                if (x > 0 && z > 0)
                    relevant_cubes.push(cubes[x - 1][y][z - 1]);
                if (x > 0 && z < zCubeNumber - 1)
                    relevant_cubes.push(cubes[x - 1][y][z + 1]);
                if (x < xCubeNumber - 1 && z > 0)
                    relevant_cubes.push(cubes[x + 1][y][z - 1]);
                if (x < xCubeNumber - 1 && z < zCubeNumber - 1)
                    relevant_cubes.push(cubes[x + 1][y][z + 1]);
                if (y > 0 && z > 0)
                    relevant_cubes.push(cubes[x][y - 1][z - 1]);
                if (y > 0 && z < zCubeNumber - 1)
                    relevant_cubes.push(cubes[x][y - 1][z + 1]);
                if (y < yCubeNumber - 1 && z > 0)
                    relevant_cubes.push(cubes[x][y + 1][z - 1]);
                if (y < yCubeNumber - 1 && z < zCubeNumber - 1)
                    relevant_cubes.push(cubes[x][y + 1][z + 1]);

                var finished = false;
                var maxIter = 100;
                var curIter = 0;
                var maxViewPoints = Number.MAX_VALUE;

                while (!finished && curIter < maxIter && viewpoints.length < maxViewPoints) {

                    curIter++;
                    finished = true;

                    var currentNormal = undefined;
                    var normalTriangles = [];

                    var normal_count = new Array(segments).fill(0);
                    for (let t = 0; t < cube.length; t++) {
                        var triangle = cube[t];
                        if (triangle.cameraCount < 3 && currentNormal === undefined && triangle.cameraCenter == false) {
                            normal_count[triangle.bestFittingNormalIndex] += 1;
                            finished = false;
                        }
                    }

                    var mostFreqNormal = 0;
                    var maxOccur = 0;
                    for (let t = 0; t < segments; t++) {
                        if (normal_count[t] > maxOccur) {
                            maxOccur = normal_count[t];
                            mostFreqNormal = t;
                        }
                    }

                    var edgeTriangle = null;

                    for (let t = 0; t < cube.length; t++) {
                        var triangle = cube[t];
                        if (triangle.cameraCount < 3 && triangle.bestFittingNormalIndex == mostFreqNormal) {
                            normalTriangles.push(triangle);
                            if(vertexIsEdge[triangle.a.getIndex()] && vertexIsEdge[triangle.b.getIndex()]){
                                edgeTriangle = triangle;
                            }

                            if(vertexIsEdge[triangle.b.getIndex()] && vertexIsEdge[triangle.c.getIndex()]){
                                edgeTriangle = triangle;
                            }

                            if(vertexIsEdge[triangle.c.getIndex()] && vertexIsEdge[triangle.a.getIndex()]){
                                edgeTriangle = triangle;
                            }

                        }
                    }


                    if (normalTriangles.length > 0) {

                        // inline to add viewpoint
                        var addViewPoint = (viewpoint, randomTriangle, edge, edgeNormal) => {
                            if (!showNormalSegments) {
                                positions.push(viewpoint.x, viewpoint.y, viewpoint.z);
                                if(edge){
                                    color.setRGB(0.0, 1.0, 0.0);
                                    edgepoints++
                                } else {
                                    color.setRGB(0.0, 0.0, 1.0);
                                }
                                colors.push(color.r, color.g, color.b);

                                //positions.push(
                                //    randomTriangle.center.x + 0.2 * randomTriangle.normal.x,
                                //    randomTriangle.center.y + 0.2 * randomTriangle.normal.y,
                                //    randomTriangle.center.z + 0.2 * randomTriangle.normal.z);
                                //color.setRGB(0.0, 0.0, 1.0);
                                //colors.push(color.r, color.g, color.b);
                            }

                            var matrices = null
                            if(edge){
                                matrices = computeCameraProjectionMatrix(viewpoint, edgeNormal);
                            } else {
                                matrices = computeCameraProjectionMatrix(viewpoint, randomTriangle.normal);
                            }

                            var projectionMatrix = matrices[0];
                            var rotationMatrix = matrices[1];

                            viewpoints.push(viewpoint);

                            rotationMatrices.push(rotationMatrix);
                            if (viewpoint.x < minXViewP) {
                                minXViewP = viewpoint.x;
                            }
                            if (viewpoint.x > maxXViewP) {
                                maxXViewP = viewpoint.x;
                            }
                            if (viewpoint.y < minYViewP) {
                                minYViewP = viewpoint.y;
                            }
                            if (viewpoint.y > maxYViewP) {
                                maxYViewP = viewpoint.y;
                            }
                            if (viewpoint.z < minZViewP) {
                                minZViewP = viewpoint.z;
                            }
                            if (viewpoint.z > maxZViewP) {
                                maxZViewP = viewpoint.z;
                            }

                            // project point into image to see whether the point is visible
                            // see https://github.com/peterkovesi/ImageProjectiveGeometry.jl/blob/master/src/projective.jl#L177
                            for (let u = 0; u < relevant_cubes.length; u++) {
                                var current_cube = relevant_cubes[u];
                                for (let t = 0; t < current_cube.length; t++) {
                                    var triangle = current_cube[t];
                                    // TODO: skip projection if triangle was already seen 3 or more times
                                    var camera_vec = triangle.center.sub(viewpoint);
                                    var camera_dist = camera_vec.length();
                                    // only project the camera center onto the camera image plane if it is within the allowed distance
                                    if (allowed_dist[0] <= camera_dist && camera_dist <= allowed_dist[1]) {
                                        var pt = [triangle.center.x, triangle.center.y, triangle.center.z];
                                        var xy = [-1, -1];

                                        // this works its just P * x
                                        var prX = pt[0] * projectionMatrix[0][0] + pt[1] * projectionMatrix[0][1] + pt[2] * projectionMatrix[0][2] + projectionMatrix[0][3];
                                        var prY = pt[0] * projectionMatrix[1][0] + pt[1] * projectionMatrix[1][1] + pt[2] * projectionMatrix[1][2] + projectionMatrix[1][3];
                                        var prW = pt[0] * projectionMatrix[2][0] + pt[1] * projectionMatrix[2][1] + pt[2] * projectionMatrix[2][2] + projectionMatrix[2][3];

                                        xy[0] = prX / prW;
                                        xy[1] = prY / prW;

                                        if (0 <= xy[0] && xy[0] <= img_dims[0] && 0 <= xy[1] && xy[1] <= img_dims[1]) {
                                            triangle.cameraCount += 1;
                                            if (t == randomTriangleIndex && u == 0) {
                                                if (!(img_dims[0] - 10 <= xy[0] && xy[0] <= img_dims[0] + 10 &&
                                                    img_dims[1] - 10 <= xy[1] && xy[1] <= img_dims[1] + 10)) {
                                                    //console.log("The triangle center is not in the middle of the camera image...");
                                                    total_wrong_projections += 1;
                                                } else {
                                                    total_correct_projection += 1;
                                                }
                                            }
                                        } else {
                                            if (t == randomTriangleIndex && u == 0) {
                                                if (Number.isNaN(xy[0]) || Number.isNaN[xy[1]]) {
                                                    //console.log("Something went horribly wrong. Abort mission.")
                                                }
                                                //console.log("ERROR! The current point should be in the center of the image but isn't!");
                                                //console.log(pt);
                                                //console.log(xy);
                                                total_wrong_projections += 1;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        // chose triangle to generate viewpoint from
                        var randomTriangle = null;
                        if(edgeTriangle != null){
                            randomTriangle = edgeTriangle;
                        } else {
                            var randomTriangleIndex = Math.floor(Math.random() * normalTriangles.length);
                            randomTriangle = normalTriangles[randomTriangleIndex];
                        }

                        var cameraVector = randomTriangle.normal.scale(allowed_dist[1] / (1.1));
                        var viewpoint = randomTriangle.center.add(cameraVector);
                        //console.log("FacePoint: ",viewpoint)
                        addViewPoint(viewpoint, randomTriangle)
                        randomTriangle.cameraCenter = true;

                        let middle = null
                        let n = null
                        let adjacentTriangles = null
                        let edgeAdjacentTriangle = null

                        // check if triangle has edge representing a models edge
                        var getEdgeNormal = (p1, p2, triangle)=>{
                            adjacentTriangles = trianglesPerIndex[p1.getIndex()]
                            for(var i = 0; i<adjacentTriangles.length; i++){
                                if(adjacentTriangles[i]==triangle.index){
                                    continue;
                                }

                                if(triangles[adjacentTriangles[i]].a.getIndex() == p2.getIndex()){
                                    edgeAdjacentTriangle = triangles[adjacentTriangles[i]]
                                }
                                else if(triangles[adjacentTriangles[i]].b.getIndex() == p2.getIndex()){
                                    edgeAdjacentTriangle = triangles[adjacentTriangles[i]]
                                }
                                else if(triangles[adjacentTriangles[i]].c.getIndex() == p2.getIndex()){
                                    edgeAdjacentTriangle = triangles[adjacentTriangles[i]]
                                }
                            }

                            var edgeNormal = edgeAdjacentTriangle.normal.add(triangle.normal)
                            edgeNormal.normalize();

                            //console.log(edgeNormal);
                            return edgeNormal
                        }

                        if(vertexIsEdge[randomTriangle.a.getIndex()] && vertexIsEdge[randomTriangle.b.getIndex()]){
                            middle = new Point((randomTriangle.a.x + randomTriangle.b.x)/2.0, (randomTriangle.a.y + randomTriangle.b.y)/2.0, (randomTriangle.a.z + randomTriangle.b.z)/2.0)
                            n = getEdgeNormal(randomTriangle.a, randomTriangle.b,randomTriangle)
                            cameraVector = n.scale(allowed_dist[1] / (1.1));
                            //cameraVector = triangle.normal.scale(0.4)
                            viewpoint = middle.add(cameraVector);
                            //console.log("EdgePoint: ",viewpoint)
                            addViewPoint(viewpoint, randomTriangle, true, n)
                        }

                        if(vertexIsEdge[randomTriangle.b.getIndex()] && vertexIsEdge[randomTriangle.c.getIndex()]){
                            middle = new Point((randomTriangle.b.x + randomTriangle.c.x)/2.0, (randomTriangle.b.y + randomTriangle.c.y)/2.0, (randomTriangle.b.z + randomTriangle.c.z)/2.0)
                            n = getEdgeNormal(randomTriangle.b, randomTriangle.c,randomTriangle)
                            cameraVector = n.scale(allowed_dist[1] / (1.1));
                            //cameraVector = triangle.normal.scale(0.4)
                            viewpoint = middle.add(cameraVector);
                            //console.log("EdgePoint: ",viewpoint)
                            addViewPoint(viewpoint, randomTriangle, true, n)
                        }

                        if(vertexIsEdge[randomTriangle.c.getIndex()] && vertexIsEdge[randomTriangle.a.getIndex()]){
                            middle = new Point((randomTriangle.a.x + randomTriangle.c.x)/2.0, (randomTriangle.a.y + randomTriangle.c.y)/2.0, (randomTriangle.a.z + randomTriangle.c.z)/2.0)
                            n = getEdgeNormal(randomTriangle.a, randomTriangle.c, randomTriangle)
                            cameraVector = n.scale(allowed_dist[1] / (1.1));
                            //cameraVector = randomTriangle.normal.scale(0.4)
                            viewpoint = middle.add(cameraVector);
                            //console.log("EdgePoint: ",viewpoint)
                            addViewPoint(viewpoint, randomTriangle, true, n)
                        }
                    }
                }

                total_iterations += curIter;

            }
        }
    }

    console.log("Number of edge-points.", edgepoints)

    console.log("Finished after " + total_iterations + " iterations.");
    console.log("A total of " + viewpoints.length + " viewpoints has been calculated.");
    console.log("A total of " + total_wrong_projections + " points should be in the image center but are not.");
    console.log("A total of " + total_correct_projection + " points have been correctly projected.");
    // -----------------------------------------------------------------------------------------------------------------

    // -----------------------------------------------------------------------------------------------------------------
    // find the minimum spanning tree in order to find the faster path to connect all viewpoints
    // this is done using simple prims algorithm
    var nodes = [];

    // the smaller the factor the more cubes are being generated speeding up the mst computation
    var factor = 0.5;

    var xCubeNumberViewp = Math.ceil((maxXViewP - minXViewP) / (factor * maxD));
    var yCubeNumberViewp = Math.ceil((maxYViewP - minYViewP) / (factor * maxD));
    var zCubeNumberViewp = Math.ceil((maxZViewP - minZViewP) / (factor * maxD));

    if(xCubeNumberViewp === 0)
        xCubeNumberViewp = 1;
    if(yCubeNumberViewp === 0)
        yCubeNumberViewp = 1;
    if(zCubeNumberViewp === 0)
        zCubeNumberViewp = 1

    var node_cubes = [];
    var node_cube_index = [];

    for (let j = 0; j < xCubeNumberViewp; j++) {
        var xArray = [];
        for (let k = 0; k < yCubeNumberViewp; k++) {
            var yArray = [];
            for (let l = 0; l < zCubeNumberViewp; l++) {
                var zArray = [];
                yArray.push(zArray);
            }
            xArray.push(yArray);
        }
        node_cubes.push(xArray);
    }

    for (let x = 0; x < viewpoints.length; x++) {
        var node = new Node(viewpoints[x], x);

        var xCubeIndex = Math.floor((viewpoints[x].x - minXViewP) / maxD);
        var yCubeIndex = Math.floor((viewpoints[x].y - minYViewP) / maxD);
        var zCubeIndex = Math.floor((viewpoints[x].z - minZViewP) / maxD);

        node_cubes[xCubeIndex][yCubeIndex][zCubeIndex].push(node);
        node_cube_index.push([xCubeIndex, yCubeIndex, zCubeIndex]);
        nodes.push(node);
    }

    console.log("Trying to find minimum spanning tree!");
    // TODO: find a faster way to do this... it takes to long for more than 3000 viewpoints
    // TODO: implement https://www.cs.umd.edu/~mount/Papers/soda16-emst.pdf
    var treeNodesIndex = [0];
    nodes[0].visited = true;
    var current_percentage = 0.0;
    var last_percentage = 0.0;

    while (treeNodesIndex.length !== viewpoints.length) {
        var curMinCost = Number.MAX_VALUE;
        var curMinIndex = -1;
        var curParent = -1;

        last_percentage = current_percentage;
        current_percentage = treeNodesIndex.length / viewpoints.length;
        if (Math.floor(current_percentage * 100) !== Math.floor(last_percentage * 100)) {
            console.log(Math.floor(current_percentage * 100) + " %");
        }

        for (let l = 0; l < treeNodesIndex.length; l++) {
            var curCubeIndices = node_cube_index[treeNodesIndex[l]];
            var curVisitedNode = nodes[treeNodesIndex[l]];

            var x = curCubeIndices[0];
            var y = curCubeIndices[1];
            var z = curCubeIndices[2];

            var cube = node_cubes[x][y][z];
            var relevant_cubes = [cube];

            // add the 6 cubes that are one the direct sides of the cube
            // these commands could be combined in a few for loops but it would make everything less understandable i guess
            if (x > 0)
                relevant_cubes.push(node_cubes[x - 1][y][z]);
            if (y > 0)
                relevant_cubes.push(node_cubes[x][y - 1][z]);
            if (z > 0)
                relevant_cubes.push(node_cubes[x][y][z - 1]);
            if (x < xCubeNumberViewp - 1)
                relevant_cubes.push(node_cubes[x + 1][y][z]);
            if (y < yCubeNumberViewp - 1)
                relevant_cubes.push(node_cubes[x][y + 1][z]);
            if (z < zCubeNumberViewp - 1)
                relevant_cubes.push(node_cubes[x][y][z + 1]);

            // add the 8 cubes that are completely diagonally of the cube
            // these commands could be combined in a few for loops but it would make everything less understandable i guess
            if (x > 0 && y > 0 && z > 0)
                relevant_cubes.push(node_cubes[x - 1][y - 1][z - 1]);
            if (x > 0 && y > 0 && z < zCubeNumberViewp - 1)
                relevant_cubes.push(node_cubes[x - 1][y - 1][z + 1]);
            if (x > 0 && y < yCubeNumberViewp - 1 && z > 0)
                relevant_cubes.push(node_cubes[x - 1][y + 1][z - 1]);
            if (x > 0 && y < yCubeNumberViewp - 1 && z < zCubeNumberViewp - 1)
                relevant_cubes.push(node_cubes[x - 1][y + 1][z + 1]);
            if (x < xCubeNumberViewp - 1 && y > 0 && z > 0)
                relevant_cubes.push(node_cubes[x + 1][y - 1][z - 1]);
            if (x < xCubeNumberViewp - 1 && y > 0 && z < zCubeNumberViewp - 1)
                relevant_cubes.push(node_cubes[x + 1][y - 1][z + 1]);
            if (x < xCubeNumberViewp - 1 && y < yCubeNumberViewp - 1 && z > 0)
                relevant_cubes.push(node_cubes[x + 1][y + 1][z - 1]);
            if (x < xCubeNumberViewp - 1 && y < yCubeNumberViewp - 1 && z < zCubeNumberViewp - 1)
                relevant_cubes.push(node_cubes[x + 1][y + 1][z + 1]);

            // add the 12 cubes that are sideways diagonally of the cube
            // these commands could be combined in a few for loops but it would make everything less understandable i guess
            if (x > 0 && y > 0)
                relevant_cubes.push(node_cubes[x - 1][y - 1][z]);
            if (x > 0 && y < yCubeNumberViewp - 1)
                relevant_cubes.push(node_cubes[x - 1][y + 1][z]);
            if (x < xCubeNumberViewp - 1 && y > 0)
                relevant_cubes.push(node_cubes[x + 1][y - 1][z]);
            if (x < xCubeNumberViewp - 1 && y < yCubeNumberViewp - 1)
                relevant_cubes.push(node_cubes[x + 1][y + 1][z]);
            if (x > 0 && z > 0)
                relevant_cubes.push(node_cubes[x - 1][y][z - 1]);
            if (x > 0 && z < zCubeNumberViewp - 1)
                relevant_cubes.push(node_cubes[x - 1][y][z + 1]);
            if (x < xCubeNumberViewp - 1 && z > 0)
                relevant_cubes.push(node_cubes[x + 1][y][z - 1]);
            if (x < xCubeNumberViewp - 1 && z < zCubeNumberViewp - 1)
                relevant_cubes.push(node_cubes[x + 1][y][z + 1]);
            if (y > 0 && z > 0)
                relevant_cubes.push(node_cubes[x][y - 1][z - 1]);
            if (y > 0 && z < zCubeNumberViewp - 1)
                relevant_cubes.push(node_cubes[x][y - 1][z + 1]);
            if (y < yCubeNumberViewp - 1 && z > 0)
                relevant_cubes.push(node_cubes[x][y + 1][z - 1]);
            if (y < yCubeNumberViewp - 1 && z < zCubeNumberViewp - 1)
                relevant_cubes.push(node_cubes[x][y + 1][z + 1]);

            for (let k = 0; k < relevant_cubes.length; k++) {
                var curCube = relevant_cubes[k];
                for (let i = 0; i < curCube.length; i++) {
                    var curNode = curCube[i];
                    if (curNode.index !== curVisitedNode.index) {
                        var neighbourCost = curVisitedNode.point.sub(curNode.point).length();
                        if (neighbourCost < curMinCost && !nodes[curNode.index].visited) {
                            curMinCost = neighbourCost;
                            curMinIndex = curNode.index;
                            curParent = curVisitedNode;
                        }
                    }
                }
            }
        }
        if (curMinIndex === -1) {
            console.log("Warning! Could not find the next viewpoint using neighbour cubes thus naive search is used!");
            for (let l = 0; l < treeNodesIndex.length; l++) {
                var curVisitedNode = nodes[treeNodesIndex[l]];
                for (let m = 0; m < nodes.length; m++) {
                    if (m !== curVisitedNode.index) {
                        var neighbourCost = curVisitedNode.point.sub(nodes[m].point).length();
                        if (neighbourCost < curMinCost && !nodes[m].visited) {
                            curMinCost = neighbourCost;
                            curMinIndex = m;
                            curParent = curVisitedNode;
                        }
                    }
                }
            }
        }
        treeNodesIndex.push(curMinIndex);
        nodes[curMinIndex].visited = true;
        nodes[curMinIndex].setParent(curParent);
        curParent.addChild(nodes[curMinIndex]);
    }


    console.log(nodes);

    //TODO: implement preorder traversal to approximate the tsp solution by a factor of 2

    var root = nodes[0];
    var preOrderIndices = [];
    root.preOrderTraversal(preOrderIndices);

    var sortedViewpoints = [];
    var sortedRotationMatrices = [];
    var totalFlightpathLength = 0.0;
    var originalFlightpathLength = 0.0;
    for (let x = 0; x < preOrderIndices.length; x++) {
        if (x > 0) {
            totalFlightpathLength += viewpoints[preOrderIndices[x]].sub(viewpoints[preOrderIndices[x - 1]]).length();
            if(x < viewpoints.length) {
                originalFlightpathLength += viewpoints[x].sub(viewpoints[x - 1]).length();
            }
        }
        var cameraRotMat = rotationMatrices[preOrderIndices[x]];
        var viewpointInWorldCoord = viewpoints[preOrderIndices[x]];
        var viewpointTransform = new Point(-1,-1,-1);

        // this is done as -R * t = camera position in world coordinate according to https://www.cs.cornell.edu/~snavely/bundler/bundler-v0.4-manual.html
        viewpointTransform.x = -cameraRotMat[0][0] * viewpointInWorldCoord.x -cameraRotMat[0][1] * viewpointInWorldCoord.y - cameraRotMat[0][2] * viewpointInWorldCoord.z;
        viewpointTransform.y = -cameraRotMat[1][0] * viewpointInWorldCoord.x -cameraRotMat[1][1] * viewpointInWorldCoord.y - cameraRotMat[1][2] * viewpointInWorldCoord.z;
        viewpointTransform.z = -cameraRotMat[2][0] * viewpointInWorldCoord.x -cameraRotMat[2][1] * viewpointInWorldCoord.y - cameraRotMat[2][2] * viewpointInWorldCoord.z;

        sortedViewpoints.push(viewpointTransform);
        sortedRotationMatrices.push(cameraRotMat);
    }
    console.log("Total flightpath length: " + totalFlightpathLength);
    console.log("Original unoptimized flightpath length: " + originalFlightpathLength);
// -----------------------------------------------------------------------------------------------------------------

// -----------------------------------------------------------------------------------------------------------------
// color the visible triangles using the number of cameras that see the triangle
 var TEMP = 0
    for (let x = 0; x < xCubeNumber; x++) {
        for (let y = 0; y < yCubeNumber; y++) {
            for (let z = 0; z < zCubeNumber; z++) {
                var cube = cubes[x][y][z];

                for (let t = 0; t < cube.length; t++) {
                    var triangle = cube[t];
                    if(vertexIsEdge[triangle.a.getIndex()] && vertexIsEdge[triangle.b.getIndex()]){
                        positions.push(
                            (triangle.a.x + triangle.b.x)/2.0 + 0.1 * triangle.normal.x,
                            (triangle.a.y + triangle.b.y)/2.0 + 0.1 * triangle.normal.y,
                            (triangle.a.z + triangle.b.z)/2.0 + 0.1 * triangle.normal.z);

                        color.setRGB(1.0, 0.0, 0.0);
                        colors.push(color.r, color.g, color.b);
                        TEMP++
                    }

                    if(vertexIsEdge[triangle.b.getIndex()] && vertexIsEdge[triangle.c.getIndex()]){
                        positions.push(
                            (triangle.b.x + triangle.c.x)/2.0 + 0.1 * triangle.normal.x,
                            (triangle.b.y + triangle.c.y)/2.0 + 0.1 * triangle.normal.y,
                            (triangle.b.z + triangle.c.z)/2.0 + 0.1 * triangle.normal.z);

                        color.setRGB(1.0, 0.0, 0.0);
                        colors.push(color.r, color.g, color.b);
                        TEMP++
                    }

                    if(vertexIsEdge[triangle.c.getIndex()] && vertexIsEdge[triangle.a.getIndex()]){
                        positions.push(
                            (triangle.a.x + triangle.c.x)/2.0 + 0.1 * triangle.normal.x,
                            (triangle.a.y + triangle.c.y)/2.0 + 0.1 * triangle.normal.y,
                            (triangle.a.z + triangle.c.z)/2.0 + 0.1 * triangle.normal.z);

                        color.setRGB(1.0, 0.0, 0.0);
                        colors.push(color.r, color.g, color.b);
                        TEMP++
                    }

                    if (triangle.cameraCount > 0) {
                        if (!showNormalSegments && false) {
                            positions.push(
                                triangle.center.x + 0.1 * triangle.normal.x,
                                triangle.center.y + 0.1 * triangle.normal.y,
                                triangle.center.z + 0.1 * triangle.normal.z);

                            color.setRGB(0.0, triangle.cameraCount / 10.0, 0.0);
                            colors.push(color.r, color.g, color.b);
                        }
                    }
                }


            }
        }
    }
    console.log("Num EdgesVis "+TEMP)
// -----------------------------------------------------------------------------------------------------------------
    var result = {
        sceneInstanceProperties: {
            positions: positions,
            colors: colors,
        },
        rawPoints: raw_positions,
        viewpoints: sortedViewpoints,
        rotationMatrices: sortedRotationMatrices
    };

    return result;
}
