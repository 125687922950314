import * as Three from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import Stats from 'three/examples/jsm/libs/stats.module'

export function initializeThree(container){
    var context = {};

    //UI container
    context.container = container;

    //camera
    context.camera = new Three.PerspectiveCamera(
        75,
        container.clientWidth / container.clientHeight,
        0.0001,
        10
    );
    context.camera.position.set(0, 0.2, 0.5);
    context.camera.up = new Three.Vector3(0,1,0);
    context.camera.lookAt(new Three.Vector3(0,0,0));


    // aspect ratio and fov for drone camera according to camera specification from the paper
    // three.js uses the vertical fov, this can be computed using the horizontal fov which is 36.2
    // to verify the horizontal fov blender can be used
    var defaultCameraAspect = 7360.0 / 4912.0;
    context.droneCamera = new Three.PerspectiveCamera(
        25,
        defaultCameraAspect,
        0.001,
        15
    );
    context.droneCamera.position.set(0, 0.2, 0.5);
    context.droneCamera.lookAt(new Three.Vector3(0,0,0));
    context.droneScene = new Three.Scene();

    //scene
    context.scene = new Three.Scene();
    context.scene.background = new Three.Color(0xeeeeee);

    //renderer
    context.renderer = new Three.WebGLRenderer({ alpha: true, antialias: true, logarithmicDepthBuffer: true});
    context.renderer.setSize(container.clientWidth, container.clientHeight);
    container.appendChild(context.renderer.domElement);

    //controls
    // TODO: maybe this can be replaced by FirstPersonControls?
    context.camera_controls = new OrbitControls(context.camera, context.renderer.domElement);
    context.camera_controls.rotateSpeed = 0.1; //mouse sensitivity
    context.camera_controls.update();

    context.alignCameraToFitBox = (bbox)=>{
        const dimensions = {x:bbox.max.x-bbox.min.x, y:bbox.max.y-bbox.min.y, z:bbox.max.z-bbox.min.z}
        const center = new Three.Vector3(dimensions.x/2,dimensions.y/2,dimensions.z/2);

        //position in z direction to include whole bounding box in fov
        const fov = context.camera.fov*(Math.PI / 180);
        const fovh = 2 * Math.atan(Math.tan(fov/2)*context.camera.aspect);
        let dx = dimensions.z / 2 + Math.abs(dimensions.x / 2 / Math.tan(fovh / 2));
        let dy = dimensions.z / 2 + Math.abs(dimensions.y / 2 / Math.tan(fov / 2));
        let cameraZ = Math.max(dx, dy);

        //set camera to fit bounding box
        context.camera.position.set(0, center.y+0.2, center.z+cameraZ*1.0);

        context.camera.lookAt(0,center.y,0);
        context.camera_controls.target = new Three.Vector3(0,center.y,0);
        context.camera_controls.update();
    }

    //light
    const light = new Three.AmbientLight( 0x666666 );
    const directionalLight = new Three.DirectionalLight( 0xffffff, 0.5);
    const hemiLight = new Three.HemisphereLight(0xffffbb, 0x080820, 0.3);
    context.lights = [light, directionalLight, hemiLight];

    directionalLight.castShadow = true;
    directionalLight.position.x = 1;
    directionalLight.position.y = 1;

    context.scene.add(light);
    context.scene.add(directionalLight);
    context.scene.add(hemiLight);

    //FPS stats
    context.stats = new Stats();
    context.stats.setMode(0);
    context.stats.domElement.style.position = 'absolute';
    context.stats.domElement.style.top = '30px';
    context.stats.domElement.style.left = '20px';
    container.appendChild(context.stats.dom);

    //static objects
    //floor grid
    const grid = new Three.GridHelper(1, 40, 0x000000, 0x000000);
    grid.position.z = 0;
    grid.material.opacity = 0.05;
    grid.material.depthWrite = false;
    grid.material.transparent = true;
    context.scene.add(grid);

    const axesHelper = new Three.AxesHelper( 0.05 );
    context.scene.add( axesHelper );

    context.droneScene.add(context.droneCamera)
    context.droneScene.add(grid.clone(true));
    context.droneScene.add(light.clone(true));
    context.droneScene.add(directionalLight.clone(true));
    context.droneScene.add(hemiLight.clone(true));

    //creates frustum geom
    const frustumGeometry = new Three.BufferGeometry();
    const vertices = [
        -defaultCameraAspect*0.02 / 2, -0.02 / 2, -0.03,
        -defaultCameraAspect*0.02 / 2, 0.02 / 2, -0.03,
        defaultCameraAspect*0.02 / 2, 0.02 / 2, -0.03,
        defaultCameraAspect*0.02 / 2, -0.02 / 2, -0.03,
        0, 0, 0
    ]

    const indices = [
        1, 0, 4, 2, 1, 4, 3, 2, 4, 0, 3, 4, 2, 3, 4, 1, 2, 4, 3, 0, 4, 0, 1, 4
    ]

    const normals = []
    normals.fill(new Three.Vector3(1.0,0,0));

    frustumGeometry.setIndex(indices);
    frustumGeometry.setAttribute('position', new Three.Float32BufferAttribute( vertices, 3 ));

    const frustumMaterial = new Three.MeshBasicMaterial( { color: 0xff69b4, wireframe: true } );
    context.droneFrustum = new Three.Mesh(frustumGeometry, frustumMaterial);
    context.droneFrustum.visible = false;
    context.scene.add(context.droneFrustum);

    // create walls for droneScene
    const planeGeo = new Three.PlaneGeometry( 10, 10);

    var visibilityMaterial = new Three.MeshBasicMaterial( { color: 0xff0000})
    visibilityMaterial.transparent = true;
    visibilityMaterial.opacity = 0.3;

    const visibilityPlane = new Three.Mesh( planeGeo, visibilityMaterial);
    visibilityPlane.position.set(0,0, -0.1);
    context.droneCamera.add( visibilityPlane );

    const planeTop = new Three.Mesh( planeGeo, new Three.MeshBasicMaterial( { color: 0xffffff } ) );
    planeTop.position.y = 5;
    planeTop.rotateX( Math.PI / 2 );
    context.droneScene.add( planeTop );

    const planeBottom = new Three.Mesh( planeGeo, new Three.MeshBasicMaterial( { color: 0xffffff } ) );
    planeBottom.position.y = -5;
    planeBottom.rotateX( - Math.PI / 2 );
    context.droneScene.add( planeBottom );

    const planeFront = new Three.Mesh( planeGeo, new Three.MeshBasicMaterial( { color: 0xffffff } ) );
    planeFront.position.z = 5;
    planeFront.position.y = 5;
    planeFront.rotateY( Math.PI );
    planeFront.translateY(-5);
    context.droneScene.add( planeFront );

    const planeBack = new Three.Mesh( planeGeo, new Three.MeshBasicMaterial( { color: 0xffffff } ) );
    planeBack.position.z = -5;
    planeBack.position.y = 5;
    planeBack.translateY(-5);
    context.droneScene.add( planeBack );

    const planeRight = new Three.Mesh( planeGeo, new Three.MeshBasicMaterial( { color: 0xffffff } ) );
    planeRight.position.x = 5;
    planeRight.position.y = 5;
    planeRight.rotateY( - Math.PI / 2 );
    planeRight.translateY(-5);
    context.droneScene.add( planeRight );

    const planeLeft = new Three.Mesh( planeGeo, new Three.MeshBasicMaterial( { color: 0xffffff } ) );
    planeLeft.position.x = - 5;
    planeLeft.position.y = 5;
    planeLeft.rotateY( Math.PI / 2 );
    planeLeft.translateY(-5);
    context.droneScene.add( planeLeft );

    context.renderer.autoClear = false;
    var clock = new Three.Clock();
    var delta = 0;
    // seconds until the new viewpoint is reached
    var animationSpeed = 1;
    var currentAnimationTime = 0;
    // seconds to stop the animation when an viewpoint is reached
    var tWaitImage = 1;
    // time waited so far;
    var tWait = 0;
    //register rendering loop
    const rendering = function(){

        // clear renderer to draw bridge and flightpath
        context.renderer.clear();
        // set viewport to full window width and height
        context.renderer.setViewport( 0, 0, container.clientWidth, container.clientHeight );
        // render bridge and user camera
        context.renderer.render(context.scene, context.camera);

        // clear depth to draw drone animation view
        context.renderer.clearDepth();
        // set viewport to have a smaller width and height
        // here the width and height are chosen according to the defaultCameraAspect
        var width = container.clientWidth/4
        var height = width / defaultCameraAspect
        if(width <= container.clientWidth && height <= container.clientHeight){
            context.renderer.setViewport( container.clientWidth - width, container.clientHeight - height, width, height);
        } else {
            context.renderer.setViewport( 0, 0, container.clientWidth/3, container.clientHeight/3);
        }

        // if the animation button was pressed compute the current position of the drone camera
        if(context.droneCamera.animateCamera === true){
            context.droneFrustum.visible = true;
            var maxAllowedDistance = 2.4875 * context.droneCamera.normalization.scale;
            visibilityPlane.position.set(0,0, -maxAllowedDistance);
            // index of the current camera
            var curCam = context.droneCamera.currentCamera;
            var curPos = context.droneCamera.cameraPositions[curCam];
            var curRot = context.droneCamera.cameraRotations[curCam];
            var prePos = curCam > 0 ? context.droneCamera.cameraPositions[curCam - 1] : undefined;
            var preRot = curCam > 0 ? context.droneCamera.cameraRotations[curCam - 1] : undefined;

            // time since last frame
            delta = clock.getDelta();
            currentAnimationTime += delta;
            // current interpolation value

            if(currentAnimationTime > animationSpeed){
                if(tWait > tWaitImage){
                    tWait = 0;
                    currentAnimationTime = 0;
                    context.droneCamera.currentCamera += 1;
                    context.droneCamera.currentCamera = context.droneCamera.currentCamera % context.droneCamera.cameraPositions.length;
                    context.droneCamera.position.set(curPos.x, curPos.y, curPos.z);
                    context.droneCamera.setRotationFromQuaternion(curRot.normalize());
                    context.droneFrustum.setRotationFromQuaternion(curRot.normalize());
                } else {
                    tWait += delta;
                }

            } else {
                var interpVal = currentAnimationTime / animationSpeed;

                if (preRot !== undefined && prePos !== undefined) {
                    var pos = [interpVal * curPos.x + (1 - interpVal) * prePos.x,
                               interpVal * curPos.y + (1 - interpVal) * prePos.y,
                               interpVal * curPos.z + (1 - interpVal) * prePos.z];
                    context.droneCamera.position.set(pos[0], pos[1], pos[2]);
                    context.droneFrustum.position.set(pos[0], pos[1], pos[2]);

                    var interpolQuat = new Three.Quaternion();
                    interpolQuat.slerpQuaternions(preRot, curRot, interpVal);
                    context.droneCamera.setRotationFromQuaternion(interpolQuat.normalize());
                    context.droneFrustum.setRotationFromQuaternion(interpolQuat.normalize());

                } else {
                    context.droneCamera.position.set(curPos.x, curPos.y, curPos.z);
                    context.droneFrustum.position.set(curPos.x, curPos.y, curPos.z);
                    context.droneCamera.setRotationFromQuaternion(curRot.normalize());
                    context.droneFrustum.setRotationFromQuaternion(curRot.normalize());
                }
            }

            // render drone scene and current drone camera
            context.renderer.render(context.droneScene, context.droneCamera);
        } else {
            context.droneFrustum.visible = false;
        }

        // update fps stats
        context.stats.update();
        requestAnimationFrame(rendering);
    };
    context.renderLoop = requestAnimationFrame(rendering);
    return context;
}