import * as Three from "three";

function animateFlightpath(instance){
    var cameras = undefined
    var normalization = undefined
    var flightpath = undefined
    var mesh = undefined
    var sampledPoints = undefined
    var flightpathName = "flightpath"
    var meshName = "mesh"
    var pointName = "points"

    if(instance.droneCamera.animateCamera === true){
        instance.droneCamera.currentCamera = 0
        instance.droneCamera.animateCamera = false
        var selectedObject = instance.droneScene.getObjectByName(flightpathName);
        instance.droneScene.remove(selectedObject);
        selectedObject = instance.droneScene.getObjectByName(meshName);
        instance.droneScene.remove(selectedObject);
        selectedObject = instance.droneScene.getObjectByName(pointName);
        instance.droneScene.remove(selectedObject);
        return
    }

    for(var index in instance.scene.children){
        var child = instance.scene.children[index]
        // assumes that the flightpath is stored as type line
        if(child.type == "Line" && child.visible === true){
            cameras = child.cameras;
            normalization = child.normalization;
            flightpath = child.clone(true);
            flightpath.name = flightpathName
            instance.droneScene.add(flightpath);
        }
        // assumes that the mesh is of type group
        if(child.type == "Mesh" && child.visible === true){
            mesh = child.clone(true);
            mesh.name = meshName;
            mesh.material = new Three.MeshPhongMaterial({ color: 0xBBBBBB, flatShading: true });
            mesh.castShadow = true;
            mesh.receiveShadow = true;
            instance.droneScene.add(mesh);
        }
        // assumes that the sampled points are of type point
        if(child.type == "Points" && child.visible === true){
            sampledPoints = child.clone(true);
            sampledPoints.name = pointName;
            instance.droneScene.add(sampledPoints);
        }
    }

    var camera_length = cameras.length;
    var positions = [];
    var rotations = [];

    for(var i = 0; i < camera_length; i += 15){
        var camera_rotation = [
            cameras[i+3], cameras[i+4], cameras[i+5],
            cameras[i+6], cameras[i+7], cameras[i+8],
            cameras[i+9], cameras[i+10], cameras[i+11]
        ]

        var transposed_camera_rotation = [
            cameras[i+3], cameras[i+6], cameras[i+9],
            cameras[i+4], cameras[i+7], cameras[i+10],
            cameras[i+5], cameras[i+8], cameras[i+11]
        ]

        var m_tr_camera_rotation = [
            -cameras[i+3], -cameras[i+6], -cameras[i+9],
            -cameras[i+4], -cameras[i+7], -cameras[i+10],
            -cameras[i+5], -cameras[i+8], -cameras[i+11]
        ]

        var camera_translation = [cameras[i+12], cameras[i+13], cameras[i+14]]

        //This is -R' * t
        var camera_world_position = [
            (-cameras[i+3]*cameras[i+12]) + (-cameras[i+6]*cameras[i+13]) + (-cameras[i+9] *cameras[i+14]),
            (-cameras[i+4]*cameras[i+12]) + (-cameras[i+7]*cameras[i+13]) + (-cameras[i+10]*cameras[i+14]),
            (-cameras[i+5]*cameras[i+12]) + (-cameras[i+8]*cameras[i+13]) + (-cameras[i+11]*cameras[i+14])
        ]

        // this is R' * [0 0 -1]'
        var camera_viewing_direction = [
            (cameras[i+3]* 0) + (cameras[i+6]*0) + (cameras[i+9] * (-1)),
            (cameras[i+4]* 0) + (cameras[i+7]*0) + (cameras[i+10]* (-1)),
            (cameras[i+5]* 0) + (cameras[i+8]*0) + (cameras[i+11]* (-1))
        ]

        var camera_up_direction = [
            (cameras[i+3]* 0) + (cameras[i+6]*1) + (cameras[i+9] * 0),
            (cameras[i+4]* 0) + (cameras[i+7]*1) + (cameras[i+10]* 0),
            (cameras[i+5]* 0) + (cameras[i+8]*1) + (cameras[i+11]* 0)
        ]

        var test = transposed_camera_rotation; //camera_rotation;
        const m = new Three.Matrix4();
        m.set(  test[0], test[1], test[2], 0,
                test[3], test[4], test[5], 0,
                test[6], test[7], test[8], 0,
                 0,   0,  0, 1);

        var position = new Three.Vector3(camera_world_position[0], camera_world_position[1], camera_world_position[2]);

        var euler = new Three.Euler().setFromRotationMatrix(m);
        var oppositeEuler = new Three.Euler(-euler.x+normalization.rotation.x, -euler.y, -euler.z + 180 * 3.14159 / 180);
        oppositeEuler = new Three.Euler(euler.x+normalization.rotation.x, euler.y + 3.14159, euler.z);
        //oppositeEuler = euler;
        var rotation = new Three.Quaternion().setFromEuler(oppositeEuler);
        var vecFrom = new Three.Vector3(0,0,1);
        var vecTo = new Three.Vector3(camera_viewing_direction[0], camera_viewing_direction[1], camera_viewing_direction[2]).normalize();
        var vecUp = new Three.Vector2(camera_up_direction[0], camera_up_direction[1], camera_up_direction[2]);
        //rotation.setFromUnitVectors(vecFrom, vecTo);

        position.multiplyScalar(normalization.scale);
        position.add(normalization.translation);
        position.applyAxisAngle(new Three.Vector3(1,0,0), normalization.rotation.x);

        positions.push(position);
        rotations.push(rotation);
    }

    instance.droneCamera.cameraPositions = positions
    instance.droneCamera.cameraRotations = rotations
    instance.droneCamera.animateCamera = true
    instance.droneCamera.currentCamera = 0
    instance.droneCamera.normalization = normalization
}

export{animateFlightpath}