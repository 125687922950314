import * as Three from "three";
import {Vector3} from "three";

function visualizeEvaluatedPoints(instance, evaluatedPoints, transform = {translation: new Vector3(0.0, 0.0, 0.0), rotation: new Vector3(0,0,0), scale: 1.0}){
    var pointLines = evaluatedPoints.split(/\r?\n/)
    var pointString = ""
    var pointValues = undefined
    // defined in pixel per meter
    var max_allowed_error = 0.001
    var geometry = new Three.BufferGeometry();
    var positions = [];
    var colors = [];
    var color = new Three.Color();

    for (var i = 0; i < pointLines.length; i++) {
        pointString = pointLines[i];
        pointValues = pointString.split(" ");

        if(pointValues.length == 5) {
            positions.push(parseFloat(pointValues[0]), parseFloat(pointValues[1]), parseFloat(pointValues[2]));
            var resolution = (pointValues[3] === 'true');
            var accuracy = undefined;
            var no_accuracy = 0.0;

            if (pointValues[4] != "Inf") {
                // x = 1 means highest accuracy, while x = 0 means lowest allowed accuracy
                var x = (1 - parseFloat(pointValues[4]) / max_allowed_error);
                var acc_color = 0;

                if(x < 0.2) {
                    acc_color = 0.0;
                } else if (x < 0.4){
                    acc_color = 0.25;
                } else if (x < 0.6){
                    acc_color = 0.5;
                } else if (x < 0.8) {
                    acc_color = 0.75;
                } else {
                    acc_color = 1.0;
                }

                accuracy = acc_color;
            } else {
                accuracy = 0.0;
                no_accuracy = 1.0;
            }
            if(resolution){
                color.setRGB(0.0, accuracy, no_accuracy);
            } else {
                color.setRGB(1.0, 0.0, 0.0);
            }

            colors.push(color.r, color.g, color.b);
        }
    }

    geometry.setAttribute('position', new Three.Float32BufferAttribute(positions, 3));
    geometry.setAttribute('color', new Three.Float32BufferAttribute(colors, 3));
    geometry.computeBoundingSphere();

    var material = new Three.PointsMaterial({size: 0.01, vertexColors: Three.VertexColors});
    material.polygonOffset = true;
    material.depthTest = true;
    material.polygonOffsetFactor = 1;
    material.polygonOffsetUnits = 0.1;

    var result = {
        sceneInstance: new Three.Points(geometry, material),
        rawPoints: positions
    };

    var scale = transform.scale;
    result.sceneInstance.scale.set(scale,scale,scale);
    result.sceneInstance.rotation.set(transform.rotation.x, transform.rotation.y, transform.rotation.z);
    result.sceneInstance.translateX(transform.translation.x);
    result.sceneInstance.translateY(transform.translation.y);
    result.sceneInstance.translateZ(transform.translation.z);
    return result;
}

export{visualizeEvaluatedPoints}