<template>
    <div id="bridge">
    <h3>Train bridge</h3>
    <div class="model-container">
        <div class="model-item">
                <img src="@/assets/images/bridge_thumbnail.png" alt="Image of the bridge pier">
            </div>
            <div class="model-item">
            <p>
                The bridge model consists of one pier of a train bridge. While geometrically simple, 
                the quality requirements are strict as close inspection is required. The pier is of rectangular
                cross section with slightly slanted sides. The model contains many geometrical details and
                accordingly consists of a relatively large number of triangles.
            </p>
            <ul>
                <li>Required resolution: $0.2mm/px$</li>
                <li>Admissible reconstruction error: $1mm$</li>
            </ul>
            <p> Download the model and an example flight path here: </p>
            <ul>
               <li><a :href="downloadModelItems.bridge.url" v-text="downloadModelItems.bridge.label" @click.prevent="download(downloadModelItems.bridge)" /></li>
               <li><a :href="downloadFlightpathItems.bridge.url" v-text="downloadFlightpathItems.bridge.label" @click.prevent="download(downloadFlightpathItems.bridge)" /></li>
            </ul>
            </div>
    </div>
    </div>
    <div id="hart">
    <h3>School building</h3>
    <div class="model-container">
        <div class="model-item">
                <img src="@/assets/images/hart_thumbnail.png" alt="Image of the Hart school">
            </div>
            <div class="model-item">
            <p>
                The school model does not require very high quality for the inspection, however the
                geometry of the object is more challenging, especially due to some corners, thight spaces
                and occlusion.
                While the scene contains the building and the surroundings, only the building itself is to
                be inspected. Accordingly, the evaluation of the flight paths is only computed on the building,
                while the surroundings need to be considered for occlusions.
            </p>
            <ul>
                <li>Required resolution: $1mm/px$</li>
                <li>Admissible reconstruction error: $10mm$</li>
            </ul>
            <p> Download the model and an example flight path here: </p>
            <ul>
               <li><a :href="downloadModelItems.hart.url" v-text="downloadModelItems.hart.label" @click.prevent="download(downloadModelItems.hart)" /></li>
               <li><a :href="downloadFlightpathItems.hart.url" v-text="downloadFlightpathItems.hart.label" @click.prevent="download(downloadFlightpathItems.hart)" /></li>
            </ul>
            </div>
    </div>
    </div>
    <div id="artificial">
    <h3>Artificial building</h3>
    <div class="model-container">
        <div class="model-item">
                <img src="@/assets/images/artificial_thumbnail.png" alt="Image of the artificial building">
            </div>
            <div class="model-item">
            <p>
                The artificial model was specifically created manually to challenge flight path planning
                algorithms with complicated geometrical features, such as slanted sides, round surfaces, and
                an overpass. The high resolution is designed to allow a close distance to be able to cross the overpass.
                Even though the underside of the building is contained in the model, it is not considered in the
                evaluation, which only concerns the parts above the ground.
            </p>
            <ul>
                <li>Required resolution: $0.25mm/px$</li>
                <li>Admissible reconstruction error: $2mm$</li>
            </ul>
            <p> Download the model and an example flight path here: </p>
            <ul>
               <li><a :href="downloadModelItems.artificial.url" v-text="downloadModelItems.artificial.label" @click.prevent="download(downloadModelItems.artificial)" /></li>
               <li><a :href="downloadFlightpathItems.artificial.url" v-text="downloadFlightpathItems.artificial.label" @click.prevent="download(downloadFlightpathItems.artificial)" /></li>
            </ul>
            </div>
    </div>
    </div>
</template>

<script>

import { downloadItem } from '@/assets/utils/file_download.js';

export default {
    name: "ParticipationModels",
    data() {
        return {
            // type has to be a valid MIME type: https://www.iana.org/assignments/media-types/media-types.xhtml
      downloadModelItems: {
        "bridge": { url: '/meshes/bridge.obj', label: 'bridge.obj', type: 'model/obj' },
        "artificial": { url: '/meshes/artificial_house.obj', label: 'Artificial-House.obj', type: 'model/obj' },
        "hart": { url: '/meshes/hart.obj', label: 'Hart-School.obj', type: 'model/obj' }},
      downloadFlightpathItems: {
        "bridge": { url: '/bundler_files/slicing_02mm_tour.out', label: 'bridge.out', type: 'plain/text' },
        "artificial": { url: '/bundler_files/route_artifical.out', label: 'Artifical-House.out', type: 'plain/text' },
        "hart": { url: '/bundler_files/route_hart.out', label: 'Hart-School.out', type: 'plain/text' }},
        }
    },

    methods:{
    download(item){
    downloadItem(item);
    }
  }
}
</script>

<style scoped>
img {
    width: 100%;
}
.model-container {
    display: grid;
    grid-template-columns: 20% auto;
}
.model-item {
    padding: 5px;
}
</style>
