<template>
  <div id="container">
    <div id="head">
      <three-canvas/> <br>
    </div>

    <nav-bar/>

    <div id="main">
      <h1 style="padding-top: 15px; padding-bottom: 15px">{{ title }}</h1><br>
      <div id="motivation">
        <span>
          {{ motivation }}
        </span>
      </div>

      <p style="margin-bottom:2cm;"></p>

      <div id="participation">
        <h1>Challenge Participation</h1>
        <br>
        <span>
          {{ participationContent }}
        </span>
      </div>

      <p style="margin-bottom:2cm;"></p>

      <div id="custom_model">
        <h1>Participation Models</h1>
        <br>
        <span>
          {{ downloadContent }}
        </span>

        <participation-models/>

        <!-- <h4>Model Downloads</h4>
        <ul id="model-downloads">
          <li v-for="(item, index) in downloadModleItems">
            <a :href="item.url" v-text="item.label" @click.prevent="download(item)" />
          </li>
        </ul>
        <h4>Example Flightpath Downloads</h4>
        <ul id="flightpath-download">
          <li v-for="item in downloadFlightpathItems">
            <a :href="item.url" v-text="item.label" @click.prevent="download(item)" />
          </li>
        </ul> -->
      </div>

      <p style="margin-bottom:2cm;"></p>

      <div id="scoreboard">
        <h1>Scoreboard</h1>
        <scoreboard/>
        <p style="margin-bottom:2cm;"></p>
      </div>

      <p style="margin-bottom:2cm;"></p>

      <div id="criteria">
        <h1>Criteria</h1>
        <br>
        <div class="alert alert-primary" role="alert">
          The corresponding Paper: <a href="https://www.int-arch-photogramm-remote-sens-spatial-inf-sci.net/XLIII-B1-2021/157/2021/" class="alert-link"> EVALUATION OF 3D UAS FLIGHT PATH PLANNING ALGORITHMS </a> published at
          Int. Arch. Photogramm. Remote Sens. Spatial Inf. Sci., XLIII-B1-2021, 157–164, 2021 under cc license.
          Author: <a href="#team" class="alert-link">Debus, P.</a> and Rodehorst, V
        </div>
        <span>
          {{ paperContent }}
        </span>
        <newsletter-signup/>
      </div>

      <p style="margin-bottom:2cm;"></p>

      <div id="team">
        <h1>Team</h1>
        <br>
        <span>
          {{ teamContent }}
        </span>
        <br>

        <div class="contact-column">
          <div class="contact-card">
            <img src="@/assets/images/debus.jpg" alt="Paul Debus" style="width:100%">
            <div class="contact-container">
              <h2 class="contact-team-name">Paul Debus</h2>
              <p class="contact-title">M.Sc. Research Assistant at the Chair <br>of Computer Vision in Engineering at Bauhaus-Universität Weimar</p>
              <div class="contact-interest-area">
                <p>Research areas of interests: UAS, Computer Vision, Computational Geometry and Photogrammetry.</p>
              </div>
              <p class="contact-small-text">paul.debus@uni-weimar.de</p>
              <p><button class="contact-button" onclick="location.href='mailto:paul.debus@uni-weimar.de';">Contact</button></p>
            </div>
          </div>
        </div>

        <div class="contact-row">
          <div class="contact-column">
            <div class="contact-card">
              <img src="@/assets/images/lammert.jpg" alt="Anton Lammert" style="width:100%">
              <div class="contact-container">
                <h2 class="contact-team-name">Anton Lammert</h2>
                <p class="contact-title">B.Sc. Student Assistant at the Chair <br>of Virtual Reality and Visualization Research at Bauhaus-Universität Weimar</p>
                <div class="contact-interest-area">
                <p>Areas of interest: Computer Graphics, Mathematics, Simulations, VR.</p>
                </div>
                <p class="contact-small-text">anton.benjamin.lammert@uni-weimar.de</p>
                <p><button class="contact-button" onclick="location.href='mailto:anton.benjamin.lammert@uni-weimar.de';">Contact</button></p>
              </div>
            </div>
          </div>

          <div class="contact-column">
            <div class="contact-card">
              <img src="@/assets/images/tornow.jpg" alt="Philipp Tornow" style="width:100%">
              <div class="contact-container">
                <h2 class="contact-team-name">Philipp Tornow</h2>
                <p class="contact-title">B.Sc. Student Trainee at INFRALYTICA</p>
                <div class="contact-interest-area">
                <p>Areas of interest: Computer Vision, VR, AR, IoT</p>
                </div>
                <p class="contact-small-text">philipp.tornow@uni-weimar.de</p>
                <p><button class="contact-button" onclick="location.href='mailto:philipp.tornow@uni-weimar.de';">Contact</button></p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <p style="margin-bottom:2cm;"></p>

      <div id="contact">
        <h1>Contact</h1>
        <br>
        <span>
          {{ contactContent }}
        </span>
      </div>

    </div>

    <div id="aside">
      <div class="card" style="box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);; padding-left: 5px; padding-right: 5px; margin-bottom: 30px">
        <div class="card-body">
          <interactive-scoreboard/>
        </div>
      </div>
      <div id="aside-stiky-container">
        <flightpath-evaluation/>
        <flightpath-generation/>
      </div>
    </div>

    </div>
</template>
<script>
import ThreeCanvas from './components/ThreeCanvas.vue'
import NavBar from './components/NavBar.vue';
import FlightpathEvaluation from './components/FlightpathEvaluation.vue';
import InteractiveScoreboard from './components/InteractiveScoreboard.vue';
import Scoreboard from './components/Scoreboard.vue'
import { downloadItem } from '@/assets/utils/file_download.js';
import ParticipationModels from './components/ParticipationModels.vue';
import { PageTitle_String, Motivation_String, ParticipationContent_String, PaperContent_String, DownloadContent_String, TeamContent_String, ContactContent_String} from '@/assets/text_strings.js';
import NewsletterSignUp from "./components/NewsletterSignUp";
import FlightpathGeneration from "./components/FlightpathGeneration.vue";

export default {
  name: 'App',

  data() {
    return{
      title: PageTitle_String,
      motivation: Motivation_String,

      participationContent: ParticipationContent_String,
      paperContent: PaperContent_String,

      downloadContent: DownloadContent_String,

      teamContent: TeamContent_String,

      contactContent: ContactContent_String,


    }
  },

  components: {
    'three-canvas' : ThreeCanvas,
    'flightpath-evaluation' : FlightpathEvaluation,
    'interactive-scoreboard' : InteractiveScoreboard,
    'scoreboard' : Scoreboard,
    'nav-bar' : NavBar,
    'flightpath-generation' : FlightpathGeneration,
    'newsletter-signup' : NewsletterSignUp,
    'participation-models': ParticipationModels
  }
}
</script>

<style>
#app {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

@media (max-width: 50em) {
  #container {
    display: grid;
    min-width: 0;
    grid-template-columns: 100%;
    grid-template-rows: 440px auto auto 1fr;
    grid-row-gap: 0px;
  }

  #main {
    padding-left: 20px;
  }

  h1 {
    text-align: center;
  }

  #aside {
    grid-row: 3;
  }
}

@media (min-width: 50em) {
  #container {
    display: grid;
    grid-template-columns: 55% 45%;
    grid-template-rows: 440px auto auto 1fr;
    grid-row-gap: 0px;
  }

  #main {
    padding: 40px;
  }

  #aside {
    border-left: 1px solid #ddd;
  }

  #aside-stiky-container{
    position: -webkit-sticky; /* Safari */
    position: sticky; top: 100px;
    z-index: 21;
  }
}

#head {
  grid-row: 1;
  grid-column: 1/-1;
}

#main {
  padding-top: 20px;
}

#aside {
  padding: 20px;
  padding-top: 40px
}

h1 {
  display: inline-block;
  text-align: left;
  padding-bottom: 10px;
}

.contact-row {
  width: 100.0%;
  flex-wrap: nowrap !important;
}

/* Three columns side by side */
.contact-column {
  float: left;
  width: 33.3%;
  margin-bottom: 16px;
  padding: 0 8px;
}

.contact-interest-area{
  height: 100px;
}

/* Display the columns below each other instead of side by side on small screens */
@media screen and (max-width: 650px) {
  .contact-column {
    width: 100%;
    display: block;
  }
}

.contact-small-text{
  font-size: small;
  text-align: center;
}

/* Add some shadows to create a card effect */

.contact-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

/* Some left and right padding inside the container */
.contact-container {
  padding: 0 16px;
  height: 20%;
}

/* Clear floats */
.contact-container::after, .contact-row::after {
  content: "";
  clear: both;
  display: table;
}

.contact-team-name{
  padding-top: 5px;
}

.contact-title {
  color: grey;
  height: 120px;
}

.contact-button {
  border: none;
  outline: 0;
  display: inline-block;
  padding: 8px;
  color: white;
  background-color: #000;
  text-align: center;
  cursor: pointer;
  width: 100%;
}

.contact-button:hover {
  background-color: #555;
}

</style>
