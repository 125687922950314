<template>
    <h3 style="margin-bottom: 0px"> Challenge Ranking
        <button class="btn btn-labeled btn-outline-dark btn-sm" style="margin-left: 5px" @click="updateScores()">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-counterclockwise" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"/>
                <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z"/>
            </svg>
        </button>
    </h3>
    <p class="text-muted">Select to inspect in Viewer</p>
    <table class="table table-sm table-hover">
        <thead>
            <tr>
            <th scope="col">Rank</th>
            <th scope="col">Name</th>
            <th scope="col">Publication</th>
            <th scope="col">Score</th>
            <th scope="col">Date</th>
            </tr>
        </thead>
        <tbody>
            <template v-for="(score,index) in this.scores">
                <tr :class="['clickable', {'table-active' : index===currentlySelectedScore.index}]" @click="openModelSelection(index)">
                    <th scope="row">{{index+1}}</th>
                    <td>{{score.author}}</td>
                    <td>{{score.paper}}</td>
                    <td>{{parseFloat(score.final_score).toFixed(6)}}</td>
                    <td>{{convertDate(score.publishing_date)}}</td>
                </tr>
            </template>
        </tbody>
    </table>

    <modal v-if="selectionOpen" id="vis-selection" title="Select Participation Model" large v-on:close ="closeModelSelection">
        <template v-slot:body>
            <div class="row" style="padding: 18px">
                <div class="col">
                    <div class="card">
                        <img :src="getImageUrl(models[0].thumbnail)" class="card-img-top" alt="Lel">
                        <div class="card-body">
                            <h5 class="card-title">{{models[0].modelName}}</h5>
                            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                            <button v-if="loadingVisualizationData===0" class="btn btn-primary" disabled><div class="spinner-border spinner-border-sm" role="status"/></button>
                            <button v-else class="btn btn-primary" @click="showInViewer(0)">Show in Viewer ></button>
                        </div>
                    </div>
                </div>

                <div class="col">
                    <div class="card">
                        <img :src="getImageUrl(models[1].thumbnail)" class="card-img-top" alt="Lel">
                        <div class="card-body">
                            <h5 class="card-title">{{models[1].modelName}}</h5>
                            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                            <button v-if="loadingVisualizationData===1" class="btn btn-primary" disabled><div class="spinner-border spinner-border-sm" role="status"/></button>
                            <button v-else class="btn btn-primary" @click="showInViewer(1)">Show in Viewer ></button>
                        </div>
                    </div>
                </div>

                <div class="col">
                    <div class="card">
                        <img :src="getImageUrl(models[2].thumbnail)" class="card-img-top" alt="Lel">
                        <div class="card-body">
                            <h5 class="card-title">{{models[2].modelName}}</h5>
                            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                            <button v-if="loadingVisualizationData===2" class="btn btn-primary" disabled><div class="spinner-border spinner-border-sm" role="status"/></button>
                            <button v-else class="btn btn-primary" @click="showInViewer(2)">Show in Viewer ></button>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </modal>
</template>

<script>
    import Modal from './Modal.vue'
    import { GETRequest } from '@/assets/utils/api_request.js';

    export default {
    name: "InteractiveScoreboard",

    data(){
        return {
            scores: [],
            models: ["","",""],
            selectionOpen: false,
            currentlySelectedScore: {},
            currentlyVisualizedItem: undefined,
            loadingVisualizationData: -1,
            maxScoreCount: 10,
        }
    },

    components: {
        'modal' : Modal,
    },

    methods: {
        getImageUrl(image) {
          return require('@/assets/'+ image)
        },

        updateScores(){
            this.$scoreboardController.updateScores();
        },

        showInViewer(index){
            if(this.currentlyVisualizedItem){
                this.removeScoreVisualization();
            }

            this.loadingVisualizationData = index;
            this.currentlyVisualizedItem = {index: -1, rendered: false, model: this.models[index].modelName};
            const params = {
                score_id: this.currentlySelectedScore.id.value
            }
            var that = this;
            GETRequest('/get-flightpath', params)
                .then(async function(response){
                    //this.$threeInstance.visualizeResult(fp, pointResponse.data);
                    that.currentlyVisualizedItem.filename = "ScoreVis_"+that.currentlyVisualizedItem.model+".out";
                    var data = new Blob([response.data[index]],{type: 'text/plain'});
                    that.currentlyVisualizedItem.file = new File([data],that.currentlyVisualizedItem.filename);
                    await that.$threeInstance.addEvaluationItem(that.currentlyVisualizedItem);
                    that.$threeInstance.showEvaluationItem(that.currentlyVisualizedItem);
                    that.loadingVisualizationData = -1;
                    that.closeModelSelection(true);
                    that.$emitter.emit('renderScoreVisualization');
                })
                .catch(error => {
                    console.error("Error trying requesting flightpath for score with id: "+that.currentlySelectedScore.id.value);
                    console.error(error.message);
                });
        },

        openModelSelection(index){
            this.selectionOpen = true;
            this.currentlySelectedScore = this.scores[index];
            this.currentlySelectedScore.index = index;
        },

        closeModelSelection(selected = false){
            this.selectionOpen = false;
            if(!selected){
                this.currentlySelectedScore.index = undefined;
                this.currentlySelectedScore = {};
            }
        },

        removeScoreVisualization(){
            this.$threeInstance.removeEvaluationItem(this.currentlyVisualizedItem);
            this.currentlyVisualizedItem = undefined;
        },

        convertDate(date){
            var date = new Date(date-62135683200000);
            date.setTime( date.getTime() + date.getTimezoneOffset()*60*1000 );
            return date.toLocaleDateString() + " " +date.toLocaleTimeString();
        }
    },

    mounted(){
        this.models = this.$threeInstance.getStaticModels();
        var that = this;
        this.$scoreboardController.subscribeForUpdates("update", scores =>{
            this.scores = scores.slice(0,this.maxScoreCount);
        })
        this.$emitter.on('renderEvaluationVisualization',function(){
            if(that.currentlyVisualizedItem){
                that.removeScoreVisualization();
            }
        })
    }
}
</script>

<style>

    #vis-selection {
        filter: drop-shadow(0 0 0.75rem rgb(64, 64, 64));
    }

</style>