<template>
    <div class="modal" style="display: block; overflow-y: auto; margin-top: 25px;">
        <div :class="['modal-dialog', 'modal-lg',{'modal-xl':large}]">
            <div class="modal-content p-top-2">

                <!-- Modal Header -->
                <div class="modal-header">
                    <h4 class="modal-title">{{title}}</h4>
                    <button type="button" class="close btn" @click="$emit('close')" data-dismiss="modal" aria-label="Close">X</button>
                </div>

                <!-- Modal body -->
                <div class="modal-body pt-1">
                    <slot name="body"></slot>
                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <slot name="footer"></slot>
                    <button type="button" @click="$emit('close')" class="btn btn-danger" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
    name: 'Modal',
    props: {
        title: String,
        large: Boolean,
    }
}
</script>

<style>
    .modal {
        display: block;
        overflow-y: auto;
    }

    #modal-active{
	    display:block;
    }

</style>
