<template>
    <modal id="participation-modal" title="Participate in Challenge" v-on:close = "$emit('update:showModal', false)">
        <template v-slot:body>
            <form id="participationFileForm" class="needs-validation" ref="participationFileForm" novalidate>
                <div v-if="isInitialized()">
                    <legend style="margin-bottom: 0px"> Upload your Flightpaths for the Benchmark models.</legend>
                    <div class="form-label" style="margin-bottom: 20px">
                        .out in
                        <a href="https://www.cs.cornell.edu/~snavely/bundler/bundler-v0.4-manual.html" target="_blank" rel="noopener noreferrer" class="card-link">Bundler Format</a>
                    </div>

                    <div :class="['form-group', {'was-validated' : flightpaths[0].missing && !flightpaths[0].invalidFile}]">
                        <label for="inputFlightpath0">Input Flightpath {{flightpaths[0].model}}</label>
                        <input id="inputFlightpath0" type="file" @change="inputFile($event.target.files,0)" :class="['form-control', 'form-control-file',{'is-invalid' : flightpaths[0].invalidFile}]" accept=".out" required>
                        <div class="invalid-feedback">
                            {{flightpaths[0].invalidText}}
                        </div>
                    </div>
                    <div :class="['form-group', {'was-validated' : flightpaths[1].missing && !flightpaths[1].invalidFile}]">
                        <label for="inputFlightpath1">Input Flightpath {{flightpaths[1].model}}</label>
                        <input id="inputFlightpath1" type="file" @change="inputFile($event.target.files,1)" :class="['form-control', 'form-control-file',{'is-invalid' : flightpaths[1].invalidFile}]" accept=".out" required>
                        <div class="invalid-feedback">
                            {{flightpaths[1].invalidText}}
                        </div>
                    </div>
                    <div :class="['form-group', {'was-validated' : flightpaths[2].missing && !flightpaths[2].invalidFile}]">
                        <label for="inputFlightpath2">Input Flightpath {{flightpaths[2].model}}</label>
                        <input id="inputFlightpath2" type="file" @change="inputFile($event.target.files,2)" :class="['form-control', 'form-control-file',{'is-invalid' : flightpaths[2].invalidFile}]" accept=".out" required>
                        <div class="invalid-feedback">
                            {{flightpaths[2].invalidText}}
                        </div>
                    </div>
                </div>

                <div v-if="isUploading() || isEvaluating() || isFailed() || isPreparingResults()">
                    <div v-if="isFailed()">
                        <legend style="margin-bottom: 0px; font-size: 20px;">Some errors occurred during the evaluation.</legend>
                        <small v-if="errorMessage!=''" class="text-muted">{{errorMessage}}</small>
                        <small v-else class="text-muted">Check the error messages.</small>
                        <div class="progress" id="participation-bar" ref="participationProgress">
                            <div class="progress-bar progress-bar-striped progress-bar bg-danger" role="progressbar" :aria-valuenow="progress.percentage" :style="{width: progress.percentage +'%'}" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>

                    <div v-else-if="isPreparingResults()">
                        <legend style="margin-bottom: 0px; font-size: 20px;">All flightpaths evaluated</legend>
                        <small class="text-muted">Getting the score.</small>
                        <div class="progress" id="participation-bar" ref="participationProgress">
                            <div class="progress-bar progress-bar-striped progress-bar-animated bg-success" role="progressbar" :aria-valuenow="progress.percentage" :style="{width: progress.percentage +'%'}" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>

                    <div v-else>
                        <legend style="margin-bottom: 0px; font-size: 20px;">Your files are getting evaluated.</legend>
                        <small class="text-muted">This might take up to 40 minutes. You can close the Modal. Please don't reload the page.</small>
                        <div class="progress" id="participation-bar" ref="participationProgress">
                            <div class="progress-bar progress-bar-striped progress-bar-animated bg-info" role="progressbar" :aria-valuenow="progress.percentage" :style="{width: progress.percentage +'%'}" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>

                    <template v-for="fp in this.flightpaths">
                        <div class="card mb-3" style="top: 12px">
                            <div class="row align-items-start">
                                <div class="col-md-4">
                                <img :src="getImageUrl(fp.model)" class="img-fluid rounded-start" style="height:100px !important; padding-left: 10px; padding-top: 10px; padding-bottom: 10px" alt="lel">
                                </div>
                                <div class="col-md-4">
                                    <div class="card-body">
                                        <h5 class="card-title">{{fp.model}}</h5>
                                        <p class="card-text"><small class="text-muted">{{fp.statusText}}</small></p>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <h3 v-if="isUploading(fp.status)" style="margin-top: 20px;"><span class="badge bg-info">Uploading</span></h3>
                                    <h3 v-else-if="isEvaluating(fp.status)" style="margin-top: 20px;"><span class="badge bg-info">Evaluating</span></h3>
                                    <h3 v-else-if="isSuccess(fp.status)" style="margin-top: 20px;"><span class="badge bg-success">Success</span></h3>
                                    <h3 v-else-if="isFailed(fp.status)" style="margin-top: 20px;"><span class="badge bg-danger">Failed</span></h3>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>

                <div v-if="isSuccess() || isAccepting() || isAccepted() || isRejected()">
                    <div class="row" id="result">
                        <div id="description" class="four col-md-6">
                            <legend style="margin-bottom: 0px; font-size: 20px;">Congratulations! <br> Your Evaluation was successful.<br></legend>
                            <small class="text-muted">If you want to participate in the bppc, fill in your information and accept your score.</small>
                        </div>
                        <div class="four col-md-3">
                            <div class="counter-box">
                                <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-speedometer2" viewBox="0 0 16 16">
                                    <path d="M8 4a.5.5 0 0 1 .5.5V6a.5.5 0 0 1-1 0V4.5A.5.5 0 0 1 8 4zM3.732 5.732a.5.5 0 0 1 .707 0l.915.914a.5.5 0 1 1-.708.708l-.914-.915a.5.5 0 0 1 0-.707zM2 10a.5.5 0 0 1 .5-.5h1.586a.5.5 0 0 1 0 1H2.5A.5.5 0 0 1 2 10zm9.5 0a.5.5 0 0 1 .5-.5h1.5a.5.5 0 0 1 0 1H12a.5.5 0 0 1-.5-.5zm.754-4.246a.389.389 0 0 0-.527-.02L7.547 9.31a.91.91 0 1 0 1.302 1.258l3.434-4.297a.389.389 0 0 0-.029-.518z"/>
                                    <path fill-rule="evenodd" d="M0 10a8 8 0 1 1 15.547 2.661c-.442 1.253-1.845 1.602-2.932 1.25C11.309 13.488 9.475 13 8 13c-1.474 0-3.31.488-4.615.911-1.087.352-2.49.003-2.932-1.25A7.988 7.988 0 0 1 0 10zm8-7a7 7 0 0 0-6.603 9.329c.203.575.923.876 1.68.63C4.397 12.533 6.358 12 8 12s3.604.532 4.923.96c.757.245 1.477-.056 1.68-.631A7 7 0 0 0 8 3z"/>
                                </svg>
                                <span class="counter">{{score}}</span>
                                <p>Score</p>
                            </div>
                        </div>
                        <div class="four col-md-3">
                            <div class="counter-box colored">
                                <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-trophy" viewBox="0 0 16 16">
                                    <path d="M2.5.5A.5.5 0 0 1 3 0h10a.5.5 0 0 1 .5.5c0 .538-.012 1.05-.034 1.536a3 3 0 1 1-1.133 5.89c-.79 1.865-1.878 2.777-2.833 3.011v2.173l1.425.356c.194.048.377.135.537.255L13.3 15.1a.5.5 0 0 1-.3.9H3a.5.5 0 0 1-.3-.9l1.838-1.379c.16-.12.343-.207.537-.255L6.5 13.11v-2.173c-.955-.234-2.043-1.146-2.833-3.012a3 3 0 1 1-1.132-5.89A33.076 33.076 0 0 1 2.5.5zm.099 2.54a2 2 0 0 0 .72 3.935c-.333-1.05-.588-2.346-.72-3.935zm10.083 3.935a2 2 0 0 0 .72-3.935c-.133 1.59-.388 2.885-.72 3.935zM3.504 1c.007.517.026 1.006.056 1.469.13 2.028.457 3.546.87 4.667C5.294 9.48 6.484 10 7 10a.5.5 0 0 1 .5.5v2.61a1 1 0 0 1-.757.97l-1.426.356a.5.5 0 0 0-.179.085L4.5 15h7l-.638-.479a.501.501 0 0 0-.18-.085l-1.425-.356a1 1 0 0 1-.757-.97V10.5A.5.5 0 0 1 9 10c.516 0 1.706-.52 2.57-2.864.413-1.12.74-2.64.87-4.667.03-.463.049-.952.056-1.469H3.504z"/>
                                </svg>
                                <span class="counter">#{{rank}}</span>
                                <p>Rank</p>
                            </div>
                        </div>
                    </div>

                    <form id="participationForm" class="needs-validation" novalidate>
                        <div class="row">
                            <div class="form-group col-md-2">
                                <label for="inputTitle">Title</label>
                                <input type="text" class="form-control" id="inputTitle" v-model="formData.title" placeholder="Title">
                            </div>
                            <div :class="['form-group','col-md-4', {'was-validated' : formData.missingPrename}]">
                                <label for="inputPrename">First Name</label>
                                <input type="text" class="form-control" id="inputPrename" v-model="formData.prename" placeholder="First Name" required>
                            </div>
                            <div :class="['form-group','col-md-6', {'was-validated' : formData.missingName}]">
                                <label for="inputName">Last Name</label>
                                <input type="text" class="form-control" id="inputName" v-model="formData.name" placeholder="Last Name" required>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="InputEmail" class="col-sm-2 col-form-label" >Email</label>
                            <div :class="['form-group','col-sm-10']">
                                <input type="email" :class="['form-control',{'is-invalid' : (!verifyEmail()&&formData.email) || formData.missingEmail}]" id="InputEmail" aria-describedby="email" v-model="formData.email" placeholder="Enter organizational email" required>
                                <div class="invalid-feedback">
                                    {{formData.emailWrongHint}}
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="InputPublication" class="col-sm-2 col-form-label" >Publication</label>
                            <div class="col-sm-10">
                                <input type="url" pattern="https://.*" :class="['form-control',{'is-invalid' : !verifyPublication()}]" id="InputPublication" v-model="formData.paper" aria-describedby="publication" placeholder="https://example.com [OPTIONAL]">
                                <div class="invalid-feedback">
                                    {{formData.paperWrongHint}}
                                </div>
                            </div>
                        </div>

                        <div :class="['form-check', {'was-validated' : formData.missingAccept}]">
                            <input class="form-check-input" type="checkbox" v-model="formData.acceptedPrivacy" id="privacyCheck" required>
                            <label class="form-check-label" for="privacyCheck">
                                I accept that my personal information and the accepted results will be displayed on the site
                            </label>
                        </div>
                    </form>
                </div>
            </form>
        </template>

        <template v-slot:footer>
            <button v-if="negate(isAccepted()) && negate(isInitialized())" type="button" class="btn btn-link" id="createPage-modal-button" @click="createPage">Create Info Page</button>
            <button v-if="isInitialized() || isEvaluating() || isUploading() || isPreparingResults()" type="button" class="btn btn-success" v-bind:disabled="isUploading() || isEvaluating() || isPreparingResults()" id="participation-modal-button" @click="participate()">Participate</button>
            <button v-else-if="isSuccess()" type="button" class="btn btn-success" id="participation-modal-button" @click="acceptScore">Accept</button>
            <button v-else-if="isAccepting()" type="button" class="btn btn-outline-success" disabled id="participation-modal-button">
                <div class="spinner-border spinner-border-sm" role="status"/>
            </button>
            <button v-else-if="isAccepted()" type="button" class="btn btn-outline-success" disabled id="participation-modal-button">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                </svg>
                Accepted
            </button>
            <button v-else-if="isRejected()" type="button" class="btn btn-outline-danger" @click="acceptScore" id="participation-modal-button">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-counterclockwise" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"/>
                    <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z"/>
                </svg>
                Rejected. Try Again.
            </button>

            <button v-if="isFailed() || isSuccess() || isAccepted() || isRejected()" type="button" class="btn btn btn-outline-danger" id="participation-modal-button" @click="reset">Reset</button>
        </template>
    </modal>
</template>

<script>
    import Modal from './Modal.vue'
    import { sendFormdata } from '@/assets/utils/api_request.js';
    import { GETRequest } from '@/assets/utils/api_request.js';

    const STATUS_INITIALIZED = 0, STATUS_SAMPLING = 1, STATUS_UPLOADING = 2, STATUS_EVALUATING = 3, STATUS_PREPARING_RESULT = 4, STATUS_SUCCESS = 5, STATUS_FAILED = 6, STATUS_ACCEPTING_SCORE = 7, STATUS_ACCEPTED_SCORE = 8, STATUS_REJECTED_SCORE = 9;

    export default {
    name: "ChallengeParticipation",

    props: {
        showModal: Boolean,
        participationProgress: Number,
        participationStatus: Number,
        models: Array,
    },
    emits:['update:showModal','update:participationProgress','update:participationStatus'],

    data(){
        return {
            currentStatus:  STATUS_INITIALIZED,
            errorMessage: "",
            requestId: undefined,
            flightpaths: [{model: this.models[0], statusText: "Initialized", index: 0},{model: this.models[1], statusText: "Initialized", index: 1},{model: this.models[2], statusText: "Initialized", index: 2}],
            progress: {percentage: 0, evaluationProgresses: [0,0,0], value: 0},
            score: undefined,
            rank: undefined,
            formData: {title: "",
                       prename: undefined, missingPrename: undefined,
                       name: undefined, missingName: undefined,
                       email: undefined, missingEmail: undefined, emailWrongHint: "",
                       paper: undefined, paperWrongHint: "",
                       acceptedPrivacy: false, missingAccept: undefined},

            //maby remove when concurrent evaluations are possible
            lastFlightpathIndex: 0
        }
    },

    components: {
        'modal' : Modal,
    },

    methods: {

        getImageUrl(image) {
          return require('@/assets/images/' + image + '.png')
        },

        //MAIN
        participate(){

            //validate input
            var valid = true;
            for(var i = 0; i < this.flightpaths.length; i++){
                valid = this.verifyFile(this.flightpaths[i]);
            }
            if(!valid) return;

            //assemble upload data
            this.setStatus(STATUS_UPLOADING);
            const formData = new FormData();

            for(var i = 0; i < this.flightpaths.length; i++){
                (function(j, flightpaths){
                    var fp = flightpaths[j];

                    //create evaluation json form
                    formData.append(fp.model, fp.file);
                    fp.status = STATUS_UPLOADING;
                    fp.statusText = "Uploading flightpath."
                })(i, this.flightpaths);
            }

            //upload flightpaths for Challenge evaluation
            var that = this;
            var participation_request = sendFormdata(formData, '/participate-challenge')
                .then(response => {
                    var request_id = response.data.Evaluation_Request_Id.value;
                    if(request_id === undefined){
                        request_id = response.data.Evaluation_Request_Id;
                    }

                    if(request_id > 0){
                        console.log("Request id for challenge: "+request_id);
                        that.requestId = request_id;
                        for(var i = 0; i<that.flightpaths.length; i++){
                            var fp = that.flightpaths[i];
                            fp.status = STATUS_EVALUATING;
                            fp.statusText = "Starts evaluating.";
                        }
                        that.increaseProgress(5);
                        that.setStatus(STATUS_EVALUATING);

                        that.waitForScore(that.requestId, that.flightpaths)
                            .then(response =>{
                              if(that.currentStatus != STATUS_FAILED) {
                                that.setStatus(STATUS_SUCCESS);
                                that.increaseProgress(5);
                              }
                            })
                            .catch(error => {
                                that.setStatus(STATUS_FAILED);
                                that.errorMessage = error.message;
                                console.error(error.message);
                                Promise.reject();
                            });

                    } else if(request_id == -1){
                        console.log("Challenge Participation: Request id -1: Server found no flightpath.");
                        that.setStatus(STATUS_FAILED);
                        that.errorMessage = "Server found no flightpath.";
                    } else if(request_id == -3){
                        console.log("Challenge Participation: One Flightpath is of the wrong file type.");
                        that.setStatus(STATUS_FAILED);
                        that.errorMessage = "One Flightpath is of the wrong file type.";
                    }  else if(request_id == -5){
                        console.log("Challenge Participation: Not all models for challenge evaluation present.");
                        that.setStatus(STATUS_FAILED);
                        that.errorMessage = "Not all models for challenge evaluation present.";
                    } else if(request_id == -999){
                        console.log("Challenge Participation: A submitted file is larger than the allowed 100 MB.");
                        that.setStatus(STATUS_FAILED);
                        that.errorMessage = "A submitted file is larger than the allowed 100 MB.";
                    } else{
                        console.error("Participation Request-id "+request_id+": Unknown error.");
                        that.errorMessage = "Request id < -4: Unknown error.";
                        that.setStatus(STATUS_FAILED);
                    }

                    if(that.currentStatus == STATUS_FAILED){
                        for(var i = 0; i<that.flightpaths.length; i++){
                            var fp = that.flightpaths[i];
                            fp.status = STATUS_FAILED;
                        }
                    }
                })
                .catch(response => {
                    console.warn("An error in the upload of challenge Evaluation occurred: "+response);
                    that.errorMessage = "Connection to server went wrong. " +response;
                    that.setStatus(STATUS_FAILED);

                    for(var i = 0; i<that.flightpaths.length; i++){
                        var fp = that.flightpaths[i];
                        fp.status = STATUS_FAILED;
                    }
                });
        },

        //PARTICIPATION LIFECYCLE
        //async status loop
        waitForScore(){
            var that = this;
            return new Promise(async function (resolve, reject) {

                //requests status of challenge until finished
                while(that.currentStatus === STATUS_EVALUATING || that.currentStatus === STATUS_PREPARING_RESULT){
                    console.log("Check status for challenge: "+that.requestId+".");
                    await that.requestStatus(that.requestId)
                        .catch(error => {
                            console.warn(error);
                            reject();
                        });

                    //delay when evaluation is not ready yet
                    if(that.currentStatus != STATUS_SUCCESS && that.currentStatus != STATUS_FAILED && that.currentStatus != STATUS_PREPARING_RESULT){
                        const waiter = ms => new Promise(res => setTimeout(res, ms));
                        await waiter(7000);
                    }

                    //request score when evaluation is ready
                    else {
                        if(that.currentStatus != STATUS_FAILED) {
                          await that.requestScore(that.requestId);
                        }
                        resolve();
                    }
                }
            });
        },

        //requests the status of the challenge
        requestStatus(requestId){
            const parameter = {
                evaluation_id: requestId
            }

            var that = this;
            return GETRequest('/get-evaluation-status', parameter)
                .then(response => {
                    var flightpath_index;
                    var status;

                    if(response.data.status[0] == 0){
                        return;
                    }

                    if(response.data.status[0] < 0){
                        flightpath_index = ((-1 * response.data.status[0]) % 10);
                        status = (response.data.status[0]+flightpath_index) / 10;;
                    }
                    else{
                        flightpath_index = (response.data.status[0] % 10);
                        status = (response.data.status[0]-flightpath_index) / 10;
                    }

                    //(maby temp) ensure that all flightpaths reached the last status due to 7s delay
                    if((flightpath_index-1) > that.lastFlightpathIndex && that.flightpaths[that.lastFlightpathIndex]!=STATUS_SUCCESS){
                        var lastFP = that.flightpaths[that.lastFlightpathIndex];
                        lastFP.status = STATUS_SUCCESS;
                        lastFP.statusText = "Evaluation done. Return score.";
                        that.progress.evaluationProgresses[that.lastFlightpathIndex] = 7;
                    }
                    that.lastFlightpathIndex = flightpath_index - 1;

                    if(flightpath_index>0){var fp = that.flightpaths[flightpath_index-1]};

                    switch(status){
                        case -8:
                            fp.statusText = "An internal error occurred please notify the devs.";
                            fp.status = STATUS_FAILED;
                            break;
                        case -7:
                            fp.statusText = "An internal error occurred please notify the devs.";
                            fp.status = STATUS_FAILED;
                            break;
                        case -6:
                            fp.statusText = "Point visibility could not be computed.";
                            fp.status = STATUS_FAILED;
                            break;
                        case -5:
                            fp.statusText = "Server could not load the content of the obj file.";
                            fp.status = STATUS_FAILED;
                            break;
                        case -4:
                            fp.statusText = "Server could not load the content of the bundler file.";
                            fp.status = STATUS_FAILED;
                            break;
                        case -3:
                            fp.statusText = "Server could not load flightpath or model.";
                            fp.status = STATUS_FAILED;
                            break;
                        case -2:
                            fp.statusText = "Received sampled points empty.";
                            fp.status = STATUS_FAILED;
                            break;
                        case -1:
                            fp.statusText = "No sampled points received.";
                            fp.status = STATUS_FAILED;
                            break;
                        case 0:
                            fp.statusText = "Loading mesh and bundlerfile.";
                            break;
                        case 1:
                            //mesh and bundler file loaded
                            fp.statusText = "Computing visibility and setting default parameter.";
                            that.progress.evaluationProgresses[fp.index] = 1
                            that.increaseProgress(0);
                            break;
                        case 2:
                            //visibility computed and parameter set
                            fp.statusText = "Computing surface area.";
                            that.progress.evaluationProgresses[fp.index] = 2
                            that.increaseProgress(0);
                            break;
                        case 3:
                            //scene measured - surface area computed
                            fp.statusText = "Computing max theoretical score.";
                            that.progress.evaluationProgresses[fp.index] = 3
                            that.increaseProgress(0);
                            break;
                        case 4:
                            //smax berechnet max theoretical possible score
                            fp.statusText = "Computing resolution.";
                            that.progress.evaluationProgresses[fp.index] = 4
                            that.increaseProgress(0);
                            break;
                        case 5:
                            //resolution computed
                            fp.statusText = "Computing Accuracy.";
                            that.progress.evaluationProgresses[fp.index] = 5
                            that.increaseProgress(0);
                            break;
                        case 6:
                            //accuracy computed
                            fp.statusText = "Saving evaluation results.";
                            that.progress.evaluationProgresses[fp.index] = 6
                            that.increaseProgress(0);
                            break;
                        case 7:
                            //score computed - saved acc and resolution
                            fp.statusText = "Evaluation done. Return score.";
                            that.progress.evaluationProgresses[fp.index] = 7
                            that.increaseProgress(0);
                            fp.status = STATUS_SUCCESS;
                            break;
                        case 10:
                            for(var i =0; i< this.progress.evaluationProgresses.length; i++){
                                that.progress.evaluationProgresses[i] = 7;
                                that.flightpaths[i].status = STATUS_SUCCESS;
                            }
                            that.increaseProgress(5);
                            that.setStatus(STATUS_PREPARING_RESULT);
                            break;
                        default:
                            fp.status = STATUS_FAILED;
                            break;
                    }
                    if(fp != undefined && fp.status==STATUS_FAILED){
                        that.setStatus(STATUS_FAILED);
                        console.error("The evaluation of one flightpath in challenge with id "+requestId+" failed.");
                        console.error(fp.statusText);
                    }
                })
                .catch(error => {
                    that.setStatus(STATUS_FAILED);
                    that.errorMessage = "Unable to request status for participation."
                    console.error("Unable to request status for participation.");
                    console.error(error.message);
                    Promise.reject(error);
                });
        },

        //receives the resulting score
        requestScore(requestId){
            const parameter = {
                evaluation_id: requestId
            }
            var that = this;
            return GETRequest('/get-temporary-score', parameter)
                .then(response => {
                    that.score = parseFloat(response.data.final_score).toFixed(3);
                    that.rank = that.$scoreboardController.rankScore(that.score);
                    that.setStatus(STATUS_SUCCESS);
                })
                .catch(error => {
                    that.setStatus(STATUS_FAILED);
                    that.errorMessage = "Unable to request score for Participation.";
                    console.error("Unable to request score for Participation.");
                    console.error(error.message);
                    Promise.reject(error);
                });
        },

        acceptScore(){

            var valid = true;

            //check if name is complete
            if(!this.formData.prename){
                this.formData.missingPrename = true;
                valid = false;
            }

            if(!this.formData.name){
                this.formData.missingName = true;
                valid = false;
            }

            //check for valid email
            if(!this.verifyEmail){
                valid = false;
            }

            if(!this.verifyPublication()){
                valid = false;
                this.formData.missingEmail = true;
            }

            //check for privacy checkbox
            if(!this.formData.acceptedPrivacy){
                this.formData.missingAccept = true;
                valid = false;
            }

            if(!valid)return;

            this.currentStatus = STATUS_ACCEPTING_SCORE;

            const formData = new FormData();
            formData.append("evaluation_id", this.requestId);
            formData.append("name", this.formData.title+" "+this.formData.prename+" "+this.formData.name);
            if(this.formData.paper){
                formData.append("paper", this.formData.paper);
            }
            formData.append("mail", this.formData.email);
            var accept_post = sendFormdata(formData, '/accept-temporary-score')
                .then(response => {
                    this.currentStatus = STATUS_ACCEPTED_SCORE;
                    this.$scoreboardController.updateScores();
                })
                .catch(error =>{
                    console.error(error.response);
                    this.currentStatus = STATUS_REJECTED_SCORE;
                });
        },

        //HELPER
        reset(){
            this.setStatus(STATUS_INITIALIZED);
            this.errorMessage = "";
            this.requestId = undefined;
            this.flightpaths = [{model: this.models[0], statusText: "Initialized", index: 0},{model: this.models[1], statusText: "Initialized", index: 1},{model: this.models[2], statusText: "Initialized", index: 2}];
            this.progress = {percentage: 0, evaluationProgresses: [0,0,0], value: 0};
            this.score = undefined;
            this.rank = undefined;
            this.lastFlightpathIndex = 0;

            this.formData = {title: "",
                       prename: undefined, missingPrename: undefined,
                       name: undefined, missingName: undefined,
                       email: undefined, emailWrongHint: "",
                       paper: undefined, paperWrongHint: "",
                       acceptedPrivacy: false, missingAccept: undefined},

            this.$emit('update:participationProgress', this.progress.percentage);

        },

        isInitialized(status = this.currentStatus){
            return status === STATUS_INITIALIZED;
        },

        isUploading(status = this.currentStatus) {
            return status === STATUS_UPLOADING;
        },

        isEvaluating(status = this.currentStatus) {
            return status === STATUS_EVALUATING;
        },

        isPreparingResults(status = this.currentStatus) {
            return status === STATUS_PREPARING_RESULT;
        },

        isSuccess(status = this.currentStatus) {
            return status === STATUS_SUCCESS;
        },

        isFailed(status = this.currentStatus) {
            return status === STATUS_FAILED;
        },

        isAccepting(status = this.currentStatus) {
            return status === STATUS_ACCEPTING_SCORE;
        },

        isAccepted(status = this.currentStatus){
            return status === STATUS_ACCEPTED_SCORE;
        },

        isRejected(status = this.currentStatus){
            return status === STATUS_REJECTED_SCORE;
        },

        negate(state){
            return !state;
        },

        inputFile(file, index){
            var fp =  this.flightpaths[index];
            fp.file = file[0];
            fp.status = STATUS_INITIALIZED;
        },

        createPage(){
             window.open("https://www.uni-weimar.de", "_blank");
        },

        setStatus(status){
            this.currentStatus = status;
            this.$emit('update:participationStatus', status);
        },

        increaseProgress(amount = 1){
            this.progress.value += amount;
            var sum = 0;
            for(var i = 0; i<this.progress.evaluationProgresses.length; i++){
                sum += this.progress.evaluationProgresses[i];
            }
            this.progress.percentage = Math.round(((sum + this.progress.value)*100)/36);
            this.$emit('update:participationProgress', this.progress.percentage);
        },

        verifyFile(flightpath){
            var file = flightpath.file;
                if(!file){
                   flightpath.missing = true;
                    return false;
                }
                else if((file.size/1024/1024)>100){
                    flightpath.invalidFile = true;
                    flightpath.invalidText = "File larger than 100 Mb."
                    return false;
                }
                else if(file.name.split('.').pop()!= 'out'){
                    flightpath.invalidFile = true;
                    flightpath.invalidText = "Wrong file type. '.out' file needed."
                    return false;
                }
            return true;
        },

        verifyEmail(){
            if(this.formData.email){
                var pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if(!pattern.test(String(this.formData.email).toLowerCase())){
                    this.formData.emailWrongHint = "Email has an invalid format.";
                    return false;
                }
                else{
                    return true;
                }
            }
            return false;
        },

        verifyPublication(){
            if(this.formData.paper){
                var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
                    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
                    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
                    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
                    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
                    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator

                if(!pattern.test(this.formData.paper)){
                    this.formData.paperWrongHint = "Publication must be a link.";
                    return false;
                }

                return true;
            }
            return true
        }
    },

    //triggered on open
    mounted(){
        //var persistend_data = fetchData();
    },
}


</script>

<style>

    #participation-modal {
        filter: drop-shadow(0 0 0.75rem rgb(64, 64, 64));
    }

    #participationFileForm > * {
        margin: 12px;
    }

    .form-group {
        padding-bottom: 20px;
    }

    #participation-bar {
        position: relative;
        margin-top: 20px;
        margin-bottom: 30px;
        height: 15px;
    }

    #result {
        display: flex;
        margin-top: 20px;
        margin-bottom: 25px;
        justify-content: flex-end;
        align-items: right;
    }

    /*Success score */
    .counter-box {
        display: block;
        background: #f6f6f6;
        padding: 40px 30px 40px;
        text-align: center
    }

    .counter-box p {
        margin: 5px 0 0;
        padding: 0;
        color: #909090;
        font-size: 18px;
        font-weight: 500
    }

    .counter-box svg {
        font-size: 60px;
        margin: 0 0 15px;
        color: rgb(95, 95, 95)
    }

    .counter {
        display: block;
        font-size: 32px;
        font-weight: 700;
        color: #666;
        line-height: 28px
    }

    .counter-box.colored {
        background: #E3F2FD
    }

    .counter-box.colored p,
    .counter-box.colored svg,
    .counter-box.colored .counter {
        color: #0D6EFD
    }

</style>