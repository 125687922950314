<template>
  <form id="flightpathGenerationTemplate" class="needs-validation" novalidate>
    <div id="list" class="list-group">
      <li class="list-group-item active">Generate a flightpath</li>
      <li class="list-group-item">
        <div :class="['form-group']">
            <div id="row1" class="row align-items-start">
                <label class="form-label">
                    Input model (.obj) to generate a Flightpath. Please make sure the model contains normals.
                </label>
                <input id="inputModel" ref="inputModel" :class="['form-control','col','align-self-start',{'is-invalid' : modelStatus===-1}]" v-on:change="this.file =$event.target.files[0]" type="file" accept=".obj" aria-label="Upload" required>
                <!-- <a v-if="modelStatus===2" href="#" class="btn btn-outline-secondary col col-sm-4 align-self-end modelSubmitBtn" type="button">Segment</a> -->
                <button v-if="modelStatus===-1" class="btn btn-outline-danger col col-sm-4 align-self-end modelSubmitBtn" role="button" type="button" @click.stop="reset">Retry</button>
                <button v-else-if="modelStatus<3" class="btn btn-outline-success col col-sm-4 align-self-end modelSubmitBtn" type="button" :disabled="modelStatus===1" @click.stop="loadModel">
                    <span v-if="modelStatus===1">
                        <span class="spinner-border spinner-border-sm text-success" style="margin-right: 5px" role="status"></span>
                        Loading...
                    </span>
                    <span v-else>Load</span>
                </button>
                <div class="invalid-feedback">
                    {{fileErrorMessage}}
                </div>
            </div>
            <div v-if="modelStatus>1" id="row2" class="row align-items-start">
                <button v-if="modelStatus===4" class="btn btn-outline-primary col col-sm-4 align-self-start" style="margin-left: 10px;" role="button" type="button" @click.stop="downloadBundler()">
                    Download
                </button>
                <button v-else class="btn btn-outline-primary col col-sm-4 align-self-start" :disabled="modelStatus===3" style="margin-left: 10px;" role="button" type="button" @click.stop="generateFlightpath()">
                    <div v-if="modelStatus===3">
                        <span class="spinner-border spinner-border-sm text-primary" style="margin-right: 5px" role="status"></span>
                        Generating...
                    </div>
                    <span v-else>Generate Flightpath</span>
                </button>
                <button class="btn btn-outline-danger col col-sm-4 align-self-start" style="margin-left: 10px;" role="button" type="button" @click.stop="reset()">Reset</button>
            </div>
        </div>
      </li>
    </div>
  </form>


</template>

<script>

const  FILE_INVALID = -1, INITIALIZED = 0, LOADING = 1, LOADED = 2, GENERATING = 3, DOWNLOADABLE = 4;

export default {
  name: 'FlightpathGeneration',

  data(){
    return {
        file: null,
        modelStatus: INITIALIZED,
        fileErrorMessage: "",
        modelInstance: null,
        generationProcess: {},
        computedFlightpath: {
            fileURL: ""
        }
    }
  },

  mounted() {
      this.$emitter.on("content-changed", (content) => {
        if(this.modelStatus===LOADED){
            this.reset();
        }
    });
  },

  unmounted(){
      this.reset();
  },

  methods: {

    reset(){
        this.$refs.inputModel.value = "";
        this.file = null;
        this.fileErrorMessage = "";
        this.$threeInstance.removeEvaluationItem(this.modelInstance);
        this.$threeInstance.showDefault();
        this.modelInstance = null;

        if(this.generationProcess && this.generationProcess.Worker){
            console.log("Cancel Generation");
            this.generationProcess.Worker.terminate();
        }
        this.modelStatus = INITIALIZED;
    },

    loadModel(){
        if(!this.file){
            return;
        }
        else if(this.file.name.split('.').pop()!="obj"){
            this.modelStatus = FILE_INVALID;
            this.fileErrorMessage = "Wrong file type. '.obj' needed.";
            return;
        }
        else{
            this.modelStatus = LOADING;
            var item = {
                file: null,
                filename: this.file.name,
                customFile: this.file,
                customModelLoaded: true,
                model: "Custom"
            };
            this.$threeInstance.addCustomModel(item)
            .then(()=>{
                this.modelInstance = item;
                item.customModelLoaded =true,
                this.$threeInstance.showCustomModelForGeneration(item);
                this.modelStatus = LOADED;
            })
            .catch((error)=>{
                console.log(error);
                //this.fileErrorMessage = error.message;
                this.modelStatus = FILE_INVALID;
            });
        }
    },

    generateFlightpath(){
        if(this.modelInstance){
            this.modelStatus = GENERATING;
            this.generationProcess = this.$threeInstance.computeFlightpathForModel(this.modelInstance);
            this.generationProcess.Promise.then((result)=>{
                    this.modelStatus = DOWNLOADABLE;
                    this.computedFlightpath = result;
                    this.modelInstance.flightpathInstance = result.sceneInstance;
                })
                .catch((error)=>{
                    console.log(error);
                });
        }
    },

    downloadBundler(){
        const link = document.createElement("a");
        var url = window.URL.createObjectURL(this.computedFlightpath.payloadBlob);
        console.log(url);
        link.href = url;
        link.download = "bundle.out";
        document.body.appendChild(link);
        link.dispatchEvent(
            new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window
            })
        );
        document.body.removeChild(link);
    }
  }
}
</script>

<style>

    #flightpathGenerationTemplate {
        padding-top: 20px;
    }

    #row1 {
        padding-top: 15px;
    }

    #row2 {
        padding-top: 15px;
    }

    #inputModel {
        margin-left: 10px;
    }

    .modelSubmitBtn {
        margin-left: 20px;
        margin-right: 10px;
    }

</style>