import * as axios from 'axios';

//const BASE_URL = 'http://localhost:8000';
const BASE_URL = 'https://path.medien.uni-weimar.de/api';

function sendFormdata(formData, endpoint) {
    const url = `${BASE_URL}`+endpoint;
    return axios.post(url, formData)
    .catch(error =>{

        if(error.response){
            console.log("Send formData failed. Invalid status code.")
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
        }else if(error.request){
            console.log("Request tried: ", error.request);
        }else {
            console.log("Error: ", error.message);
        }
        return Promise.reject(error.message);
    });
}

function GETRequest(endpoint, parameter){
    const url =`${BASE_URL}`+endpoint;
    const params = new URLSearchParams(parameter);
    return axios.get(url, {params})
    .catch(error =>{
        if(error.response){
            console.log("Get request failed. Invalid status code.")
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
        }else if(error.request){
            console.log(error.request);
        }else {
            console.log("Error", error.message);
        }
        console.log(error.config);
        return Promise.reject(error);
    });
}

export {sendFormdata, GETRequest}
