import * as Three from 'three';
import { Vector3 } from 'three';
import { MeshSurfaceSampler } from 'three/examples/jsm/math/MeshSurfaceSampler'

export function samplePoints(mesh_node, transform = {translation: new Vector3(0.0, 0.0, 0.0), rotation: new Vector3(0.0,0.0,0.0), scale: 1.0}){
    const sampler = new MeshSurfaceSampler(mesh_node).build();
    // mesh_node.geometry.computeBoundingBox();
    // const min_z = mesh_node.geometry.boundingBox.min[2] + 0.2;

    var geometry = new Three.BufferGeometry();

    var raw_positions = [];
    var positions = [];
    var colors = [];

    var color = new Three.Color();
    const position = new Three.Vector3();
    const normal = new Three.Vector3();

    // Sample randomly from the surface, creating an instance of the sample
    // geometry at each sample point.
    for ( let i = 0; i < 10000; i ++ ) {
        sampler.sample( position, normal );
        // while (position[2] < min_z) {
        //     sampler.sample( position, normal );
        // }
        normal.normalize();
        // move sampled point outside of the mesh to avoid z_fighting
        positions.push(position.x + 0.01 * normal.x, position.y + 0.01 * normal.y, position.z + 0.01 * normal.z);
        // push raw points for evaluation
        raw_positions.push(position.x, position.y, position.z);
        color.setRGB(1.0, 1.0, 0.0);
        colors.push(color.r, color.g, color.b);
    }

    geometry.setAttribute('position', new Three.Float32BufferAttribute(positions, 3));
    geometry.setAttribute('color', new Three.Float32BufferAttribute(colors, 3));
    geometry.computeBoundingSphere();

    var material = new Three.PointsMaterial({size: 0.005, vertexColors: Three.VertexColors});
    material.polygonOffset = true;
    material.depthTest = true;
    material.polygonOffsetFactor = 1;
    material.polygonOffsetUnits = 0.1;

    var result = {
        sceneInstance: new Three.Points(geometry, material),
        rawPoints: raw_positions
    };

    var scale = transform.scale;
    result.sceneInstance.scale.set(scale,scale,scale);
    result.sceneInstance.rotation.set(transform.rotation.x, transform.rotation.y, transform.rotation.z);
    result.sceneInstance.translateX(transform.translation.x);
    result.sceneInstance.translateY(transform.translation.y);
    result.sceneInstance.translateZ(transform.translation.z);

    return result;
}