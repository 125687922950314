<template>
    <div>
        <button @click.stop='toggleMenu' class='anchor'>{{this.title}}<!--{{this.in_label}}--></button>
        <div v-if='this.showMenu' class='menu' v-click-outside="toggleMenu">
            <div class='menu-item' v-for='item in this.items' @click.stop='itemClicked(item)'>{{item}}</div>
        </div>
    </div>
</template>

<script>
    export default {
    name: 'DropdownButton',

    props: {
        label: String,
        items: {
            type: Array,
            default: [],
        }
    },

    data() {
        return{
            showMenu: false,
            title: this.label,
        }
    },

    methods: {
        toggleMenu: function() {
            this.showMenu = !this.showMenu;
        },

        itemClicked: function(item){
            this.toggleMenu();
            this.title = item;
            this.$emit("clicked",item);
        }
    }
}

</script>

<style>

    .anchor {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid transparent;
        padding: .35rem 1.6rem;
        font-size: 0.9rem;
        width: 200px;
        border-radius: .25rem;
        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        color: #000000;
        background-color: #f5faf7;
        border-color: #000000;
    }

    .anchor::after {
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: .5em;
        vertical-align: .255em;
        content: "";
        border-top: .3em solid;
        border-right: .28em solid transparent;
        border-bottom: 0;
        border-left: .28em solid transparent;
    }

    .anchor:hover {
        color: #fff;
        background-color: #0D6EFD;
        border-color: #0d6dfd9c;
        cursor: pointer;
    }

    .menu {
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid rgba(0,0,0,.15);
        border-radius: .25rem;
        color: #212529;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        font-size: 1rem;
        list-style: none;
        margin: .125rem 0 0;
        padding: .5rem 0;
        position: absolute;
        text-align: left;
        z-index: 20;
    }

    .menu-item {
        color: #212529;
        padding: .25rem 1.5rem;
        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }

    .menu-item:hover {
        background-color: #F4F6F6;
        cursor: pointer;
    }

</style>
