<template>
  <form id="newsletterForm" class="needs-validation" style="padding-top: 30px" novalidate>
    <div id="list" class="list-group">
      <li class="list-group-item active">Newsletter Signup:</li>
      <li class="list-group-item">
        <div :class="['form-group','col-sm-6']" style="height: 40px; width: 100%">
          <input type="email" :class="['form-control',{'is-invalid' : (!verifyEmail() && formData.email) || formData.missingEmail}]" id="InputEmail" aria-describedby="email" v-model="formData.email" placeholder="Enter your mail for news about changes and updates" required>
          <div class="invalid-feedback">
            {{formData.emailWrongHint}}
          </div>
        </div>
        <button type="button" class="btn btn-outline-primary" style="width: 100%; height: 40px; margin-top: 10px" @click="addMail">Add Mail</button>
      </li>
      <li v-if="isAdded()" class="list-group-item">
        <h4>Your mail was successfully added to the newsletter.</h4>
      </li>

      <li v-if="errorOccurred()" class="list-group-item">
        <h4>Your mail was successfully added to the newsletter.</h4>
      </li>
    </div>
  </form>


</template>

<script>
import { sendFormdata } from '@/assets/utils/api_request.js';

export default {
  name: 'NewsletterSignUp',

  data(){
    return {
      formData: {
        email: undefined, missingEmail: undefined, emailWrongHint: "",
        },
      status: 0,
    }
  },

  methods: {

    verifyEmail() {
      if (this.formData.email) {
        var pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!pattern.test(String(this.formData.email).toLowerCase())) {
          this.formData.emailWrongHint = "Email has an invalid format.";
          return false;
        } else {
          return true;
        }
      }
      return false;
    },

    addMail() {
      if(this.verifyEmail()) {
        const formData = new FormData();
        formData.append("user_mail", this.formData.email);

        var promise = sendFormdata(formData, '/newsletter-mails').then(response => {
          this.status = response.data;
        })

        Promise.resolve(promise);
      }
    },

    isAdded(){
      if(this.status === 1){
        return true;
      } else {
        return false;
      }
    },

    errorOccurred(){
      if(this.status === -1){
        return true;
      } else {
        return false;
      }
    }
  }
}
</script>

<style>

</style>