import { GETRequest } from '@/assets/utils/api_request.js';

//singleton pattern
class ScoreboardController {

    constructor(){
        this.scores = [];
        this.subscribers = [];
    }

    //creates ScoreboardController if none exists
    static getInstance(){
        if(!ScoreboardController.instance){
            ScoreboardController.instance = new ScoreboardController();
            ScoreboardController.instance.updateScores();
        }
        else{
            console.log("Scoreboard Controller already created. Returned existing.");
        }
        return ScoreboardController.instance;
    }

    //loads the scoreboard data Async
    updateScores(){
        var that = this;
        return GETRequest('/get-scores')
            .then(response => {
                that.scores = response.data.persistent_scores;
                that.scores.sort(function(s1, s2){
                    return s2.final_score - s1.final_score;
                });
                this.publish("update",that.scores);
            })
            .catch(error => {
                console.warn("Error trying requesting scores.");
                console.warn(error.message);
            });
    }

    //notifys each subscriber when updates the scores
    subscribeForUpdates(event, callback){
        if(!this.subscribers[event]){
            this.subscribers[event] = [];
        }

        this.subscribers[event].push(callback);
    }

    //ranks score and returns rank
    rankScore(value){
        let virtualScores = this.scores.map(s => s.final_score);
        virtualScores = virtualScores.concat(value);
        virtualScores.sort(function(s1, s2){
            return s2.final_score - s1.final_score;
        });
        return virtualScores.indexOf(value)+1;
    }


    //set this to private when eslint + babel supports private functionality
    //pub / sub pattern
    publish(event, data){
        if(!this.subscribers[event]) return;

        this.subscribers[event].forEach(subCallback => {
            subCallback(data);
        });
    }
}
export {ScoreboardController}