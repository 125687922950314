import { computeFlightpath } from "../three_scripts/compute_flightpath";

onmessage = (data)=>{
    console.log("start parallel flightpath computation");
    console.log(data);
    var normalization = {rotation: null, scale: null, translation: null};
    normalization.rotation = JSON.parse(data.data.normalization.rotation);
    normalization.scale = JSON.parse(data.data.normalization.scale);
    normalization.translation = JSON.parse(data.data.normalization.translation);
    var result = computeFlightpath(data.data.vertexBuffer, data.data.normalBuffer, normalization);
    console.log("Stop parallel flightpath computation");
    console.log("Prepare bundle file.");

    var serialized_result = {
        rawPoints: result.rawPoints,
        viewpoints: result.viewpoints,
        rotationMatrices: result.rotationMatrices,
        sceneInstanceProperties: result.sceneInstanceProperties
    };

    //write data
    //var encoder = new TextEncoder();
    //const line = encoder.encode("Hello Text 2!");
    const numCameras = result.viewpoints.length;

    var lines = [];
    lines.push("# Bundle file v0.3");
    lines.push(numCameras.toString()+" "+"0");
    var matrix = [];
    var x, y, z = 0;
    for(let i = 0; i<numCameras; i++){
        lines.push("1.0 0.0 0.0");
        matrix = result.rotationMatrices[i];
        x = result.viewpoints[i].x.toFixed(5).toString();
        y = result.viewpoints[i].y.toFixed(5).toString();
        z = result.viewpoints[i].z.toFixed(5).toString();
        lines.push(matrix[0][0].toFixed(5).toString() +" "+matrix[0][1].toFixed(5).toString()+" "+matrix[0][2].toFixed(5).toString());
        lines.push(matrix[1][0].toFixed(5).toString() +" "+matrix[1][1].toFixed(5).toString()+" "+matrix[1][2].toFixed(5).toString());
        lines.push(matrix[2][0].toFixed(5).toString() +" "+matrix[2][1].toFixed(5).toString()+" "+matrix[2][2].toFixed(5).toString());
        lines.push(x+" "+y+" "+z);
    }

    //create .out bundler file
    var payloadBlob = new Blob([lines.join('\n')], {type: 'text/plain;charset=utf-8;', endings: "native"}); //line endings?
    serialized_result.payloadBlob = payloadBlob;

    console.log("Created bundle file.");

    postMessage(serialized_result);
    close();
}