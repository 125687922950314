import { createApp } from 'vue'
import App from './App.vue'
import "bootstrap/dist/css/bootstrap.min.css";
import { ThreeInterface } from './assets/three_scripts/three_interface';
import { ScoreboardController } from './assets/scoreboard_controller';
import mitt from 'mitt'

const emitter = mitt();

const clickOutside = {
    beforeMount: (el, binding) => {
      el.clickOutsideEvent = event => {
        // here I check that click was outside the el and his children
        if (!(el == event.target || el.contains(event.target))) {
          // and if it did, call method provided in attribute value
          binding.value();
        }
      };
      document.addEventListener("click", el.clickOutsideEvent);
    },
    unmounted: el => {
      document.removeEventListener("click", el.clickOutsideEvent);
    },
};

const app = createApp(App);
app.directive("click-outside", clickOutside);
app.config.globalProperties.$threeInstance = ThreeInterface.getInstance();
app.config.globalProperties.$scoreboardController = ScoreboardController.getInstance();
app.config.globalProperties.$emitter = emitter;
app.mount('#app');
