import * as Three from 'three';

const _worldNormal = new Three.Vector3(0,1,0);

class SegmentationTool {
    constructor(scene, domElement, controls, camera) {

        this.scene = scene;
        this.domElement = domElement;
        this.controls = controls;
        this.camera = camera;

        this.raycaster = new Three.Raycaster();

        this.mousePos = new Three.Vector2();
        this.dragging = false;

        this.heightLevel = new Three.Plane();
        this.movementOrigin = new Three.Vector3();
        this.heightLevelIntersect = new Three.Vector3();
        this.intersectionPoint = new Three.Vector3();

        //instantiate bounding cube
        this.segVolume = this.instantiateSegmentationVolume();
        this.draggedObject = null;

        //mouse interaction
        document.addEventListener("pointermove", event => {
            // calculate mouse position in normalized device coordinates
	        // (-1 to +1) for both components
            this.mousePos.x = (event.clientX / this.domElement.clientWidth) * 2 - 1;
	        this.mousePos.y = - (event.clientY / this.domElement.clientHeight) * 2 + 1;

            // update the picking ray with the camera and mouse position
            this.raycaster.setFromCamera( this.mousePos, this.camera );

            //move seg. volume on heightlevel (plane) by mouse movement on drag
            if (this.dragging) {
                this.raycaster.ray.intersectPlane(this.heightLevel, this.heightLevelIntersect);
                this.draggedObject.position.addVectors(this.heightLevelIntersect, this.movementOrigin);
            }
        });

        document.addEventListener("pointerdown", () => {

            //raycast for seg. volume on click
            var intersects = this.raycaster.intersectObjects([this.segVolume]);
            if (intersects.length > 0) {

                //disable camera-controls
                controls.enabled = false;

                //get movement origin on height level
                this.draggedObject = intersects[0].object;
                this.intersectionPoint.copy(intersects[0].point);
                this.heightLevel.setFromNormalAndCoplanarPoint(_worldNormal, this.intersectionPoint);
                this.movementOrigin.subVectors(intersects[0].object.position, intersects[0].point);
                this.dragging = true;
            }
        } );

        document.addEventListener("pointerup", () => {
            this.dragging = false;
            this.controls.enabled = true;
            this.draggedObject = null
        } );
    }

    instantiateSegmentationVolume(){
        const geometry = new Three.BoxGeometry(0.3, 0.3, 0.3);
        console.log(geometry);

        //box
        var material = new Three.MeshBasicMaterial({
            color: "#0D6EFD",
            transparent: true,
            opacity: 0.3,
        });

        var instance = new Three.Mesh(geometry, material);

        //box edges
        var boxEdges = new Three.EdgesGeometry(geometry);
        var edges = new Three.LineSegments(boxEdges, new Three.LineBasicMaterial({
            color: "black",
            linewidth: 3,
        }));

        var transY = 0.4;
        instance.translateY(transY);
        instance.add(edges);

        this.scene.add(instance);

        return instance;
    }

    segment(mesh){
        // get geometry points and face indices
        var points = mesh.geometry.getAttribute('position');
        var normals = mesh.geometry.getAttribute('normal');
        var indices = mesh.geometry.getIndex();
        console.log(mesh.geometry);
        console.log(indices);

        //generate clipping box for segmentation
        this.volumeCopy = this.segVolume.clone();
        var inverse = mesh.matrix.clone();
        inverse.invert();
        console.log(inverse);
        this.volumeCopy.applyMatrix4(inverse);

        var clippingBox = new Three.Box3().setFromObject(this.volumeCopy);

        var segmented_positions = []
        var segmented_nomals = []
        var segmented_indices = []

        //check for points if inlier or not
        let point = new Three.Vector3();
        for(let i = 0; i<points.count; i++){
            point.fromBufferAttribute(points, i);
            if(clippingBox.containsPoint(point)){
                let p = new Three.Vector3();
                p.copy(point);
                segmented_positions.push(p);
                //segmented_positions.push({index: i, position: p, triangles: triangle_indices});

                if(normals){
                    let normal = new Three.Vector3();
                    normal.fromBufferAttribute(normals,i);
                    segmented_nomals.push(normal);
                }
            }
        }

        console.log("Inlying point-count: ",segmented_positions.length);

        //set positions
        mesh.geometry.setFromPoints(segmented_positions);
        mesh.geometry.getAttribute('position').needsUpdate = true;

        //set indices
        //mesh.geometry.setIndex(segmented_faces);
        //mesh.geometry.getIndex().needsUpdate = true;

        //set normals
        if(normals){
            var normal_array = new Float32Array(segmented_nomals);
            var normalAttribute = new Three.BufferAttribute(normal_array, 3, true);
            mesh.geometry.setAttribute('normal', normalAttribute);
            normalAttribute.needsUpdate = true;
        }
    }

    //lifecycle
    /*update(){

    }*/

    //aborts segmentation, removes segmentation tool
    abort(){

    }

    //segments mesh by segmentation tool

    //clear cleanup
    clear(){

    }

    //tool dimension control
    setWidth(){

    }

    setLength(){

    }

    setDepth(){

    }

    //sets position in y direction
    setHeight(){

    }
}
export {SegmentationTool}