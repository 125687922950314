<template>
    <div id='container' ref="container">
        <button type="button" class="btn btn-info btn-rounded animation-toggle" id="test_b"  v-on:click = "toggle_animation()" ref="animation_toggle">{{animation_toggle_text}}</button>
        <div v-show="preloading || loading" id="preload-spinner">
            <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
            <span style="position: relative; bottom: 5px; left: 15px">Loading ...</span>
        </div>
      <div class="bottom-bar">
          <div class="bottom-bar-row">
            <img src="@/assets/images/bppc_black_transparent.png" height="100" alt="bppc" border=0>
            <h3>{{current_bridge}}</h3>
            <button type="button" class="btn btn-circle btn-lg" id="fullscreen-button" v-on:click = "toggle_fullscreen()" ref="fullscreen_toggle" >
                <svg v-if="in_fullscreen" xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-fullscreen-exit" viewBox="0 0 16 16">
                    <path d="M5.5 0a.5.5 0 0 1 .5.5v4A1.5 1.5 0 0 1 4.5 6h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5zm5 0a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 10 4.5v-4a.5.5 0 0 1 .5-.5zM0 10.5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 6 11.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zm10 1a1.5 1.5 0 0 1 1.5-1.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4z"/>
                </svg>
                <svg v-else xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-fullscreen" viewBox="0 0 16 16">
                    <path d="M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1h-4zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zM.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5z"/>
                </svg>
            </button>
          </div>
      </div>
      <div v-if="showLegend" class='color-legend'>
        <div class='legend-title'>Evaluation Result Color Legend</div>
        <div class='legend-scale'>
          <div class='accuracy-title'>Accuracy</div>
          <ul class='legend-labels'>
            <li><span style='background:#ff0000;'></span>No Resolution</li>
            <div class="horizontalgap" style="width:1%"></div>
            <li><span style='background:#0000ff;'></span>No Accuracy</li>
            <div class="horizontalgap" style="width:1%"></div>
            <li><span style='background:#000000;'></span>0-20%</li>
            <li><span style='background:#003f00;'></span>40%</li>
            <li><span style='background:#007f00;'></span>60%</li>
            <li><span style='background:#00bf00;'></span>80%</li>
            <li><span style='background:#00ff00;'></span>100%</li>
          </ul>
        </div>
      </div>
    </div>
</template>

<script>

export default {
    name: 'ThreeCanvas',

    data() {
        return {
            //don't define variables for three here
            animation_state: false,
            in_fullscreen: false,
            animation_toggle_text: "Start Flight Animation and Preview",
            current_bridge: "",
            preloading: true,
            loading: false,
            showLegend: false
        }
    },

    methods: {

        toggle_fullscreen: function(){

            if(this.in_fullscreen){
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.mozExitFullScreen) { /* Firefox */
                    document.mozRequestFullScreen();
                } else if (document.webkitExitFullscreen) { /* Chrome, Safari & Opera */
                    document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) { /* IE/Edge */
                    document.msExitFullscreen();
                }
                else{
                    console.error("Fullscreen only compatible with Firefox, IE/Edge, Chrome, Safari & Opera.");
                }
            }
            else {
                var elem = this.$refs.container;
                if (elem.requestFullscreen) {
                    elem.requestFullscreen();
                } else if (elem.mozRequestFullScreen) { /* Firefox */
                    elem.mozRequestFullScreen();
                    console.log(this.$threeInstance.preloadReady);
                } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
                    elem.webkitRequestFullscreen();
                } else if (elem.msRequestFullscreen) { /* IE/Edge */
                    elem.msRequestFullscreen();
                } else{
                    console.error("Fullscreen only compatible with Firefox, IE/Edge, Chrome, Safari & Opera.");
                    this.in_fullscreen = !this.in_fullscreen;
                }
            }
            this.$threeInstance.toggleFullscreen(this.in_fullscreen);
        },

        toggle_animation: function(){
          this.$threeInstance.animate();
          if(this.animation_toggle_text == "Start Flight Animation and Preview"){
            this.animation_toggle_text = "Stop Animation";
          } else {
            this.animation_toggle_text ="Start Flight Animation and Preview";
          }
        }
    },

    mounted() {
        this.$threeInstance.bindContainer(this.$refs.container);
        this.$threeInstance.bindEventHandler(this.$emitter);

        document.addEventListener("fullscreenchange", () => {this.in_fullscreen = !this.in_fullscreen;});
        document.addEventListener("mozfullscreenchange", () => {this.in_fullscreen = !this.in_fullscreen;});
        document.addEventListener("webkitfullscreenchange", () => {this.in_fullscreen = !this.in_fullscreen;});
        document.addEventListener("msfullscreenchange", () => {this.in_fullscreen = !this.in_fullscreen;});

        this.$threeInstance.preloadStaticModels().then((result) => {
            this.preloading = false;
            this.current_bridge = this.$threeInstance.renderedItem.model.modelName;
        });

        this.$emitter.on("loading",(isLoading) => {
            this.loading = isLoading;
        });

        this.$emitter.on("content-changed", (content) => {
            this.current_bridge = content.model.modelName;
        });

        this.$emitter.on("evaluated-points-visible", (content) => {
            this.showLegend = content;
        });
    },

    created() {
        window.addEventListener('resize', () =>{this.$threeInstance.onWindowResize()});
    }
}
</script>

<style scoped>
    #container{
        height: 100%;
        width: 100%;
        position: relative;
    }

    #three_container{
        position: absolute;
        z-index: 8;
    }

    #test_b{
        width: 100%;
        height: 50px;
        width: auto;
        margin-top: 90px;
        margin-left: 20px;
        outline: none !important;
        box-shadow: none;
    }

    #test_c{
      width: 100%;
      height: 50px;
      width: auto;
      margin-top: 150px;
      margin-left: 20px;
      outline: none !important;
      box-shadow: none;
    }

    .animation-toggle {
        position: absolute;
        z-index: 10;
    }

    #preload-spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        scale: 2;
        z-index: 10;
    }

    .bottom-bar {
        position: absolute;
        width: 100%;
        right: 20px;
        z-index: 10;
        bottom: 10px;
    }

    .bottom-bar .bottom-bar-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .bottom-bar-row h3 {
        padding-right: 10px;
    }

    .bottom-bar-row img {
        margin-right: auto;
        margin-left: 40px;
        border:0;
        text-decoration:none;
        outline:none;
    }

    #fullscreen-button {
        outline: none !important;
        box-shadow: none;
    }
    #fullscreen-button:hover {
        scale: 120%;
    }

    #fullscreen-button svg {
        padding: 0px;
    }

    div.horizontalgap {
      float: left;
      overflow: hidden;
      height: 1px;
      width: 0px;
    }

    .color-legend  {
      z-index: 10;
      position: absolute;
      margin-top: 12px;
      height: 50px;
      width: auto;
      top: 0%;
      left: 45%;
      outline: none !important;
      box-shadow: none;
    }

    .color-legend .legend-title {
      text-align: left;
      margin-left: 10%;
      font-weight: bold;
      font-size: 90%;
    }

    .color-legend .accuracy-title {
      text-align: left;
      font-size: 60%;
      margin-left: 35%;
    }

    .color-legend .legend-scale ul {
      margin: 0;
      padding: 0;
      float: left;
      list-style: none;
    }
    .color-legend .legend-scale ul li {
      display: block;
      float: left;
      width: 10%;
      margin-bottom: 6px;
      margin-left: -1%;
      text-align: center;
      font-size: 60%;
      list-style: none;
    }

    .color-legend ul.legend-labels li span {
      display: block;
      float: left;
      height: 15px;
      width: 60px;
    }
    .color-legend .legend-source {
      font-size: 70%;
      color: #999;
      clear: both;
    }
    .color-legend a {
      color: #777;
    }
</style>


