<template>
      <div id="list" class="list-group">
        <li class="list-group-item active">Evaluate your flightpaths:</li>
        <li class="list-group-item">
            <form enctype="multipart/form-data" novalidate v-if="isInitialized() || isSampling() || isUploading()">
                <div class="dropbox">
                    <input type="file" multiple :name="uploadFieldName" :disabled="isUploading()" @change="inputFiles($event.target.name, $event.target.files); fileCount = $event.target.files.length"  accept=".out" class="input-file">

                    <p v-if="isInitialized()">
                        {{dragAndDropText}}
                    </p>

                    <p v-if="isSampling()">
                        Sampling bridges ...
                    </p>

                    <p v-if="isUploading()">
                        <h4>Sampled bridges successfully.</h4>
                        Uploading files ...
                    </p>
                </div>
            </form>

            <!--- UPLOADED -->
            <div v-if="isEvaluating()">
                <h3>Uploaded {{success_upload_count}} file(s) successfully.</h3>
                <!--<button class="btn btn-outline-danger" @click="reset()">Upload again</button>-->
                <p>Evaluating flightpaths ...</p>
            </div>

            <!--- SUCCESS -->
            <div v-if="isSuccess()">
                <h3>Evaluated {{success_evaluate_count}} file(s) successfully.</h3>
                <button class="btn btn-outline-danger" @click="reset()">Reset</button>
            </div>

            <!--FAILED-->
            <div v-if="isFailed()">
                <h2>Evaluation failed.</h2>
                <button class="btn btn-outline-danger" @click="reset()">Try again</button>
                <p><br>{{errorMessage}}</p>
            </div>
        </li>

        <template v-for="fp in this.flightpaths">
            <li id="evaluationItem" v-bind:class="['list-group-item', 'flex-column', 'align-items-start',{'list-group-item-action' : negate(modelSelectionHovered) && fp.model, active: fp.rendered}]" @click="renderEvaluationItem(fp)">
                <div id="content" class="d-flex w-100 justify-content-between">
                    <svg v-if="fp.rendered" style="margin-top: 2px; margin-right: 6px" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-fill" viewBox="0 0 16 16">
                        <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
                        <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
                    </svg>
                    <svg v-else xmlns="http://www.w3.org/2000/svg" style="margin-top: 2px; margin-right: 6px" width="16" height="16" fill="currentColor" class="bi bi-eye-slash" viewBox="0 0 16 16">
                        <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"/>
                        <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"/>
                        <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"/>
                    </svg>
                    <h6 id="fp_name" v-bind:class="['md-1', 'me-auto', {fp_name_dark: fp.rendered}]">
                        {{fp.filename}}</h6>
                    <p v-if="isInitialized(fp.status)"></p>
                    <p v-else id="model-text">{{ fp.model }}</p>
                    <div v-if="isInitialized()" id="model-selection" @mouseenter="modelSelectionHovered = true" @mouseleave="modelSelectionHovered = false">
                        <dropdown-button v-bind:class="{missing: fp.highlight}" v-on:clicked='selectModel(fp,$event)' label="Select Model" :items="models"/>
                        <button type="button" id="remove-model-button" class="btn" @click.stop="removeFile(fp)">
                            X
                        </button>
                    </div>

                </div>

                <div v-if="fp.model=='Custom' && negate(fp.customModelLoaded)" class="input-group" style=" top: 5px; padding-bottom: 5px;"  @mouseenter="modelSelectionHovered = true" @mouseleave="modelSelectionHovered = false">
                    <input id="inputCustomFile" :class="['form-control',{'is-invalid' : fp.invalidCustomFile}]" v-on:change="selectCustomFile(fp, $event.target.files)" type="file" accept=".obj" aria-label="Upload" required>
                    <div class="invalid-feedback">
                            {{fp.invalidCustomFile}}
                    </div>
                    <button class="btn btn-success" type="button" id="InputCustomFileButton" @click.stop="selectModel(fp,'Custom')">Load</button>
                </div>

                <div v-else-if="fp.model=='Custom' && fp.customModelLoaded" class="input-group" style="top: 5px; padding-bottom: 5px;">
                    <p>Custom model: {{fp.customFile.name}} loaded.</p>
                </div>

                <div v-if="isSampling(fp.status)">
                    <span id="flightpath_status">Status: </span>
                    <span class="badge bg-info">Sampling</span>
                </div>

                <div v-else-if="isUploading(fp.status)">
                    <span id="flightpath_status">Status: </span>
                    <span class="badge bg-info">Uploading</span>
                </div>

                <div v-else-if="isEvaluating(fp.status)">
                    <p id="flightpath_status">Status:
                        <span class="badge bg-info">Evaluating</span>
                        <span id="status_text">{{fp.statusText}}</span>
                    </p>
                </div>

                <div v-else-if="isPreparingResults(fp.status)">
                    <p id="flightpath_status">Status:<span class="badge bg-info">Preparing Results</span></p>
                </div>

                <div v-else-if="isSuccess(fp.status)">
                    <p id="flightpath_status">Status:<span class="badge bg-success">Success</span></p>
                    <span id="general_score">Score S: {{fp.score}},&#160; Accuracy &Phi;: {{fp.accuracyScore}},&#160;  Resolution &delta;: {{fp.resolutionScore}},&#160;  Length L: {{fp.lengthCost}},&#160;  Quality &Psi;: {{fp.qualityScore}}</span>
                </div>

                <div v-else-if="isFailed(fp.status)">
                    <p id="flightpath_status">Status:
                        <span class="badge bg-danger">Failed</span>
                        <span id="status_text">{{fp.error_message}}</span>
                    </p>
                </div>
            </li>
        </template>
        <!--<button type="button" id="add-item-button" class="list-group-item list-group-item-action" >ADD +</button>-->
    <li class="list-group-item align-items-center">
        <button type="button" v-bind:disabled ="negate(isInitialized())" class="btn btn-outline-primary evaluation-button" @click="evaluate">Evaluate</button>
        <button type="button" v-if="isFailed() || isSuccess()" class="btn btn-outline-danger evaluation-button" @click="reset">Reset</button>
        <button type="button" v-else class="btn btn-outline-danger evaluation-button">Cancel</button>
        <div v-if="negate(isInitialized(participationStatus))" class="progress" id="participation-button-bar" @click="showParticipationModal = true">
                <span v-if="isFailed(participationStatus)" id="participation-button-bar-lable-white">ERROR: Participation</span>
                <div v-if="isFailed(participationStatus)" class="progress-bar bg-danger" role="progressbar" :aria-valuenow="participationProgress" :style="{width: participationProgress +'%'}" aria-valuemin="0" aria-valuemax="100"></div>

                <span v-if="negate(isFailed(participationStatus))" id="participation-button-bar-lable">Participation</span>
                <div v-if="negate(isFailed(participationStatus))" class="progress-bar bg-success" role="progressbar" :aria-valuenow="participationProgress" :style="{width: participationProgress +'%'}" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <button v-else type="button" v-bind:disabled ="negate(isInitialized())" class="btn btn-success" id="participation-button" @click="showParticipationModal = true">Participate</button>
    </li>
    </div>
    <!-- ensure to keep the modal alive when closed -->
    <keep-alive>
        <challenge-participation v-if="showParticipationModal" v-model:showModal="showParticipationModal" v-model:participationProgress="participationProgress" v-model:participationStatus="participationStatus" :models = "models"></challenge-participation>
    </keep-alive>
</template>

<script>
import { sendFormdata } from '@/assets/utils/api_request.js';
import { GETRequest } from '@/assets/utils/api_request.js';

import ChallengeParticipation from "./ChallengeParticipation.vue"
import DropdownButton from './DropdownButton.vue';

const STATUS_INITIALIZED = 0, STATUS_SAMPLING = 1, STATUS_UPLOADING = 2, STATUS_EVALUATING = 3, STATUS_PREPARING_RESULT = 4, STATUS_SUCCESS = 5, STATUS_FAILED = 6;

export default {
    name: "FlightpathEvaluation",

    data(){
        return {
            currentStatus: null,
            errorMessage: '',
            uploadFieldName: "bundle_input",
            flightpaths: [],
            models: [],
            success_upload_count: 0,
            success_evaluate_count: 0,

            participationProgress: 0,
            showParticipationModal: false,
            participationStatus: STATUS_INITIALIZED,

            modelSelectionHovered: false,
            dragAndDropText : "Drag your bundler files here to begin or click to browse"
        }
    },

    components: {
        'dropdown-button' : DropdownButton,
        'challenge-participation' : ChallengeParticipation,
    },

    methods: {

        ///////////////////// UI ////////////////////
        reset() {
            // reset form to initial state
            this.currentStatus = STATUS_INITIALIZED;
            for(var i = 0; i<this.flightpaths.length; i++){
                this.$threeInstance.removeEvaluationItem(this.flightpaths[i]);
            }
            this.flightpaths = [],
            this.success_upload_count = 0,
            this.success_evaluate_count = 0;
            this.dragAndDropText = "Drag your bundler files here to begin or click to browse";
        },

        isInitialized(status = this.currentStatus){
            return status === STATUS_INITIALIZED;
        },
        isSampling(status = this.currentStatus){
            return status === STATUS_SAMPLING;
        },
        isUploading(status = this.currentStatus) {
            return status === STATUS_UPLOADING;
        },
        isEvaluating(status = this.currentStatus) {
            return status === STATUS_EVALUATING;
        },
        isPreparingResults(status = this.currentStatus) {
            return status === STATUS_PREPARING_RESULT;
        },
        isSuccess(status = this.currentStatus) {
            return status === STATUS_SUCCESS;
        },
        isFailed(status = this.currentStatus) {
            return status === STATUS_FAILED;
        },
        negate(state){
            return !state;
        },
        isRendered(flightpath){
            if(flightpath.rendered){
                return flightpath.rendered;
            }
            else {
                return false;
            }
        },

        /////////// EVALUATION LIFECYCLE ///////////

        //request evaluation status for flightpath
        requestStatus(fp_index, score_promises){
            var fp = this.flightpaths[fp_index];
            const parameter = {
                evaluation_id: fp.request_id
            }
            return GETRequest('/get-evaluation-status', parameter)
                .then(response => {
                    switch(response.data.status[0] / 10){
                        case -8:
                          fp.error_message = "An internal error occurred please notify the devs.";
                          fp.status = STATUS_FAILED;
                          break;
                        case -7:
                          fp.error_message = "An internal error occurred please notify the devs.";
                          fp.status = STATUS_FAILED;
                          break;
                        case -6:
                            fp.error_message = "Point visibility could not be computed.";
                            fp.status = STATUS_FAILED;
                            break;
                        case -5:
                            fp.error_message = "Server could not load the content of the obj file.";
                            fp.status = STATUS_FAILED;
                            break;
                        case -4:
                            fp.error_message = "Server could not load the content of the bundler file.";
                            fp.status = STATUS_FAILED;
                            break;
                        case -3:
                          fp.error_message = "Server could not load flightpath or model.";
                          fp.status = STATUS_FAILED;
                          break;
                        case -2:
                            fp.error_message = "Received sampled points empty.";
                            fp.status = STATUS_FAILED;
                            break;
                        case -1:
                            fp.error_message = "No sampled points received.";
                            fp.status = STATUS_FAILED;
                            break;
                        case 0:
                            fp.statusText = "Loading mesh and bundlerfile.";
                            break;
                        case 1:
                            //mesh and bundler file loaded
                            fp.statusText = "Computing visibility and setting default parameter.";
                            break;
                        case 2:
                            //visibility computed and parameter set
                            fp.statusText = "Computing surface area.";
                            break;
                        case 3:
                            //scene measured - surface area computed
                            fp.statusText = "Computing max theoretical score.";
                            break;
                        case 4:
                            //smax berechnet max theoretical possible score
                            fp.statusText = "Computing resolution.";
                            break;
                        case 5:
                            //resolution computed
                            fp.statusText = "Computing Accuracy.";
                            break;
                        case 6:
                            //accuracy computed
                            fp.statusText = "Saving evaluation results.";
                            break;
                        case 7:
                            //score computed - saved acc and resolution
                            fp.statusText = "Evaluation done. Return score.";
                            break;
                        case 10:
                            fp.status = STATUS_PREPARING_RESULT;
                            score_promises.push(this.requestScore(fp_index));
                            break;
                        default:
                            fp.status = STATUS_FAILED;
                            break;
                    }
                })
                .catch(error => {
                    fp.status = STATUS_FAILED;
                    fp.error_message = "Unable to request status for flightpath."
                    console.error("Unable to request status of flightpath "+fp_index+".");
                    console.error(error.message);
                    Promise.reject(error);
                });
        },

        //request evaluation score for flightpath
        requestScore(fp_index){
            var fp = this.flightpaths[fp_index];
            const parameter = {
                evaluation_id: fp.request_id
            }
            var that = this;
            return GETRequest('/get-temporary-score', parameter)
                .then(response => {

                    fp.score = response.data.final_score[0].toFixed(3);
                    fp.accuracyScore = response.data.accuracy_score[0].toFixed(3);
                    fp.lengthCost = response.data.length_cost[0].toFixed(3);
                    fp.qualityScore = response.data.quality_score[0].toFixed(3);
                    fp.resolutionScore = response.data.resolution_score[0].toFixed(3);

                    const evalPointParameter = {
                      temporary_score_id : response.data.id[0]
                    }
                    GETRequest('/get-evaluated-points', evalPointParameter)
                      .then(pointResponse => {
                          try {
                              that.$threeInstance.visualizeResult(fp, pointResponse.data);
                          }
                          catch(error){
                              console.error("Visualizing the evaluated points for evaluated flightpath failed.");
                              fp.error_message = "Unable to visualize evaluated points for evaluated flightpath "+fp_index+".";
                              fp.STATUS_FAILED;

                          }
                          fp.status = STATUS_SUCCESS;
                      })
                      .catch(error => {
                        fp.status = STATUS_FAILED;
                        fp.error_message = "Unable to request evaluated points for evaluated flightpath.";
                        console.error("Unable to request evaluated points for evaluated flightpath "+fp_index+".");
                        console.error(error.message);
                        Promise.reject(error);
                      });
                })
                .catch(error => {
                    fp.status = STATUS_FAILED;
                    fp.error_message = "Unable to request score for flightpath.";
                    console.error("Unable to request score for flightpath "+fp_index+".");
                    console.error(error.message);
                    Promise.reject(error);
                });
        },

        //async status loop
        waitForScores(currentStatus, flightpaths, requestStatus){
            return new Promise(async function (resolve, reject) {

                var score_promises = [];

                //requests status of evaluations until every flightpath is finished
                while(currentStatus === STATUS_EVALUATING){

                    var status_promises = [];
                    for(var i = 0; i < flightpaths.length; i++){
                        (function(j){
                            var fp = flightpaths[j];

                            //only requests status for flightpaths, where the status is evaluating
                            if(fp.status === STATUS_EVALUATING){
                                console.log("Check status for flightpath "+fp.filename+".");
                                status_promises.push(requestStatus(j,score_promises));
                            }
                        })(i);
                    }

                    //wait until every status request returns
                    await Promise.allSettled(status_promises)
                        .then(async function (values){
                            var log = "Checked status for flightpaths: ";
                            values.forEach((element,index) => {
                                log = log + index + ", ";
                            });

                            //check if every flightpath is evaluated
                            if(status_promises.length > 0){
                                const waiter = ms => new Promise(res => setTimeout(res, ms));
                                await waiter(15000);
                            }
                            else {
                                currentStatus = STATUS_PREPARING_RESULT;
                            }
                        });
                }

                //wait until for each flightpath a score request returned
                var failed = [];
                var successful_score_requests = 0;
                Promise.allSettled(score_promises)
                    .then((results) => {
                        results.forEach((result,index) =>
                        {
                            if(result.status == "rejected"){
                                failed.push(index);
                            }else if(result.status =="fulfilled"){
                                successful_score_requests++;
                            }
                        });

                        if(successful_score_requests != flightpaths.length){
                            console.warn("Some score requests for the flightpaths failed.");
                            resolve({success: successful_score_requests, failed: failed.length});
                        }
                        else if(successful_score_requests == 0){
                            reject(new Error('Evaluation for every flightpath failed.'+failed));
                        }
                        else{
                            resolve({success: successful_score_requests, failed: failed.length});
                        }
                    });
            });
        },

        ///////////////////// Api requests ////////////////////
        //participate in challenge
        participate() {

        },

        //upload data to server for evaluation
        evaluate() {

            //check if no flightpath added
            if(this.flightpaths.length == 0){
                return;
            }

            var request_id_promises = [];

            //checks if for each flightpath a model is selected
            for(var i = 0; i < this.flightpaths.length; i++){
                var fp = this.flightpaths[i];
                if(fp.model==undefined || (fp.model=="Custom" && !fp.customFile)){
                    fp.highlight = true;
                    this.currentStatus = STATUS_INITIALIZED;
                    return false;
                }

                //sample random points on the bridges
                fp.status = STATUS_SAMPLING;
                this.$threeInstance.sample(fp);

            };
            this.currentStatus = STATUS_UPLOADING;

            for(var i = 0; i < this.flightpaths.length; i++){
                (function(j, flightpaths){
                    var fp = flightpaths[j];

                    //create evaluation json form
                    const formData = new FormData();
                    if(fp.model=="Custom"){
                      formData.append("custom", fp.file);
                      formData.append("custom_model", fp.customFile);
                    } else {
                      if(fp.model=="bridge") {
                        formData.append("Scherkondetal", fp.file);
                      } else {
                        formData.append(fp.model, fp.file);
                      }
                    }
                    var blob = new Blob([fp.sampledPoints], {type: "octet/stream"});
                    formData.append("sampled_points",blob);
                    fp.status = STATUS_UPLOADING;

                    //send evaluation request for flightpath
                    //starts evaluation on server
                    //gets request id
                    var id_request = sendFormdata(formData, '/evaluate-flightpath')
                        .then(response => {
                            var request_id = response.data.Evaluation_Request_Id.value;
                            if(request_id === undefined){
                              request_id = response.data.Evaluation_Request_Id;
                            }
                            if(request_id > 0){
                                console.log("Request id for flightpath "+fp.filename+" "+request_id);
                                fp.request_id = request_id;
                                fp.status = STATUS_EVALUATING;
                                fp.statusText = "Starts evaluating.";
                            } else if(request_id == -1){
                                console.log("Flightpath "+fp.index+": Request id -1: Server found no flightpath.");
                                fp.status = STATUS_FAILED;
                                fp.error_message = "Server found no flightpath.";
                            } else if(request_id == -2){
                                console.log("Flightpath "+fp.index+": Request id -2: Server found no sampled points.");
                                fp.status = STATUS_FAILED;
                                fp.error_message = "Server found no sampled points.";
                            } else if(request_id == -3){
                                console.log("Flightpath "+fp.index+": Flightpath is of the wrong file type.");
                                fp.status = STATUS_FAILED;
                                fp.error_message = "Flightpath is of the wrong file type.";
                            } else if(request_id == -4){
                                console.log("Flightpath "+fp.index+": Custom model is of the wrong file type.");
                                fp.status = STATUS_FAILED;
                                fp.error_message = "Custom model is of the wrong file type.";
                            }  else if(request_id == -5){
                              console.log("Flightpath "+fp.index+": Not all models for challenge evaluation present.");
                              fp.status = STATUS_FAILED;
                              fp.error_message = "Not all models for challenge evaluation present.";
                            } else if(request_id == -999){
                              console.log("Flightpath "+fp.index+": A submitted file is larger than the allowed 100 MB.");
                              fp.status = STATUS_FAILED;
                              fp.error_message = "A submitted file is larger than the allowed 100 MB.";
                            } else{
                                console.error("Flightpath "+fp.index+": Request id "+request_id+" < -4 or 0: Unknown error.");
                                fp.error_message = "Request id < -4: Unknown error.";
                                fp.status = STATUS_FAILED;
                            }
                        })
                        .catch(response => {
                                console.warn("Flightpath "+fp.index+": "+response);
                                fp.error_message = response;
                                fp.status = STATUS_FAILED;
                        });
                    request_id_promises.push(id_request);
                })(i, this.flightpaths);
            }

            //triggers when a response for all flightpaths is received
            Promise.allSettled(request_id_promises).then(values => {
                values.forEach(element => {
                    if(element.status == "fulfilled"){
                        this.success_upload_count++;
                        this.currentStatus = STATUS_EVALUATING;
                        this.waitForScores(this.currentStatus, this.flightpaths, this.requestStatus)
                            .then(response =>{
                                this.currentStatus = STATUS_SUCCESS;
                                this.success_evaluate_count = response.success;
                            })
                            .catch(error => {
                                console.error(error.message);
                                Promise.reject();
                            });
                    }
                });
            });
        },

        ///////////////////// File List ////////////////////
        //handle file input
        inputFiles(fieldName, files) {
            if(!files) return;

            Array
                .from(Array(files.length).keys())
                .map(x => {
                    var flightpath = {
                        filename: files[x].name,
                        file: files[x],
                        highlight: false,
                        participation: false,
                        status: STATUS_INITIALIZED,
                        statusText: "Initialized"
                    };
                    flightpath.index = this.flightpaths.length;
                    if(this.verifyFile(flightpath)){
                        this.flightpaths.push(flightpath);
                        this.dragAndDropText = "Drag your bundler files here to begin or click to browse";
                    }
                });
        },

        //enable removing files
        removeFile(flightpath){
            var fp_index = this.flightpaths.findIndex(el=>el.filename == flightpath.filename);
            this.flightpaths.splice(fp_index, 1);
            if(flightpath.model){
                this.$threeInstance.removeEvaluationItem(flightpath);
                flightpath.rendered = false;
            }

            for (var i = 0; i < this.flightpaths.length; i++){
                this.flightpaths[i].index = i;
            }
        },

        //select bridge model to evaluate
        selectModel(flightpath, model){
            flightpath.model = model;
            if(model == "Custom"){
                if(!flightpath.customFile || flightpath.invalidCustomFile){
                    return;
                }
                if(!flightpath.customModelLoaded){
                    flightpath.customModelLoaded = true;
                    this.$threeInstance.addEvaluationItem(flightpath);
                }
            }
            else {
                this.$threeInstance.addEvaluationItem(flightpath);
            }
            flightpath.highlight = false;
            this.modelSelectionHovered = false;
        },

        //renders flightpath and model
        renderEvaluationItem(flightpath){
            this.$emitter.emit('renderEvaluationVisualization');
            if(!this.modelSelectionHovered){
                if(flightpath.model){
                    if(this.renderedFlightpath){
                        this.renderedFlightpath.rendered = false;
                    }
                    this.$threeInstance.showEvaluationItem(flightpath);
                    this.renderedFlightpath = flightpath;
                    flightpath.rendered = true;
                }
            }
        },

        //triggers when a custom model is added
        selectCustomFile(flightpath, file_list){
            var file = file_list[0];
            if(!file){
                flightpath.invalidCustomFile = "";
                return;
            }
            else if(file.name.split('.').pop()!="obj"){
                flightpath.invalidCustomFile = "Wrong file type. '.obj' needed.";
                return;
            }
            else{
                flightpath.customFile = file;
                flightpath.invalidCustomFile = undefined;
            }
        },

        //triggers when user tries to reload or nav away during participation
        preventReloadOrNav(event){
            if(this.isInitialized(this.participationStatus))return
            event.preventDefault();
            event.value="Do you really want to leave the page? You participating in the challenge.";
            return "Do you really want to leave the page? You participating in the challenge.";
        },

        verifyFile(flightpath){
            var file = flightpath.file;
                if(!file){
                    flightpath.status = STATUS_FAILED;
                    this.dragAndDropText = "Empty file.";
                    return false;
                }
                else if((file.size/1024/1024)>100){
                    flightpath.status = STATUS_FAILED;
                    this.dragAndDropText =  "File to large. Larger than 100Mb";
                    return false;
                }
                else if(file.name.split('.').pop()!= 'out'){
                    flightpath.status = STATUS_FAILED;
                    this.dragAndDropText =  "Wrong file type. '.out file needed'";
                    return false;
                }
            return true;
        },
    },

    beforeMount(){
        window.addEventListener("beforeunload", this.preventReloadOrNav);
    },

    mounted(){
        this.reset();
        var staticModels = this.$threeInstance.getStaticModels();
        this.$threeInstance.setDefaultFlightpath(staticModels[0].modelName,"bundler_files/slicing_02mm_tour.out", "slicing_02mm_tour.out");
        for(var i = 0; i<staticModels.length; i++){
            this.models.push(staticModels[i].modelName);
        }
        this.models.push("Custom");

        //event which is triggered when scoreboard visualization is loaded
        var that = this;
        this.$emitter.on('renderScoreVisualization',function(){
            if(that.renderedFlightpath){
                that.renderedFlightpath.rendered = false;
            }
        })

    },

    beforeUnmount(){
        window.removeEventListener("beforeunload", this.preventReloadOrNav);
    }
}
</script>

<style>
    @media (min-width: 50em) {
        #list {
            /*min-height: 380px;*/
            max-height: 600px;
            margin-bottom: 10px;
            overflow-y: auto;
        }
    }

    /*File upload Drag-n-Drop*/
    .dropbox {
        outline: 2px dashed grey; /* the dash box */
        outline-offset: -10px;
        background: #E3F2FD;
        color: dimgray;
        padding: 10px 10px;
        min-height: 100px; /* minimum height */
        margin-left: 2%;
        margin-right: 2%;
        margin-top: 15px;
        margin-bottom: 10px;
        position: relative;
        cursor: pointer;
    }

    .input-file {
        opacity: 0; /* invisible but it's there! */
        width: 100%;
        height: 90px;
        left: 0%;
        z-index: 10;
        position: absolute;
        cursor: pointer;
    }

    .dropbox:hover {
        background: #a4d7fc7c;
    }

    .dropbox p {
        font-size: 1.2em;
        text-align: center;
        padding: 10px 0;
        width: 100%;
        z-index: 7;
        position: absolute;
    }

    .dropbox {
        padding-left: 5px;
    }

    /* Flightpath item */

    fp_name_dark {
        color: black;
    }

    #evaluationItem.active {
        background-color: #E3F2FD;
        color: black;
        border-color: #cde9fd ;
    }

    #flightpath_status {
        padding-left: 5px;
        margin-bottom: 0px;
    }

    .badge {
        margin-left: 12px
    }

    #status_text {
        padding-left: 12px;
    }

    #general_score {
        padding-left: 5px;
    }

    #model-selection {
        display:flex;
        gap: 10px;
    }

    #model-text {
        display: flex;
        gap: 10px;
        padding-right: 12px;
        margin-bottom: 0px;
    }

    .missing > * {
        border: solid 3px red;
    }

    #remove-model-button {
        height: 35px;
        width: 35px;
        padding: 0px;
        margin: 0px;
        font-size: 18px;
        color: #BB2D3B;
        border: solid 0.5px #BB2D3B;
        outline: none !important;
        box-shadow: none;
    }

    #remove-model-button:hover{
        background-color: #BB2D3B;
        color: #fff;
    }

    /* Aside interface / buttons */
    .evaluation-button {
        margin-left: 12px;
        margin-top: 12px;
        width: 45%;
    }

    #participation-button {
        margin-bottom: 25px;
        margin-top: 10px;
        margin-left: 2%;
        width: 92%;
    }

    #participation-button-bar {
        position: relative;
        margin-bottom: 25px;
        margin-top: 10px;
        margin-left: 2%;
        width: 92%;
        height: 35px;
    }

    #participation-button-bar-lable{
        color: black;
        font-size: 1.3em;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    #participation-button-bar:hover {
        filter: brightness(85%);
    }

    #participation-button-bar-lable-white{
        color: rgb(94, 93, 93);
        font-size: 1.3em;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    h3 {
        padding-top: 5px;
    }

</style>