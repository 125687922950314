import { fetchBundle } from '../../../public/bundle_parser.js'

import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import * as Three from 'three';

var contentHandler = {
    three_context: null,
    three_loader: [undefined, undefined],
    customCount: 0,
    visibleItem: null,

    setThreeContext: function(context){
        this.three_context = context;

        //add model manager for async loading models
        this.three_context.loadingManager = new Three.LoadingManager();
        var that = this;
        this.three_context.loadingManager.onStart = function (url, itemsLoaded, itemsTotal){
            console.log('Started loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.' );
            that.three_context.itemsLoaded = itemsTotal;
        };

        this.three_context.loadingManager.onLoad =  function (){
            that.three_context.itemsLoaded--;
            console.log("Loaded");
        };

        this.three_context.loadingManager.onProgress = function ( url, itemsLoaded, itemsTotal ) {
            console.log( 'Loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.' );
        };

        this.three_context.loadingManager.onError = function (url) {
            console.log( 'There was an error loading ' + url );
        }

        this.three_loader[0] = new OBJLoader(this.three_context.loadingManager);
        this.three_loader[1] = new GLTFLoader(this.three_context.loadingManager);
    },

    //loads bridge model
    loadModel: function(model, filepath, filename){
        var that = this;
        var ending = filepath.split('.').pop();
        return new Promise(function(resolve, reject){
            var processResult = function(object){
                var obj;
                if(ending == 'obj' || filepath.includes("blob")){
                    obj = object.children[0];
                }
                else{
                    obj = object.scene.children[0];
                }

                if(model=='Custom'){
                    obj.name = model+"_"+filename;
                }
                else {
                    obj.name = model;
                }

                obj.material = new Three.MeshPhongMaterial({ color: 0xBBBBBB, flatShading: true });
                obj.castShadow = true;
                obj.receiveShadow = true;


                // normalize the bridge using the bounding box such that it is inside a 1-1-1 cube
                var bbox = new Three.Box3().setFromObject(obj);

                var x_range = bbox.max.x - bbox.min.x;
                var y_range = bbox.max.y - bbox.min.y;
                var z_range = bbox.max.z - bbox.min.z;
                var max = Math.max(x_range, Math.max(y_range, z_range))

                var scale = 1/max;
                obj.scale.set(scale, scale, scale);

                bbox.setFromObject(obj);

                x_range = bbox.max.x - bbox.min.x;
                y_range = bbox.max.y - bbox.min.y;
                z_range = bbox.max.z - bbox.min.z;

                var translate = new Three.Vector3(- bbox.min.x - x_range/2, -bbox.min.y - y_range/2, -bbox.min.z);
                obj.rotation.set(-Math.PI/2,0,0);
                var rotation = obj.rotation;

                obj.translateX(translate.x);
                obj.translateY(translate.y);
                obj.translateZ(translate.z);
                bbox.setFromObject(obj);

                obj.normalization = {translation: translate, rotation: rotation, scale: scale};
                obj.geometry.boundingBox = bbox;
                resolve(obj);
            }

            if(ending == 'obj' || filepath.includes("blob")){
                that.three_loader[0].load(filepath, processResult , undefined, function( error ){
                    reject(error);
                    console.error(error);});
            }
            else if(ending == 'glb' || ending =='gltf') {
                that.three_loader[1].load(filepath, processResult , undefined, function( error ){
                    reject(error);
                    console.error(error);});
            }
            else {
                console.error("Model is of wrong filetype.");
                reject();
            }
        });
    },

    loadFlightpath: function(item, model){
        console.log("Start loading flightpath for "+item.model+item.index);
        var that = this;
        return new Promise(function(resolve, reject){
            var flightpathPath;
            if(item.file instanceof File){
                flightpathPath = URL.createObjectURL(item.file);
            } else {
                flightpathPath = item.file;
            }
            var fp_points = []
            fetchBundle(flightpathPath,false, true).then((data) => {
                var camera_length = data.cameras.length;

                for(var i = 0; i < camera_length; i += 15){
                    var camera_rotation = [
                        data.cameras[i+3], data.cameras[i+4], data.cameras[i+5],
                        data.cameras[i+6], data.cameras[i+7], data.cameras[i+8],
                        data.cameras[i+9], data.cameras[i+10], data.cameras[i+11]
                    ]

                    var transposed_camera_rotation = [
                        data.cameras[i+3], data.cameras[i+6], data.cameras[i+9],
                        data.cameras[i+4], data.cameras[i+7], data.cameras[i+10],
                        data.cameras[i+5], data.cameras[i+8], data.cameras[i+11]
                    ]

                    var min_transposed_camera_rotation = [
                        -data.cameras[i+3], -data.cameras[i+6], -data.cameras[i+9],
                        -data.cameras[i+4], -data.cameras[i+7], -data.cameras[i+10],
                        -data.cameras[i+5], -data.cameras[i+8], -data.cameras[i+11]
                    ]

                    var camera_translation = [data.cameras[i+12], data.cameras[i+13], data.cameras[i+14]]

                    //This is -R' * t
                    var camera_world_position = [
                        (-data.cameras[i+3]*data.cameras[i+12]) + (-data.cameras[i+6]*data.cameras[i+13]) + (-data.cameras[i+9] *data.cameras[i+14]),
                        (-data.cameras[i+4]*data.cameras[i+12]) + (-data.cameras[i+7]*data.cameras[i+13]) + (-data.cameras[i+10]*data.cameras[i+14]),
                        (-data.cameras[i+5]*data.cameras[i+12]) + (-data.cameras[i+8]*data.cameras[i+13]) + (-data.cameras[i+11]*data.cameras[i+14])
                    ]

                    var new_point = new Three.Vector3(camera_world_position[0], camera_world_position[1], camera_world_position[2]);
                    // normalize the flightpath according to the values computed before to normalize the bridge
                    new_point.multiplyScalar(model.normalization.scale);
                    new_point.add(model.normalization.translation);
                    fp_points.push(new_point);
                item.bundler = data;
                }
                const geometry = new Three.BufferGeometry().setFromPoints(fp_points);
                var line = new Three.Line(geometry, new Three.LineDashedMaterial( { color: 0x000000, dashSize: 3, gapSize: 0.01 } ));
                line.computeLineDistances();
                line.rotation.set(model.normalization.rotation.x,model.normalization.rotation.y,model.normalization.rotation.z);
                line.name = item.model+"_"+item.filename+"_Flightpath";
                line.cameras = data.cameras
                line.normalization = model.normalization
                console.log("Successfully loaded flightpath for "+item.model+item.index);
                resolve(line);
            })
            .catch((error) => {
                console.log(error);
                console.error("Error loading flightpath for "+item.model+".");
                reject(error);
            });
        });
    },
}
export {contentHandler};