var PageTitle_String = 'Bauhaus Path Planning Challenge';
var Motivation_String = `
    Welcome to the web platform of the Bauhaus Path Planning Challenge (bppc). The bppc aims to find suitable algorithms
    and methods for the planning of UAS flights for building inspection. The scope is limited to visual inspections with
    cameras, pre-planned in advance and loaded to the UAS. While many applications of drones are viable in practice and
    different inspection scenarios can be solved well without intensive planning, inspections with high quality requirements
    can only be completed with careful consideration in advance.
    $$ $$
    The application of unmanned aerial vehicles (UAV), so called drones,
    for the inspection of buildings is gaining traction and has the potential to significantly increase
    the efficiency of maintenance processes. Especially for the inspection of large infrastructure like bridges or
    dams, image based inspections can greatly reduce the total effort by limiting manual inspections to those areas,
    where potential damages have been identified automatically.
    $$ $$
    Different defects like cracks, rust or sintering can occur on building surfaces and need to be detected in inspections.
    They can provide important information about a structures  condition and support the maintenance.
    Additionally using the Structure from Motion (SfM) method, 3D models can be computed from the images,
    which can represent the geometry of the structure with high resolution and accuracy.
    $$ $$
    To be able to reliably gain this information from the data, the images need to fulfill certain quality requirements.
    Therefor it has become common to pre-plan the flight paths for the inspections in advance, by defining a
    series of waypoints from which the drone will capture the images, following the waypoints in order.
    $$ $$
    Many methods have been developed in this context that use different approaches and boundary conditions to
    fulfill their requirements. While the basic goals are the same for most approaches, capturing high-quality
    images with sufficient resolution to be able to detect certain effects on the surface and compute useful 3D
    reconstructions, the concrete conditions vary widely and often different restrictions on the possible positions
    of the waypoints apply. Accordingly, no method exists so far to compare the different approaches or even
    analyze if the requirements can be fulfilled with the computed flight path.
    $$ $$
    The goal of the Bauhaus Path Planning Challenge (bppc) is to establish a method for the evaluation of flight
    paths that can be applied flexible in different scenarios and builds on fundamental aspects, without any
    specific assumptions with regard to the used UAV, the building or the on-site conditions during the inspection.
    Using this method, it is possible to compare different approaches and evaluate, which are especially well
    suited and produce the best results. To be able to compare different flight paths, it is necessary that
    they have been computed for the same object and the same requirements. It is currently not common to publish
    the scenarios for which the approaches have been developed and tested, so another contribution of the bppc
    is to provide a set of reference scenarios, 3D models of the structures and detailed requirements, based on
    which different flight paths can be compared.
    $$ $$
    To simplify the evaluation of flight paths and to present a ranking, we provide this platform containing all
    detailed information for the bppc. It offers the possibility to upload flight paths for evaluation, visualize
    them and submit them for the challenge. It also contains the download of the reference scenarios. There are
    detailed descriptions of the used evaluation metrics, which aspects of the flight paths are evaluated and how
    the final score is computed. The platform represents the central access poinz to  the challenge, bringing together
    the community in the context of UAV flight path planning and inviting them to contribute.
          `

var ParticipationContent_String =
    `
    To participate in the Bauhaus Path Planning Challenge click the participate button and select flightpaths for the three provided
    challenge models. Note that only valid bundler files can be evaluated. It is possible to visualize the flightpaths
    in the 3D window on this page in order to verify their correctness. The maximum upload size per file is 100MB.
    While the flightpaths are being evaluated please do not reload this website. After the evaluation is finished
    the averaged score for all models is shown and can be accepted in order to participate in the scoreboard. It is not
    necessary to accept the score if the results are not satisfactory.

    For the flightpath evaluation the default parameter are set to the parameter described in the Bauhaus Path Planning Challenge
    paper. The camera sensor has dimensions $(0.0358$m, $0.0239m)$, a pixel resolution of $(7360, 4912)$ and a focal length of $0,055$m.
    The principal point of the camera is assumed to be in the middle of the image at $(7360/2, 4912/2)$. The quality requirements vary 
    for each model, and are detailed below.

    Note that the focal length parameter and the radial distortion coefficients specified in the bundler file are being ignored.
    The flightpath animation window has roughly the same parameters as the modeled camera that is used for evaluation. One
    can thus use the animation window to verify whether the flightpath was defined correctly. The red plane in the animation window
    is at the maximum allowed distance that points can be from the defined camera in order to achieve the target resolution.
    If there are any open questions regarding the challenge evaluation please get in contact with us.
      `;

var PaperContent_String = `
    In the following the computation of the score will be explained briefly. A more detailed investigation and explanation
    can be found in the publication of the paper.

    Let the visibility be represented by
    $$
    V_{ij} =
    \\begin{equation}
    \\begin{cases}
    1, &\\text{if 3D point }j \\text{ is visible from viewpoint }i\\\\
    0, &\\text{otherwise}
    \\end{cases}
    \\end{equation}
    $$
    where
    $$
    \\begin{align*}
    &V_{ij} = \\text{visibility relations between the object points and viewpoints,}\\\\
    &c_i \\in C = \\text{set of }m \\text{ viewpoints in the flight path,}\\\\
    &p_j \\in P = \\text{set of }n \\text{ 3D points on the object surface}.
    \\end{align*}
    $$

    In order to detect effects of interest in the images, a certain resolution on the surface of the scene is required.
    Let
    $$
    \\begin{equation}
    d^{*} = f \\frac{d_{obj}}{d_{pix}}
    \\end{equation}
    $$
    be the optimal distance where
    $$
    \\begin{align*}
    &f = \\text{focal length [mm]},\\\\
    &d_{obj} = \\text{target resolution on the object surface [mm/px]},\\\\
    &d_{pix} = \\text{size of one pixel on the sensor [mm/px]},\\\\
    &d^{*} = \\text{optimal distance between object and camera [mm].}
    \\end{align*}
    $$

    Since precisely achieving this computed distance is practically not feasible a range $d_{\\epsilon}$ around the optimal distance
    is defined. The range places a near limit $d_{near} = d^{*} - d_{\\epsilon}$ and a far limit $d_{near} = d^{*} + d_{\\epsilon}$
    on the accepted distance. The resolution requirement $\\delta_j$ for an object point $p_j$ is the computed by
    $$
    \\begin{equation}
    \\delta_j =
    \\begin{cases}
    1, & \\text{if } d_{near} \\leq d_{ij} \\cdot V_{ij} \\leq d_{far} \\text{ for any }c_i \\in C\\\\
    0, & \\text{otherwise}
    \\end{cases}
    \\end{equation}
    $$
    where
    $$
    d_{ij} = \\text{distance between viewpoint }c_i \\text{ and } p_j.
    $$
    The global fulfillment of the object resolution requirement is computed as follows:
    $$
    \\begin{equation}
    \\delta = \\frac{1}{n} \\sum_{p_j \\in P}\\delta_j
    \\end{equation}
    $$

    While the object resolution can by computed by evaluating single visibility relations, the accuracy requirements involves the relative
    orientations of adjacent images, as a narrow baseline between two images can lead to a glacing intersection for the object point triangulation.
    This criterion concerns the expected accuracy of the 3D reconstruction from SfM using the computed fligth path. To quantify this,
    measurement errors are propagated through the triangulation, the covariance of the triangulated 3D position is determined, a principal
    component analysis is performed to find the largest variance of the triangulation. Here an object point is only considered for the
    triangulation, if the projection lies in the image, the visibility is not occluded and the distance between the current camera and the point
    is inbetween the allowed range $(d_{near}, d_{far})$.

    First the Jacobian matrix $J_j$ containing all partial derivatives of the triangulation with regard to the image measurements is computed using
    automatic differentiation. To perform the non-linear uncertainty propagation through the triangulation, $J_j$ is multiplied with the covariance
    matrix $\\Sigma_I$ of the input to obtain the covariance matrix of the output $\\Sigma_{P,j}$ according to (Ochoa and Belongie, 2006):
    $$
    \\begin{equation}
    \\Sigma_{P,j} = J_j \\cdot \\Sigma_I \\cdot J_j^T.
    \\end{equation}
    $$

    With the assumption that the measurements in the images are within $1px$ of the true position and uncorrelated, the covariance matrix for the
    input $\\Sigma_I$ is set to the identity matrix. Performing the PCA, the largest eigenvalue of the output covariance matrix $e_{max,j}$ is
    determined, quantifying the largest variance of the output and serving as a measure for the achievable accuracy.

    The local fulfillment of the accuracy criterion $\\Phi_j$ for each point $p_j \\in P$ is computed using the admissible variance $e^{*}$:
    $$
    \\begin{equation}
    \\Phi_j =
    \\begin{cases}
    1, & \\text{if } e_{max,j} \\leq e^{*}\\\\
    0, & \\text{otherwise}.
    \\end{cases}
    \\end{equation}
    $$
    The global fulfillment of the accuracy requirement is computed as follows:
    $$
    \\begin{equation}
    \\Phi = \\frac{1}{n} \\sum\\limits_{p_j \\in P}.
    \\end{equation}
    $$

    For efficient inspections, the objective is to minimize the mission duration by minimizing the number of required images and the distance the
    UAS has to travel along the flight path. Accordingly, these two aspects can be combined into a cost function for the path planning problem,
    which aims to minimize this cost. The path cost $L$ is computed as follows:
    $$
    \\begin{equation}
    L = \\sum\\limits_{c_i \\in C}||c_i - c_{i-1}|| + m
    \\end{equation}
    $$
    where
    $$
    \\begin{align*}
    &||c_i - c_{i-1}|| = \\text{Euclidean distance between two successive viewpoints},\\\\
    &m = |C| = \\text{number of images in the path}.
    \\end{align*}
    $$

    The quality $\\Psi$ of the flight path is computed as the product of the two quality measures:
    $$
    \\begin{equation}
    \\Psi = \\delta \\cdot \\Phi
    \\end{equation}
    $$

    To normalize the Score $\\hat{S}$ for different models, the surface area of the model of the scene is used as a factor, since it is roughly
    proportional to the path length and the number of images. The target distance between camera and object $d^{*}$ is used to normalize the
    score for different inspection scenarios:
    $$
    \\begin{equation}
    \\hat{S} = \\Psi \\frac{A}{L \\cdot d^{* 2}}
    \\end{equation}
    $$
    where
    $$
    \\begin{align*}
    &\\Psi = \\text{quality factor of the flight path},\\\\
    &L    = \\text{cost of the flight path length},\\\\
    &A    = \\text{surface area of the 3D model of the scene}
    \\end{align*}
    $$

    To provide an upper bound for the achievable score, a minimum length path for a very simplified setting can be computed.
    The minimal number of required images can be computed as:
    $$
    \\begin{equation}
    m_{min} = \\frac{3 \\cdot A}{d^2_{obj} \\cdot (r_x \\cdot r_y)}
    \\end{equation}
    $$
    where $r_x$ and $r_y$ are the horizontal and vertical resolution of the images and their product with the squared object resolutoion $d_{obj}$
    is the area covered by one image. The length $dist_{min}$ of the flight path is the height of a planar strip, as it has to be traversed once.
    WIth the se assumptions a minimal path cost $L_{min} = m_{min} + dist_{min}$ can be computed and used for an upper bound of the achievable
    score $S_{max}$, as in Equation $10$.

    The final score is then calculated as:
    $$
    \\begin{equation}
    S = \\frac{\\hat{S}}{S_{max}}.
    \\end{equation}
    $$
    `;
var DownloadContent_String = `
    The challenge provides three inspections scenarios with different buildings and quality requirements. To participate in
    the ranking, paths for all three scenarios have to be submitted.
    
    The models that are used in the participation challenge can be downloaded below.
    It is possible to visualize arbitrary flightpaths for arbitrary models using this website.
    However, the flightpaths have to be stored in a valid bundler file and the models have to be stored in a valid .obj file.
    Before evaluating a flightpath the visualization can be used to verify that the flightpath was stored correctly.
    It can happen that the flightpath or model visualization runs with a low number of frames when the browser does not make use
    of a dedicated GPU.
    
    As only the resulting path has to be submitted for evaluation, any processing of the model geometry is allowed, such as remeshing,
    filtering, or conversion to a point cloud. 
      `;

var TeamContent_String = `
    The Bauhaus Path Planning Challenge was designed and published by Paul Debus. To make the challenge publicly available
    this website was created as a student project by Paul Debus, Philipp Tornow and Anton Lammert.
      `;

var ContactContent_String = `
    If there are questions regarding the challenge evaluation or any problems related the functionality of this website
    please get in contact with us under paul.debus@uni-weimar.de .
      `;


export {PageTitle_String, Motivation_String, ParticipationContent_String, PaperContent_String, DownloadContent_String,TeamContent_String, ContactContent_String};