class BundleParser {

    constructor() {

      this.m_files = [];
      this.m_numCameras = 0;
      this.m_cameras = new Float32Array();
      this.m_numPoints = 0;
      this.m_currentCameraIterator = 0;
      this.m_points = new Float32Array();
      this.m_colors = new Uint8Array();
      this.m_views = new Uint16Array();
      this.m_viewIndices = new Uint32Array();
      this.m_viewArray = [];
      this.m_lastViewIndex = 0;
      this.m_currentPointIterator = 0;

      this.headerRead = false;
      this.camerasRead = false;
      this.pointsRead = false;
      this.lineNumber = 0;
      this.expectedNumCameras = 0;
      this.expectedNumPoints = 0;
      this.parsedNumCameras = 0;
      this.parsedNumPoints = 0;
      this.currentCameraLine = 0;
      this.currentCameraParams = [];
      this.currentCameraR = [];
      this.currentCameraT = [];
      this.currentPointLine = 0;
      this.currentPointPosition = [];
      this.currentPointColor = [];
      this.currentPointViewList = [];
    }

    getData() {
      if (!this.headerRead || !this.camerasRead) {
        throw new Error("Parsing of bundle file was incomplete!");
      }
      return {
        numCameras: this.m_numCameras,
        numPoints: this.m_numPoints,
        files: this.m_files,
        cameras: this.m_cameras,
        points: this.m_points,
        colors: this.m_colors,
        views: this.m_views,
        viewIndices: this.m_viewIndices
      };
    }
    addFile(fileName) {
      if (fileName == "") return;
      const extension = fileName.toLowerCase();
      if (
        extension.endsWith(".jpg") ||
        extension.endsWith(".jpeg") ||
        extension.endsWith(".png") ||
        extension.endsWith(".bmp") ||
        extension.endsWith(".tif") ||
        extension.endsWith(".tiff") ||
        extension.endsWith(".tga")
      ) {
        this.m_files.push(fileName);
      } else {
        throw new Error(
          "Filename does not end with a known image file extension."
        );
      }
    }
    parseLine(l) {
      this.lineNumber++;
      if (l.startsWith("#") || !(l.length > 0)) {
        return;
      }
      const tokens = l.split(" ");
      if (!this.headerRead) {
        this.parseHeader(tokens);
        return;
      }
      if (!this.camerasRead) {
        this.parseCameraLine(tokens);
        return;
      }
      if (!this.pointsRead) {
        this.parsePointLine(tokens);
        return;
      }
      throw new Error("Bundler: file has already been parsed exaustively");
    }
    parseHeader(tokens) {
      if (tokens.length !== 2) {
        throw new Error(
          "Bundler: parsing error for Header in line " +
            this.lineNumber +
            ". Number of tokens != 2."
        );
      }
      const cameraTokens = tokens.map(Number);
      this.expectedNumCameras = cameraTokens[0];
      this.expectedNumPoints = cameraTokens[1];
      this.m_numCameras = cameraTokens[0];
      this.m_cameras = new Float32Array(this.m_numCameras * 15);
      this.m_numPoints = cameraTokens[1];
      this.headerRead = true;
    }
    parseCameraLine(tokens) {
      if (tokens.length !== 3) {
        throw new Error(
          "Bundler: parsing error for Camera in line " +
            this.lineNumber +
            ". Number of tokens != 3."
        );
      }
      const cameraLineTokens = tokens.map(Number);
      switch (this.currentCameraLine) {
        case 0: {
          this.currentCameraParams = cameraLineTokens;
          this.currentCameraLine++;
          break;
        }
        case 1: {
          this.currentCameraR = [];
        }
        // eslint-disable-next-line
        case 2:
        case 3: {
          this.currentCameraR = this.currentCameraR.concat(cameraLineTokens);
          this.currentCameraLine++;
          break;
        }
        case 4: {
          this.currentCameraT = [];
          this.currentCameraT = cameraLineTokens;
          this.addCamera(
            ...this.currentCameraParams,
            ...this.currentCameraR,
            ...this.currentCameraT
          );
          this.currentCameraLine = 0;
          this.parsedNumCameras++;
          if (this.parsedNumCameras === this.expectedNumCameras) {
            this.camerasRead = true;
          }
          break;
        }
        default: {
          // throw new Error("Bundler: parsing error for Camera in line " + this.linenumber + ".");
          break;
        }
      }
    }
    parsePointLine(tokens) {
      const pointLineTokens = tokens.map(Number);
      switch (this.currentPointLine) {
        case 0: {
          if (tokens.length !== 3) {
            throw new Error(
              "Bundler: parsing error for Point in line " +
                this.lineNumber +
                ". Number of tokens != 3."
            );
          }
          this.currentPointPosition = pointLineTokens;
          this.currentPointLine++;
          break;
        }
        case 1: {
          if (tokens.length !== 3) {
            throw new Error(
              "Bundler: parsing error for Point in line " +
                this.lineNumber +
                ". Number of tokens != 3."
            );
          }
          this.currentPointColor = pointLineTokens;
          this.currentPointLine++;
          break;
        }
        case 2: {
          this.currentPointViewList = pointLineTokens;
          const numViews = this.currentPointViewList[0];
          if ((this.currentPointViewList.length - 1) / 4 !== numViews) {
            throw new Error(
              "Bundler: parsing error for Point in line " +
                this.lineNumber +
                ". Mismatch between number of views."
            );
          }
          const views = [];
          for (let i = 0; i < numViews; i++) {
            views.push(this.currentPointViewList[i * 4 + 1]);
          }
          this.addPoint(this.currentPointPosition, this.currentPointColor, views);
          this.currentPointLine = 0;
          this.parsedNumPoints++;
          if (this.parsedNumPoints === this.expectedNumPoints) {
            this.pointsRead = true;
          }
          // notify about progress
          postMessage(
            {
              status: "loading",
              parsedPoints: this.parsedNumPoints,
              expectedPoints: this.expectedNumPoints
            }
          )



          break;
        }
        default: {
          break;
        }
      }
    }

    addCamera(f, k1, k2, r1, r2, r3, r4, r5, r6, r7, r8, r9, t1, t2, t3) {
      const CAMERA_ELEMENTS = 15;
      const offset = this.m_currentCameraIterator * CAMERA_ELEMENTS;
      this.m_cameras[offset + 0] = f;
      this.m_cameras[offset + 1] = k1;
      this.m_cameras[offset + 2] = k2;
      this.m_cameras[offset + 3] = r1;
      this.m_cameras[offset + 4] = r2;
      this.m_cameras[offset + 5] = r3;
      this.m_cameras[offset + 6] = r4;
      this.m_cameras[offset + 7] = r5;
      this.m_cameras[offset + 8] = r6;
      this.m_cameras[offset + 9] = r7;
      this.m_cameras[offset + 10] = r8;
      this.m_cameras[offset + 11] = r9;
      this.m_cameras[offset + 12] = t1;
      this.m_cameras[offset + 13] = t2;
      this.m_cameras[offset + 14] = t3;
      this.m_currentCameraIterator++;
    }

    addPoint(point, color, views) {
      if (this.m_points.length == 0) {
        // allocate memory for point buffer datastructure on first point
        this.m_points = new Float32Array(this.m_numPoints * 3);
        this.m_colors = new Uint8Array(this.m_numPoints * 3);
        this.m_viewIndices = new Uint32Array(this.m_numPoints + 1);
      }
      const p_index = this.m_currentPointIterator * 3;
      const c_index = this.m_currentPointIterator * 3;
      const v_index = this.m_currentPointIterator;

      for (let i = 0; i < 3; i++) {
        this.m_points[p_index + i] = point[i];
        this.m_colors[c_index + i] = color[i];
      }
      this.m_viewIndices[v_index + 0] = this.m_lastViewIndex;
      this.m_viewIndices[v_index + 1] = this.m_lastViewIndex + views.length;
      this.m_lastViewIndex += views.length;
      this.m_viewArray.push(...views);
      this.m_currentPointIterator++;
      if (this.m_currentPointIterator == this.m_numPoints) {
        // finalize view datastructure
        this.m_views = new Uint16Array(this.m_viewArray);
        this.m_viewArray = []; // mark for garbage collecting
      }
    }
  }

  export async function fetchBundle(outLocation, listLocation, camerasOnly) {
    const bundleParser = new BundleParser();
    // fetch and parse file list
    if (listLocation) {
      let response = await fetch(listLocation);
      let listContent = await response.text();
      listContent.split("\n").forEach(line => bundleParser.addFile(line.trim()));
    }
    // TODO: implement actual skipping of camera parsing
    // At the moment we just parse the complete OUT file even if we only need the points

    // stream out file and parse
    const data = await fetch(outLocation).then(response => {
      const reader = response.body.getReader();
      let partialLine = "";
      const decoder = new TextDecoder();
      const parse = () => {
        return reader.read().then(result => {
          if (result.value) {
            // if result.value === undefined Chrome throws
            partialLine += decoder.decode(result.value, { stream: !result.done });
          }
          let completeLines = partialLine.split(/\n/);
          if (!result.done) {
            partialLine = completeLines[completeLines.length - 1];
            completeLines = completeLines.slice(0, -1);
          }
          for (const line of completeLines) {
            bundleParser.parseLine(line);
            if (camerasOnly && bundleParser.camerasRead == true) {
              reader.cancel();
              return bundleParser.getData();
            }
          }
          if (result.done) return bundleParser.getData();
          return parse();
        });
      };
      return parse();
    });
    return data;
  }

  async function eventHandler(event) {
    const outLocation = event.data.outLocation;
    const listLocation = event.data.listLocation;
    const camerasOnly = event.data.camerasOnly;
    const data = await fetchBundle(outLocation, listLocation, camerasOnly);
    // transfer with ownership of buffers
    postMessage(
      {
        status: "success",
        numCameras: data.numCameras,
        numPoints: data.numPoints,
        files: data.files,
        camerasBuffer: data.cameras.buffer,
        pointsBuffer: data.points.buffer,
        colorsBuffer: data.colors.buffer,
        viewsBuffer: data.views.buffer,
        viewIndicesBuffer: data.viewIndices.buffer
      },
      [
        data.cameras.buffer,
        data.points.buffer,
        data.colors.buffer,
        data.views.buffer,
        data.viewIndices.buffer
      ]
    );

    console.log("Worker finished task");
  }

  //onmessage = eventHandler;
